import CameraSignIn from "../../assets/img/auth-bg.png";
import BottomPartenaire from "../../assets/svg/signin-bottom-partenaire.svg";

export const LoginRight = () => {
  return (
    <div className="flex justify-center bg-[#E2F3F9] py-10 lg:w-[50%]">
      <div className="mx-[5%] flex flex-col justify-center">
        <div className="text-[32px] font-bold text-secoset-green-700">
          Pilotez et labélisez <br /> la démarche RSE de votre film
        </div>
        <img className="my-20 flex h-80 object-contain" src={CameraSignIn} />
        <img className="leading-10" src={BottomPartenaire} />
      </div>
    </div>
  );
};
