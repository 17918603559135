import { useEffect, useState } from "react";

import { Doughnut } from "./graph";
import { ProgressBar } from ".";

export const statusLabels = {
  ongoing: "En cours",
  validated: "Validé",
  rejected: "Invalidé",
  error: "Non conforme",
  pending: "Soumis, en attente",
};
export const statusColors = {
  ongoing: "#ffdd4a",
  validated: "#35ecb8",
  rejected: "#fe5e33",
  error: "#ff3333",
  pending: "#97bac7",
};

export const ProjectSynthesis = ({ productions }) => {
  const [criteriaSynthesis, setCriteriaSynthesis] = useState();
  const [productionSynthesis, setProductionSynthesis] = useState();
  const [doughnutData, setDoughnutData] = useState();

  useEffect(() => {
    const { criteriaSynthesis, productionSynthesis } = buildSynthesis(productions);
    const doughnutData = buildDoughnutData(productionSynthesis);
    setCriteriaSynthesis(criteriaSynthesis);
    setProductionSynthesis(productionSynthesis);
    setDoughnutData(doughnutData);
  }, [productions]);

  const buildDoughnutData = (productionSynthesis) => {
    const labels = [];
    const datasets = { data: [], backgroundColor: [], borderColor: [], borderWidth: 1 };
    Object.entries(productionSynthesis).forEach(([statusKey, statusSum]) => {
      if (!Object.keys(statusLabels).includes(statusKey)) return;
      labels.push(statusLabels[statusKey] + " : " + statusSum);
      datasets.data.push(statusSum);
      datasets.backgroundColor.push(statusColors[statusKey]);
      datasets.borderColor.push(statusColors[statusKey]);
    });
    return { labels, datasets: [datasets] };
  };

  const buildSynthesis = (productions) => {
    const productionSynthesis = { validated: 0, ongoing: 0, rejected: 0, pending: 0, error: 0 };
    const criteriaSynthesis = { valid: 0, pending: 0, todo: 0, error: 0, invalid: 0, total: 0 };
    productions.forEach((production) => {
      if (production.status === "validated") productionSynthesis.validated++;
      else if (production.status === "ongoing") productionSynthesis.ongoing++;
      else if (production.status === "pending") productionSynthesis.pending++;
      else if (production.status === "error") productionSynthesis.error++;
      else if (production.status === "rejected") productionSynthesis.rejected++;
      productionSynthesis.total++;
      production.criterias.forEach((criteria) => {
        if (criteria.status === "ok") criteriaSynthesis.valid++;
        else if (criteria.status === "pending") criteriaSynthesis.pending++;
        else if (criteria.status === "todo") criteriaSynthesis.todo++;
        else if (criteria.status === "error") criteriaSynthesis.error++;
        else if (criteria.status === "invalid") criteriaSynthesis.invalid++;
        criteriaSynthesis.total++;
      });
    });
    return { criteriaSynthesis, productionSynthesis };
  };

  if (!criteriaSynthesis || !productionSynthesis) return <div>Chargement...</div>;
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 items-stretch gap-5">
        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Mes productions</h1>
          <div className="mb-8 grid grid-cols-2">
            <div>
              <p>Productions</p>
              <div className="flex h-16 w-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-mid" />
                <p>{productions.length}</p>
              </div>
            </div>
            <div>
              <p>Productions labéllisées</p>
              <div className="flex h-16 w-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-dark" />
                <p>{productions.reduce((acc, a) => acc + (a.status === "validated" ? 1 : 0), 0)}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center">
              <div className="mr-5 w-[85%]">
                <ProgressBar progress={(productions.reduce((acc, a) => acc + (a.status === "validated" ? 1 : 0), 0) / productions.length) * 100} />
              </div>
              <p className="font-bold"> {((productions.reduce((acc, a) => acc + (a.status === "validated" ? 1 : 0), 0) / productions.length) * 100).toFixed(0)} % </p>
            </div>
          </div>
        </section>

        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Statuts de labellisation</h1>
          <div className="m-4 ">
            <Doughnut data={doughnutData} />
          </div>
        </section>
      </div>
      <section className="rounded bg-white">
        <div className="flex justify-between p-8">
          <h1 className="text-xl font-bold text-blue-dark">Tableau synthétique</h1>
          <button className="rounded-sm border bg-blue-dark px-3 py-2 text-center text-white " type="button" onClick={() => downloadCSV()}>
            Télécharger
          </button>
        </div>

        <div className="mb-8 flex items-center justify-center gap-2 overflow-auto">
          <table className="w-full table-auto rounded-sm text-center">
            <thead>
              <tr className="border-b border-blue-gray">
                <th className="p-3">Projet</th>
                <th className="p-3">Statut</th>
                <th className="p-3">Complétion (%)</th>
                <th className="p-3">Dates de tournage</th>
                {/* <th className="p-3">Type de label</th> */}
              </tr>
            </thead>
            <tbody>
              {productions.map((p, i) => {
                const validCount = p.criterias.reduce((acc, a) => acc + (a.status === "ok" ? 1 : 0), 0);
                return (
                  <tr key={i} className="border-b border-blue-gray">
                    <td className="p-3">{p.title}</td>

                    <td className="p-3">
                      <div className="flex items-center justify-center gap-2 ">
                        <div className={`mr-2 h-4 w-4 rounded-full bg-${statusColors[p.status]}`} />
                        {statusLabels[p.status]}
                      </div>
                    </td>

                    <td className="p-3">
                      <div className="flex items-center justify-center gap-2 ">
                        <ProgressCircle percentage={(validCount * 100) / p.criterias.length} />
                        <p>{((validCount * 100) / p.criterias.length).toFixed(0)} % </p>
                      </div>
                    </td>
                    <td>
                      Du {new Date(p.shootingStart).toLocaleDateString("fr")} au {new Date(p.shootingEnd).toLocaleDateString("fr")}
                    </td>
                    {/* {p.label.type ? <td className="capitalize">{p.type}</td> : <td> Documents </td>} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

const downloadCSV = () => {
  const table = document.querySelector("table");
  const rows = table.rows;
  const data = [];

  for (let i = 0; i < rows.length; i++) {
    const cells = rows[i].cells;
    const rowData = [];
    for (let j = 0; j < cells.length; j++) {
      rowData.push(cells[j].textContent);
    }
    data.push(rowData);
  }

  const csvData = data.map((row) => row.join(",")).join("\n");

  const link = document.createElement("a");
  link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvData));
  link.setAttribute("download", "projets_synthèse.csv");
  document.body.appendChild(link);
  link.click();
};

const ProgressCircle = ({ percentage, colour = "#0c5371" }) => {
  const r = 10;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  const strokeWidth = 3;
  return (
    <svg width={r * 2 + strokeWidth} height={r * 2 + strokeWidth}>
      <circle r={r} cx={r + strokeWidth / 2} cy={r + strokeWidth / 2} fill="transparent" stroke="#cfdde3" strokeWidth={strokeWidth} />
      <circle
        r={r}
        cx={r + strokeWidth / 2}
        cy={r + strokeWidth / 2}
        fill="transparent"
        stroke={colour}
        strokeWidth={strokeWidth}
        strokeDasharray={circ}
        strokeDashoffset={strokePct}
        strokeLinecap="round"
      />
    </svg>
  );
};
