import React, { useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import api from "../../api/apiHandler";
import { ProjectSynthesis } from "./project-synthesis";
import { ProjectList } from "./project-list";
import { CriteriaSynthesis } from "./criteria-synthesis";
import { CriteriaList } from "./criteria-list";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Select-bis";

const details = [
  { name: "synthesis", title: "Synthèse" },
  { name: "list", title: "Liste" },
];

const choices = [
  { name: "projects", title: "Projets" },
  { name: "criteria", title: "Critères" },
];

const Index = () => {
  const { user } = useSelector((state) => state.Auth);
  const [organization, setOrganization] = useState();
  const [thematics, setThematics] = useState();
  const [criterias, setCriterias] = useState();
  const [productions, setProductions] = useState();
  const [activeDetail, setActiveDetail] = useState("synthesis");
  const [activeChoice, setActiveChoice] = useState("projects");
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const getOrganizations = async () => {
      try {
        const res = await api.get(`/organization`);
        const { ok, data } = res.data;
        if (ok) {
          const initOrganizations = data.filter((o) => o.accessDashboard);
          if (!initOrganizations.length) return navigate("/saas");
          setOrganizations(initOrganizations);
          setOrganization(initOrganizations[0]);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getOrganizations();
  }, [user]);

  useEffect(() => {
    if (!organization) return;
    api
      .get(`/labels/all?organization=${organization._id.toString()}`)
      .then((res) => {
        setThematics(res.data.thematics);
        setCriterias(res.data.criterias);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(organization);
    api
      .get(`/organization/${organization._id.toString()}/productions`)
      .then((res) => {
        setProductions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [organization]);

  if (!productions || !thematics || !criterias) return <h1>Chargement...</h1>;

  return (
    <div className="h-full w-full p-16">
      {organizations.length > 1 && (
        <div className="w-[360px]">
          <Select options={organizations.map((o) => ({ label: o.name, value: o }))} value={organization} onChange={setOrganization} placeholder={organization.name} />
        </div>
      )}
      <div className="flex items-center justify-between">
        <p className="flex text-2xl font-bold">Dashboard</p>
        <Choices choices={choices} activeChoice={activeChoice} setActiveChoice={setActiveChoice} />
        <Details details={details} activeDetail={activeDetail} setActiveDetail={setActiveDetail} />
      </div>
      <div className="mt-10">
        {activeChoice === "criteria" && activeDetail === "synthesis" ? (
          <CriteriaSynthesis productions={productions} thematics={thematics} criterias={criterias} />
        ) : activeChoice === "criteria" && activeDetail === "list" ? (
          <CriteriaList productions={productions} thematics={thematics} criterias={criterias} />
        ) : activeChoice === "projects" && activeDetail === "synthesis" ? (
          <ProjectSynthesis productions={productions} />
        ) : activeChoice === "projects" && activeDetail === "list" ? (
          <ProjectList productions={productions} thematics={thematics} />
        ) : (
          <ProjectSynthesis productions={productions} />
        )}
      </div>
    </div>
  );
};
export default Index;

export const ProgressBar = ({ progress }) => {
  return (
    <div className="relative">
      <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
        <div style={{ width: `${progress}%` }} className="h-full bg-blue-dark" />
      </div>
    </div>
  );
};

export const Details = ({ details, activeDetail, setActiveDetail }) => {
  const handleDetailClick = (detail) => {
    setActiveDetail(detail);
  };

  return (
    <section className="relative p-4">
      <div className="flex items-center">
        {details.map((detail) => (
          <Detail key={detail.name} name={detail.name} title={detail.title} isActive={detail.name === activeDetail} onClick={() => handleDetailClick(detail.name)} />
        ))}
      </div>
    </section>
  );
};

export const Detail = ({ name, title, isActive, onClick }) => (
  <div onClick={() => onClick(name)}>
    <div
      className={`${
        isActive ? "hover:bg-gray-hover rounded-sm border border-blue-dark bg-white text-blue-dark" : "bg-tab-main hover:bg-tab-hover rounded-sm border-0 bg-blue-gray"
      } flex cursor-pointer items-center px-7 py-1`}>
      <p>{title}</p>
    </div>
  </div>
);

export const Choices = ({ choice, activeChoice, setActiveChoice }) => {
  const handleChoiceClick = (choice) => {
    setActiveChoice(choice);
  };

  return (
    <section className="relative p-4">
      <div className="flex items-center">
        {choices.map((choice) => (
          <Choice key={choice.name} name={choice.name} title={choice.title} isActive={choice.name === activeChoice} onClick={() => handleChoiceClick(choice.name)} />
        ))}
      </div>
    </section>
  );
};

export const Choice = ({ name, title, isActive, onClick }) => (
  <div onClick={() => onClick(name)}>
    <div
      className={`${
        isActive ? "hover:bg-gray-hover rounded-sm border border-blue-dark bg-white text-blue-dark" : "bg-tab-main hover:bg-tab-hover rounded-sm border-0 bg-blue-gray"
      } flex cursor-pointer items-center px-7 py-1`}>
      <p>{title}</p>
    </div>
  </div>
);
