import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    user: null,
    publisher: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPublisher: (state, action) => {
      state.publisher = action.payload;
    },
    setInit: (state, action) => {
      state.user = action.payload.user;
      state.publisher = action.payload.publisher;
    },
  },
});

export const { setUser, setPublisher, setInit } = authSlice.actions;
export default authSlice.reducer;
