import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import api from "../../api/apiHandler";
import { setUser } from "../../redux/authSlice";

export default () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .post(`/saas-auth/loginas/${searchParams.get("id")}`, { token: searchParams.get("token") })
      .then((res) => {
        const user = res.data.data;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        dispatch(setUser(user));
        navigate("/saas");
      })
      .catch((err) => {
        console.error(err);
        navigate("/saas");
      });
  }, []);
  return <></>;
};
