import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import Subtract from "../../assets/icons/chat-bubble.svg";

import { LoginRight } from "./commons";
import api from "../../api/apiHandler";

const EmailSent = () => {
  // get params from react-router-dom
  const { email } = useLocation().state;
  const [sentEmailAgain, setSetEmailAgain] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSendEmail = () => {
    if (loading) return;
    setLoading(true);
    api
      .post("/saas-auth/resend-email", { email })
      .then((res) => {
        setSetEmailAgain(true);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <div className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
        <div className="flex flex-col items-center justify-center gap-4 px-24">
          <img className="w-44" src={LogoSecoset} />
          <div className="text-center text-3xl font-bold text-gray-500">Nous vous avons envoyé un email de confirmation</div>
          <div className="text-center">Pour valider votre compte, ouvrez l'email que nous venons de vous envoyer à {email}</div>

          <div className="flex w-fit flex-row items-center justify-center gap-2 bg-[#E2F3F9] py-3 px-4">
            <img className="w-6" src={Subtract} />
            <div div className="w-fit">
              {sentEmailAgain ? (
                <div className="font-bold text-[#15E0A6]">Lien renvoyé !</div>
              ) : (
                <div>
                  Pas reçu l'email ?{" "}
                  <span onClick={handleSendEmail} className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                    Renvoyer le lien.
                  </span>
                </div>
              )}

              <div>
                Si vous ne le recevez toujours pas, vérifiez votre email et/ou contactez{" "}
                <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                  support@flyingsecoya.io
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginRight />
    </div>
  );
};

export default EmailSent;
