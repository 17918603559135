import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { BsSearch } from "react-icons/bs";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { ErrorIcon, InvalidIcon, PendingIcon, TodoIcon, ValidIcon } from "../../components/Icons";
import { ProgressBar } from ".";

export const ProjectList = ({ productions, thematics }) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [displayedProductions, setDisplayedProductions] = useState([...productions]);

  useEffect(() => {
    if (search && status !== "all") setDisplayedProductions(productions.filter((p) => p.title.toLowerCase().search(search.toLowerCase()) !== -1 && p.status === status));
    else if (status !== "all") setDisplayedProductions(productions.filter((p) => p.status === status));
    else if (search) setDisplayedProductions(productions.filter((p) => p.title.toLowerCase().search(search.toLowerCase()) !== -1));
    else setDisplayedProductions(productions);
  }, [status, search]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <section className="bg-white px-1 py-6">
      <div className="m-4 flex items-center justify-between bg-white px-16 py-6">
        <div className="flex items-center gap-3">
          <div className="py-3 text-center text-3xl font-bold">{displayedProductions.length}</div>
          <p className="text-xl font-bold">projets</p>
        </div>
        <div className="relative flex w-80 flex-wrap items-center">
          <span className="absolute left-2 z-10 w-8 items-center justify-center bg-transparent p-3 text-slate-700">
            <BsSearch />
          </span>
          <input onChange={handleSearch} placeholder="Rechercher par titre..." className="input pl-10" />
        </div>
        <StatusSelect status={status} setStatus={setStatus} />
      </div>
      <div className="flex flex-col gap-4">
        {displayedProductions.map((p, i) => (
          <Project key={i} production={p} thematics={thematics} />
        ))}
      </div>
    </section>
  );
};

const Project = ({ production, thematics }) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const validCount = production.criterias.reduce((acc, a) => acc + (a.status === "ok" ? 1 : 0), 0);
    const progress = (validCount * 100) / production.criterias.length;
    setProgress(progress);
  }, [production]);

  return (
    <div className="border-y border-blue-gray bg-white px-16 py-6">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-lg font-bold text-blue-dark">{production.title}</h1>
        <button className="rounded bg-blue-dark px-4 py-2 text-white" type="button" onClick={() => navigate(`/saas/productions/${production._id}`)}>
          Ouvrir
        </button>
      </div>

      <div className="m-3 grid grid-cols-2 gap-2 ">
        <div>
          <div className="mb-4 flex w-[90%] items-center">
            <p className="text-center text-xs font-bold text-blue-dark">{`${progress.toFixed(0)} %`}</p>
            <div className="ml-5 w-[85%]">
              <ProgressBar progress={progress} />
            </div>
          </div>
          <p className="font-size-9">Direction de production : {production.productionDirector}</p>
          <p>Réalisation: {production.director}</p>
          <p>
            Dates du tournage : Du {new Date(production.shootingStart).toLocaleDateString("fr")} au {new Date(production.shootingEnd).toLocaleDateString("fr")}
          </p>
        </div>

        <div className="grid grid-cols-2 gap-x-6 gap-y-2 rounded-sm bg-blue-light p-4 ring-1 ring-blue-mid ring-offset-blue-light ">
          {thematics.map((thematic, i) => {
            const criterias = production.criterias.filter((criteria) => criteria.thematic === thematic._id);
            return (
              <div className="flex items-center justify-end" key={i}>
                <p className="whitespace-nowrap text-right text-sm text-blue-dark">{thematic.title}</p>
                <div className="flex">
                  {criterias.map((criteria, i) => (
                    <div key={i}>
                      {
                        {
                          ok: <ValidIcon className="h-5 w-5 text-green-light" />,
                          pending: <PendingIcon className="h-5 w-5 text-blue-dark" />,
                          todo: <TodoIcon className="h-5 w-5 text-blue-mid" />,
                          error: <ErrorIcon className="h-5 w-5 text-orange" />,
                          invalid: <InvalidIcon className="h-5 w-5 text-red" />,
                        }[criteria.status]
                      }
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const projectStatus = {
  all: "Tous",
  validated: "Validé",
  pending: "En attente",
  error: "Erreur",
  ongoing: "En cours",
  rejected: "Rejeté",
};

const StatusSelect = ({ status, setStatus }) => (
  <Menu as="div" className="z-20 inline-block text-left">
    <Menu.Button as="div" className="w-56 cursor-pointer rounded border bg-blue-dark p-2 text-center text-white">
      <div className="flex items-center justify-between">
        <span className="pl-2">{projectStatus[status]}</span>
        <HiChevronDown className="mr-1" aria-hidden="true" />
      </div>
    </Menu.Button>
    <Transition
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <Menu.Items className="absolute right-0 mt-2 flex w-40 origin-top-right flex-col border border-blue-dark bg-blue-light text-blue-dark focus:outline-none">
        {Object.entries(projectStatus)
          .filter(([k]) => k !== status)
          .map(([k, v]) => (
            <Menu.Item key={k}>
              <button onClick={() => setStatus(k)} className="p-4 text-blue-dark">
                {v}
              </button>
            </Menu.Item>
          ))}
      </Menu.Items>
    </Transition>
  </Menu>
);
