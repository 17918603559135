import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, Title, PointElement, LineElement } from "chart.js";
import { Doughnut as Doug, Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend, PointElement, LineElement);

export const Doughnut = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.5,
    plugins: {
      legend: {
        align: "center",
        display: true,
        position: "right",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          font: {
            size: 13,
          },
        },
      },
    },
  };

  return <Doug options={options} data={data} />;
};

export const LineChart = ({ data }) => {
  return <Line data={data} />;
};
