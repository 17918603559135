import React, { useEffect, useState } from "react";
import validator from "validator";
import { Formik, Form } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import * as Sentry from "@sentry/react";

import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/authSlice";
import api from "../../api/apiHandler";

import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import Subtract from "../../assets/icons/chat-bubble.svg";
import { LoginRight } from "./commons";
import Loader from "../../components/Loader";

const JoinEmail = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [invited, setInvited] = useState();
  const [error, setError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get(`/saas-auth/email-token/${token}`)
      .then((res) => {
        if (res.data.ok) {
          setInvited(res.data.data);
          setError(null);
        }
      })
      .catch((error) => {
        if (error.response?.data.message === "EXPIRED_TOKEN") setError("expired");
        else setError("invalid");
      });
  }, []);

  console.log("user", invited);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.password)) errors.password = "Ce champ est requis";
    if (!values.terms) errors.terms = "L'acceptation des CGV est requise";
    setErrors(errors);
    return !errors.password && !errors.terms;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .post(`/saas-auth/${invited._id}/set-password`, values)
      .then((res) => {
        const user = res.data.data;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log("error", error);
        setErrors({ password: "Une erreur est survenue" });
      });
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <div className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
        <div className="flex flex-col items-center justify-center gap-4 px-24">
          {error === "invalid" ? (
            <>
              <img className="w-44" src={LogoSecoset} />
              <div className="text-center text-3xl font-bold text-gray-500">Oups, ce lien n’est pas valide...</div>
              <div className="text-center">On dirait que le lien sur lequel vous avez cliqué n'est plus valide. Peut-être est-ce un vieux lien ?</div>
              <button className="blue-button mt-4 mb-8 w-[576px] max-w-[80%] text-lg" onClick={() => navigate("/saas")}>
                Retourner à la page de connexion
              </button>
              <ContactSupport />
            </>
          ) : error === "expired" ? (
            <>
              <img className="w-44" src={LogoSecoset} />
              <div className="text-center text-3xl font-bold text-gray-500">Ce lien de confirmation est expiré</div>
              <div className="text-center">
                On dirait que vous avez mis trop de temps à confirmer votre compte ! Les liens de confirmation expirent au bout de 14 jours. Pour accéder à la plateforme, demandez
                à un administrateur Secoset dans votre société de vous renvoyer une invitation.
              </div>
              <button className="blue-button mt-4 mb-8 w-[576px] max-w-[80%] text-lg" onClick={() => navigate("/saas")}>
                Retourner à la page de connexion
              </button>
              <ContactSupport />
            </>
          ) : invited ? (
            <Formik
              initialValues={{
                password: "",
                terms: false,
              }}
              onSubmit={handleSubmit}>
              {({ values, errors, handleChange }) => (
                <Form className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
                  <div className="flex w-[360px] flex-col items-center justify-center gap-4">
                    <img className="w-44" src={LogoSecoset} />

                    <div className="text-[#667085]">Vous avez été invité(e) à rejoindre Secoset</div>

                    <div>
                      <div className="mb-1 font-bold text-gray-500">Email professionnel</div>
                      <input disabled className="saas-input w-[360px]" type="text" defaultValue={invited.email} />
                    </div>
                    <div>
                      <div className="mb-1 font-bold text-gray-500">Confirmez votre mot de passe</div>
                      <div className="saas-input flex w-[360px] flex-row items-center justify-between ">
                        <input
                          className="w-full"
                          placeholder="Votre mot de passe"
                          name="password"
                          type={passwordVisible ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                        />
                        {passwordVisible ? (
                          <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                        ) : (
                          <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                        )}
                      </div>
                      {errors.password && <div className="mt-1 text-sm text-red">{errors.password}</div>}
                    </div>
                    <div className="flex-start mt-4 flex w-fit flex-row items-center justify-center gap-4 self-start text-gray-700">
                      <input className="cursor-pointer" name="terms" type="checkbox" value={values.terms} onChange={handleChange} />
                      <div className="font-medium">
                        J'accepte les{" "}
                        <a href="/CGS_Secoset.pdf" className="bold cursor-pointer font-bold text-secoset-green-400 hover:underline" rel="noreferrer" target="_blank">
                          conditions générales
                        </a>
                      </div>
                    </div>
                    {errors.terms && <div className="mt-1 text-sm text-red">{errors.terms}</div>}

                    <button className="blue-button mt-4 w-[360px] text-lg" type="submit">
                      Continuer
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <LoginRight />
    </div>
  );
};

const ContactSupport = () => {
  return (
    <div className="flex w-[576px] max-w-[80%] flex-row items-center justify-center gap-2 bg-[#E2F3F9] py-3 px-4">
      <img className="w-6" src={Subtract} />
      <div div className="w-fit">
        <div>
          Un problème ? Contactez
          <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
            {" "}
            support@flyingsecoya.io
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinEmail;
