import React, { useState, useEffect } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Billing from "./billing";
import MyAccount from "./my-account";
import Society from "./society";
import Users from "./users";
import { BreadCrumps } from "../../components/BreadCrumps";

import api from "../../api/apiHandler";

const Main = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [crumbs, setCrumbs] = useState([
    { label: "Mon compte", link: "/saas/account" },
    { label: "Compte", link: "/saas/account?tab=my-account" },
  ]);
  const { user } = useSelector((state) => state.Auth);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (!user) return;
    const getOrganizations = async () => {
      try {
        const res = await api.get(`/organization/user/${user._id}`);
        const { ok, data } = res.data;
        if (ok) setOrganizations(data);
      } catch (e) {
        console.log(e);
      }
    };
    getOrganizations();
  }, [user]);

  const getTab = () => {
    const tab = searchParams.get("tab");

    if (!["my-account", "society", "users", "billing"].includes(tab)) return "my-account";
    return tab;
  };

  const handleSetTab = (tab, label) => {
    setSearchParams({ tab });
    setCrumbs([
      { label: "Mon compte", link: "/saas/account" },
      { label: label, link: `/saas/account?=tab=${tab}` },
    ]);
  };

  return (
    <>
      <div className="pt-4 pl-4">
        <BreadCrumps crumbs={crumbs} setCrumbs={setCrumbs} />
      </div>
      <div className="flex gap-8 px-12 py-6">
        {/* {organizations.length && (
          <div className="flex w-56 flex-col gap-4 rounded bg-white p-4">
            <NavLink name="my-account" label="Mon compte" setTab={handleSetTab} tab={getTab()} />
            <NavLink name="society" label="Société" setTab={handleSetTab} tab={getTab()} />
            <NavLink name="users" label="Utilisateurs" setTab={handleSetTab} tab={getTab()} />
            <NavLink name="billing" label="Facturation" setTab={handleSetTab} tab={getTab()} />
          </div>
        )} */}
        <div className="flex-1 rounded bg-white p-10">
          {{
            "my-account": <MyAccount />,
            society: <Society />,
            users: <Users />,
            // billing: <Billing />,
          }[getTab()] || <MyAccount />}
        </div>
      </div>
    </>
  );
};

const NavLink = ({ name, label, setTab, tab }) => {
  return (
    <button className={`text-blue-dar flex items-center py-4 text-left text-lg font-bold ${tab === name ? "rounded bg-blue-gray px-4" : ""}`} onClick={() => setTab(name, label)}>
      {label}
      {tab === name && <RiArrowRightLine className="ml-4" />}
    </button>
  );
};

export default Main;
