import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";

import Loader from "../../../components/Loader";

import { Modal } from "../../../components/Modal";

import ProductionInformation from "./information";
import ProductionLabel from "./labels";

import { BreadCrumps } from "../../../components/BreadCrumps";

import api from "../../../api/apiHandler";

import Notification from "./notifications";

const Edit = () => {
  const { productionId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [production, setProduction] = useState();
  const [thematics, setThematics] = useState([]);

  const [openFirstVisit, setOpenFirstVisit] = useState(false);
  const [isDoneModalShowing, setIsDoneModalShowing] = useState(false);

  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    api
      .get(`/productions/${productionId}`)
      .then(({ data }) => {
        setProduction(data.production);
        setCrumbs([{ label: data.production.title, link: `/saas/productions/${data.production._id}` }]);
        if (data.production.status === "validated") setIsDoneModalShowing(true);
      })
      .catch(() => {
        // add toast
        navigate("/saas/");
      });
    api
      .get(`/labels/all?productionId=${productionId}`)
      .then(({ data }) => {
        setThematics(data.thematics);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (searchParams.get("new") === "true" && !openFirstVisit) {
      setOpenFirstVisit(true);
      searchParams.delete("new");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  if (!production)
    return (
      <div className="h-full bg-white p-5">
        <Loader />
      </div>
    );

  return (
    <div className="space-y-4 p-4">
      <BreadCrumps crumbs={crumbs} setCrumbs={setCrumbs} />
      <ProductionDoneModal isShowing={isDoneModalShowing} setIsShowing={setIsDoneModalShowing} production={production} />
      <ModalFirstVisit open={openFirstVisit} setOpen={setOpenFirstVisit} />
      <ProductionInformation production={production} setProduction={setProduction} />

      <div className=" bg-white p-5">
        <div className="flex w-full items-center justify-between mb-1">
          <p className="h4b">Ressources</p>
        </div>
        {["autonomy", "supported"].includes(production.plan) ? (
          <div className="w-full grid grid-cols-3 gap-4 ">
            <Link className="group empty-button" to="/saas/help/directory">
              Annuaire{" "}
              <p className="text-sm font-normal mt-1 group-hover:text-white transition duration-150 ease-linear">
                Référencement des prestataires pouvant apporter des solutions "Responsables" à votre tournage.
              </p>
            </Link>
            <Link className="group empty-button" to="/saas/help/actions">
              Guide d'actions
              <p className="text-sm font-normal mt-1 group-hover:text-white transition duration-150 ease-linear">
                Des exemples concrets d'actions mises en place sur des tournages pour améliorer vos impacts!
              </p>
            </Link>
            <Link className="group empty-button" target="_blank" to="https://adminsecoya.sharepoint.com/:f:/s/Boteoutil/ErK0r4POs_BPpIaj3VfAPukBTSH8PNmmbZI-3wEIH7Q-Ug?e=O8kmQ6">
              Boîte à outils{" "}
              <p className="text-sm font-normal mt-1 group-hover:text-white transition duration-150 ease-linear">
                Des documents, des visuels, pour faciliter le travail de terrain.
              </p>
            </Link>
          </div>
        ) : (
          <div>
            <p className="whitespace-nowrap font-semibold text-sky-400 my-4">
              Vous êtes sur une version démo.
              <a href="https://calendly.com/mathieu-delahousse" className="ml-2 underline" target="_blank">
                Passez en version payante
              </a>
            </p>
            <div className="w-full grid grid-cols-3 gap-4 opacity-40">
              <div className="rounded border border-secoset-green-900 px-4 py-2 font-semibold text-secoset-green-900 cursor-not-allowed">
                Annuaire <p className="text-sm font-normal mt-1">Référencement des prestataires pouvant apporter des solutions "Responsables" à votre tournage.</p>
              </div>
              <div className="rounded border border-secoset-green-900 px-4 py-2 font-semibold text-secoset-green-900 cursor-not-allowed">
                Guide d'actions
                <p className="text-sm font-normal mt-1">Des exemples concrets d'actions mises en place sur des tournages pour améliorer vos impacts!</p>
              </div>
              <div className="rounded border border-secoset-green-900 px-4 py-2 font-semibold text-secoset-green-900 cursor-not-allowed">
                Boîte à outils <p className="text-sm font-normal mt-1">Des documents, des visuels, pour faciliter le travail de terrain.</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* TO CLEAN */}
      <ProductionLabel production={production} thematics={thematics} />
      <Notification production={production} />
    </div>
  );
};

export default Edit;

const ModalFirstVisit = (props) => {
  const { open, setOpen } = props;

  return (
    <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="bg-white px-12 pt-6 pb-12">
        <p className="my-4 text-2xl font-bold">Nouveau projet</p>
        <p>Félicitations, vous avez créé un nouveau projet! </p>
        <p>Voici quelques informations utiles avant de vous lancer dans cette démarche éthique et responsable:</p>
        <div className="my-4 flex">
          <img src={`/images/icons/check.svg`} alt="check" />
          <p>
            Afin d'obtenir votre bilan, vous devez
            <strong className="font-bold"> remplir les différents critères. </strong>
          </p>
        </div>
        <div className="my-4 flex">
          <img src={`/images/icons/check.svg`} alt="check" />
          <p>Pour rappel, voici les CGV : &nbsp;</p>
          <a href="/CGS_Secoset.pdf" className="bold underline">
            Conditions générales de ventes
          </a>
        </div>
        <div className="my-4 flex justify-end">
          <button className="blue-button" onClick={() => setOpen(false)}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

const ProductionDoneModal = ({ isShowing, setIsShowing }) => (
  <Modal className="w-full max-w-3xl" isOpen={isShowing} onClose={() => setIsShowing(false)}>
    <div className="flex flex-col md:flex-row px-12 pt-6 pb-12 w-full">
      <div className="flex-1">
        <h1 className="text-3xl font-bold">Félicitations !</h1>
        <p className="my-6 text-lg">
          Votre projet a obtenu le label <span className="font-semibold">Production&nbsp;Responsable®</span>&nbsp;!
        </p>
        <p className="my-6 text-lg">Bravo pour votre engagement !</p>
        <p className="my-6 text-lg">
          À bientôt sur un prochain projet, <br />
          L'équipe de Flying Secoya
        </p>
      </div>
      <div className="flex justify-end items-center">
        <img className="w-40 h-40" src="/images/Logo label production responsable.png" alt="Secoset" />
      </div>
    </div>
  </Modal>
);
