import React, { useState } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import api from "../../api/apiHandler";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import { LoginRight } from "./commons";
import CircularProgress from "@mui/material/CircularProgress";

const Signup = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values?.email)) errors.email = "Adresse email invalide";
    if (validator.isEmpty(values.password)) errors.password = "Ce champ est requis";
    if (!values.terms) errors.terms = "L'acceptation des CGV est requise";
    setErrors(errors);
    return !errors.email && !errors.password && !errors.terms;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    if (token) values.token = token;
    if (loading) return;
    setLoading(true);

    api
      .post("/saas-auth/signup", values)
      .then(async (res) => {
        setLoading(false);
        navigate("/sent-email", { state: { email: values.email } });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 409) return setErrors({ email: "Email déjà utilisé" });
        if (error.response?.status === 403) return setErrors({ terms: "L'acceptation des CGV est requise" });
        else setErrors({ terms: "Une erreur est survenur" });
      });
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <Formik
        initialValues={{
          email: "",
          password: "",
          terms: false,
        }}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
            <div className="flex w-[360px] flex-col items-center justify-center gap-4">
              <img className="w-44" src={LogoSecoset} />
              <div className="text-gray-500">{token ? "Vous avez été invité(e) à rejoindre Secoset" : "Essayez Secoset gratuitement"}</div>

              <div>
                <div className="mb-1 font-bold text-gray-500">Email professionel</div>
                <input className="saas-input w-[360px]" placeholder="john@ugc.fr" name="email" type="email" value={values.email} onChange={handleChange} />
                {errors.email && <div className="text-sm text-red">{errors.email}</div>}
              </div>
              <div>
                <div className="mb-1 font-bold text-gray-500">Mot de passe</div>
                <div className="saas-input flex w-[360px] flex-row items-center justify-between ">
                  <input
                    className="w-full"
                    placeholder="Votre mot de passe"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passwordVisible ? (
                    <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                  ) : (
                    <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                  )}
                </div>
                {errors.password && <div className="mt-1 text-sm text-red">{errors.password}</div>}
              </div>

              <div className="flex-start mt-4 flex w-fit flex-row items-center justify-center gap-4 self-start text-gray-700">
                <input className="cursor-pointer" name="terms" type="checkbox" value={values.terms} onChange={handleChange} />
                <div className="font-medium">
                  J'accepte les{" "}
                  <a href="/CGS_Secoset.pdf" className="bold cursor-pointer font-bold text-secoset-green-400 hover:underline" rel="noreferrer" target="_blank">
                    conditions générales
                  </a>
                </div>
              </div>
              {errors.terms && <div className="mt-1 text-sm text-red">{errors.terms}</div>}

              <button type="submit" className="blue-button mt-4 mb-8 flex w-[360px] items-center justify-center text-lg">
                {loading ? <CircularProgress style={{ color: "white" }} size={28} /> : <div> M'inscrire</div>}
              </button>
              {!token && (
                <div>
                  Déja un compte ?{" "}
                  <Link to="/signin" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                    Connectez-vous
                  </Link>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <LoginRight />
    </div>
  );
};

export default Signup;
