export const SuccessModal = ({ closeModal, titre, text, buttonText = "Confirmer" }) => {
  return (
    <div className="flex w-[760px] flex-col items-center justify-center p-10">
      <SucessIcon />
      <div className="mt-6 text-3xl font-bold text-secoset-green-700">{titre}</div>
      <div className="mt-2 text-center text-secoset-green-700">{text}</div>
      <button onClick={closeModal} className="blue-button mt-6 w-[352px]">
        {buttonText}
      </button>
    </div>
  );
};

const SucessIcon = () => {
  return (
    <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7" y="7" width="80" height="80" rx="40" fill="#D1FADF" />
      <path
        d="M39.5006 47.0006L44.5006 52.0006L54.5006 42.0006M63.6673 47.0006C63.6673 56.2054 56.2054 63.6673 47.0006 63.6673C37.7959 63.6673 30.334 56.2054 30.334 47.0006C30.334 37.7959 37.7959 30.334 47.0006 30.334C56.2054 30.334 63.6673 37.7959 63.6673 47.0006Z"
        stroke="#039855"
        stroke-width="3.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="7" y="7" width="80" height="80" rx="40" stroke="#ECFDF3" stroke-width="13.3333" />
    </svg>
  );
};
