import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import api from "../../api/apiHandler";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/authSlice";
import Loader from "../../components/Loader";

import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import Subtract from "../../assets/icons/chat-bubble.svg";
import { LoginRight } from "./commons";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [error, setError] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .post("/saas-auth/verify-email", { token })
      .then((res) => {
        const user = res.data.user;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.message === "Utilisateur déjà validé") setError("invalid");
        else if (error.response?.message === "Token invalide") setError("invalid");
        else if (error.response?.message === "Token expiré") setTokenState("expired");
        else setError("invalid");
      });
  }, []);

  const handleSendEmail = () => {
    api
      .post("/saas-auth/resend-email", { token })
      .then((res) => {
        navigate("/sent-email", { state: { email: res.data.email } });
      })
      .catch(() => setError(true));
  };

  if (!error) return <Loader />;

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <div className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
        <div className="flex flex-col items-center justify-center gap-4 px-32">
          <img className="w-44" src={LogoSecoset} />
          {error === "invalid" ? (
            <>
              <div className="text-center text-3xl font-bold text-gray-500">Oups, ce lien n’est pas valide...</div>
              <div className="text-center">On dirait que le lien sur lequel vous avez cliqué n'est plus valide. Peut-être est-ce un vieux lien ?</div>
              <button className="blue-button mt-4 mb-8 w-[576px] max-w-[80%] text-lg" onClick={() => navigate("/saas")}>
                Retourner à la page de connexion
              </button>
            </>
          ) : error === "expired" ? (
            <>
              <div className="text-center text-3xl font-bold text-gray-500">Ce lien de confirmation est expiré</div>
              <div className="text-center">On dirait que vous avez mis trop de temps à confirmer votre compte ! Les liens de confirmation expirent au bout d'1h.</div>
              <button className="blue-button mt-4 mb-8  w-[576px] max-w-[80%] text-lg" onClick={handleSendEmail}>
                Renvoyer un nouvel email de confirmation
              </button>
            </>
          ) : null}

          <div className="flex w-[576px] max-w-[80%] flex-row items-center justify-center gap-2 bg-[#E2F3F9] py-3 px-4">
            <img className="w-6" src={Subtract} />
            <div div className="w-fit">
              <div>
                Un problème ? Contactez
                <a href="mailto:support@flyingsecoya.io" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                  {" "}
                  support@flyingsecoya.io
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginRight />
    </div>
  );
};

export default VerifyEmail;
