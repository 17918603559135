import React, { useState, useEffect } from "react";

import { Modal } from "../../components/Modal";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";

import Perso1 from "../../assets/tutorial/perso_1.svg";
import Perso2 from "../../assets/tutorial/perso_2.svg";
import Perso3 from "../../assets/tutorial/perso_3.svg";
import Perso5 from "../../assets/tutorial/perso_5.svg";
import Obj1 from "../../assets/tutorial/obj_1.svg";
import Obj3 from "../../assets/tutorial/obj_3.svg";

const TutorialModal = ({ user, open, setOpen }) => {
  const [step, setStep] = useState(1);

  const closeTutorial = () => {
    setOpen(false);
  };

  return (
    <Modal onClose={() => null} isOpen={open} showCross={false}>
      <div className="p-10">
        <div className="relative flex w-[550px] flex-col items-center justify-center">
          <img className="w-44" src={LogoSecoset} />

          {step === 1 ? (
            <div>
              <img className="mt-10 rounded-md" src={"/images/gif/tuto_1.gif"} />
              <div className="mt-10 text-center text-2xl font-bold text-secoset-green-700">Créez votre projet</div>
              <div className="mx-2 mt-1 text-center text-sm">
                Votre projet n'existe pas encore ? Créez-le en commençant par compléter le <span className="font-bold">formulaire de création.</span>
              </div>

              <img src={Perso1} className="absolute left-0 bottom-[-5px]" />
              <img src={Perso2} className="absolute right-0 bottom-[-5px]" />
            </div>
          ) : step === 2 ? (
            <div>
              <img className="mt-10 rounded-md" src={"/images/gif/tuto_2.gif"} />
              <div className="mt-10 text-center text-2xl font-bold text-secoset-green-700">Votre espace</div>
              <div className="mx-2 mt-1 text-center text-sm">
                Tous les projets qui vous sont assignés se trouvent dans votre espace. Cliquez sur un projet pour accéder à son{" "}
                <span className="font-bold">tableau de pilotage.</span>
              </div>

              <img src={Perso3} className="absolute left-0 bottom-[-5px]" />
              <img src={Perso5} className="absolute right-0 bottom-[-5px]" />
            </div>
          ) : step === 3 ? (
            <div className="flex flex-col items-center justify-center">
              <img className="mt-10 rounded-md" src={"/images/gif/tuto_3.gif"} />
              <div className="mt-10 text-center text-2xl font-bold text-secoset-green-700">Complétez les critères !</div>
              <div className="mx-2 mt-1 text-center text-sm">
                La grille de critères vous donne une indication de la progression globale. Complétez chaque critère en accédant à son{" "}
                <span className="font-bold">formulaire !</span>
              </div>

              <img src={Obj1} className="absolute left-0 bottom-[-5px]" />
              <img src={Obj3} className="absolute right-0 bottom-[-5px]" />
            </div>
          ) : null}

          <div className="mt-10 flex flex-row items-center justify-center gap-4">
            <div onClick={() => setStep(1)} className={`h-[10px] w-[10px] cursor-pointer rounded-full ${step === 1 ? "bg-secoset-green-700" : "bg-secoset-green-5"}`} />
            <div onClick={() => setStep(2)} className={`h-[10px] w-[10px] cursor-pointer rounded-full ${step === 2 ? "bg-secoset-green-700" : "bg-secoset-green-5"}`} />
            <div onClick={() => setStep(3)} className={`h-[10px] w-[10px] cursor-pointer rounded-full ${step === 3 ? "bg-secoset-green-700" : "bg-secoset-green-5"}`} />
          </div>

          <div className="mt-10 flex w-full flex-row items-center justify-center gap-2">
            <div className="w-[121px] text-center font-bold text-secoset-green-700">
              <div className="mx-auto w-fit cursor-pointer" onClick={closeTutorial}>
                Passer
              </div>
            </div>
            <div
              className="blue-button w-[121px] cursor-pointer"
              onClick={() => {
                if (step === 3) return closeTutorial();
                setStep((step) => step + 1);
              }}>
              Continuer
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TutorialModal;
