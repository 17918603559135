import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../../components/Loader";

import { ActionInformation } from "./Partials";
import { Indicators } from "./indicators";
import { ModalEducationalSheet } from "./modal_educational_sheet";
import ReactMarkdown from "react-markdown";

import api from "../../../api/apiHandler";
import { BreadCrumps } from "../../../components/BreadCrumps";

export const Criteria = () => {
  const { productionId, criteriaKey } = useParams();
  const [production, setProduction] = useState();
  const [thematic, setThematic] = useState();
  const [criteria, setCriteria] = useState();
  const [openEducationalSheet, setOpenEducationalSheet] = useState();
  const [criterias, setCriterias] = useState();
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    api.get(`/productions/${productionId}`).then(({ data }) => {
      setProduction(data.production);
    });

    api.get(`/labels/criteria/${criteriaKey}`).then(({ data }) => {
      setThematic(data.thematic);
      setCriteria(data.criteria);
    });
    api.get(`/labels/all?productionId=${productionId}`).then(({ data }) => {
      setCriterias(data.criterias);
    });
  }, []);

  useEffect(() => {
    if (production && criteria) {
      setCrumbs([
        { label: production.title, link: `/saas/productions/${production._id}` },
        { label: "Formulaire", link: `/saas/productions/${production._id}/thematics` },
        { label: criteria.title, link: `/saas/productions/${production._id}/criteria/${criteria._id}/` },
      ]);
    }
  }, [production, criteria]);

  if (!production || !thematic || !criteria || !criterias)
    return (
      <div className="h-full bg-white p-5">
        <Loader />
      </div>
    );

  return (
    <div className="flex h-full w-full flex-col gap-4 p-4">
      <ModalEducationalSheet open={openEducationalSheet} setOpen={setOpenEducationalSheet} thematic={thematic} criterias={criterias} />
      <BreadCrumps crumbs={crumbs} setCrumbs={setCrumbs} />
      <section className="flex w-full items-center justify-between bg-white p-4">
        <div className="flex items-center">
          <div className="relative flex items-center">
            <img className="z-30 h-20 w-20" src={`/images/icons/thematics/${thematic.icon}.svg`} alt="Secoset" />
            <div className={`absolute h-20 w-20 rounded-full`} style={{ backgroundColor: thematic.color }} />
          </div>
          <div className="ml-4">
            <h2 className="h2">{criteria.actionTitle}</h2>
            <p className="text-blue-dark">
              Thématique : {thematic.title} / Critère : {criteria.title}
            </p>
          </div>
        </div>
      </section>
      <div className="scrollbar h-full w-full overflow-auto bg-transparent">
        <div className="mb-4 w-full bg-[var(--highlightDiv)] p-4">
          <p className="h4b mb-3">Pour débuter</p>
          <div className="grid h-full w-full grid-cols-[calc(100%_-_366px)_350px] items-start gap-4">
            <p className="hxr mb-3">{"Avez-vous bien pris le temps de consulter la fiche pédagogique de la thématique avant d'aller plus loin ;)"}</p>

            <button className="yellow-button" onClick={() => setOpenEducationalSheet(true)}>
              Fiche pédagogique : {thematic.title}
            </button>
          </div>
        </div>
        <div className="mb-4 w-full bg-white p-4">
          <ActionInformation description={criteria.description} tips={criteria.actionTips} services={criteria.actionDepartments} />
        </div>

        <Indicators criteria={criteria} production={production} setProduction={setProduction} />

        <div className="mb-4 w-full bg-white p-4">
          <p className="h4b mb-3">{"Lien(s) utile(s)"}</p>
          <div>
            <div className="flex h-full w-full items-center">
              <img src={`/images/icons/check.svg`} alt="check" />
              <div className="flex flex-col py-2 px-3">
                <ReactMarkdown>{`[Annuaire prestataires](/saas/help/directory?thematic=${thematic.thematicKey})`}</ReactMarkdown>
              </div>
            </div>
            {criteria.actionLinks.map((link, i) => {
              return (
                <div key={i} className="flex h-full w-full items-center gap-3">
                  <img src={`/images/icons/check.svg`} alt="check" />
                  <ReactMarkdown>{link.replace("\\n", "\n")}</ReactMarkdown>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
