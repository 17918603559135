import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import * as Sentry from "@sentry/react";

import api from "../../api/apiHandler";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import { useDispatch } from "react-redux";
import { setUser } from "../../redux/authSlice";

import { LoginRight } from "./commons";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (!validator.isEmail(values.email)) errors.email = "Adresse email invalide";
    if (validator.isEmpty(values.password)) errors.password = "Ce champ est requis";
    setErrors(errors);
    return !errors.email && !errors.password;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    api
      .post("/saas-auth/signin", values)
      .then((res) => {
        const user = res.data.user;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response?.data?.message === "UNVERIFIED_EMAIL") {
          return setErrors({ password: "Merci de vérifier votre adresse email afin de pouvoir vous connecter." });
        }
        if (error.response?.status === 400) {
          navigate("/sent-email", { state: { email: values.email } });
        } else setErrors({ password: "Email ou mot de passe incorrect" });
      });
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleSubmit}>
        {({ values, errors, handleChange }) => (
          <Form className="mb-6 flex items-center justify-center py-10 lg:w-[50%]">
            <div className="flex w-[360px] flex-col items-center justify-center gap-4">
              <img className="w-44" src={LogoSecoset} />
              <div className="text-gray-500">Connectez-vous</div>
              <div>
                <div className="mb-1 font-bold text-gray-500">Email</div>
                <input className="saas-input w-[360px]" placeholder="john@ugc.fr" name="email" type="email" value={values.email} onChange={handleChange} />
                {errors.email && <div className="text-sm text-red">{errors.email}</div>}
              </div>
              <div>
                <div className="mb-1 font-bold text-gray-500">Mot de passe</div>
                <div className="saas-input flex w-[360px] flex-row items-center justify-between ">
                  <input
                    className=" w-[360px]"
                    placeholder="Votre mot de passe"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passwordVisible ? (
                    <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                  ) : (
                    <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                  )}
                </div>
                {errors.password && <div className="mt-1 text-sm text-red">{errors.password}</div>}
              </div>
              <div>
                Mot de passe oublié ?{" "}
                <Link to="/forgot-password" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                  Réinitialisez votre mot de passe
                </Link>
              </div>
              <button className="blue-button mt-4 mb-8 w-[360px] text-lg" type="submit">
                Se connecter
              </button>
              <div>
                Pas encore de compte ?{" "}
                <Link to="/signup" className="cursor-pointer font-bold text-secoset-green-400 hover:underline">
                  Inscrivez-vous
                </Link>
              </div>
              <button className="text-xs text-white mt-3" onClick={() => methodDoesNotExistsecoset()}>
                Break the world
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <LoginRight />
    </div>
  );
};

export default Signin;
