import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Select({ options, value, onChange, label, readOnly = false, placeholder = "Sélectionner une option" }) {
  const selected = options.find((option) => option.value === value);

  return (
    <Listbox value={selected} onChange={(option) => !readOnly && onChange(option.value)}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            <Listbox.Button className="relative w-full text-left">
              <div className={` ${readOnly ? "cursor-default" : "cursor-pointer"} flex items-center gap-0 space-y-0 rounded border-[1px] border-[#798EA2] bg-white py-3 px-4`}>
                <div className="flex w-full items-center justify-between">
                  <div>
                    <p className="text-xs text-secoset-green-900">{label}</p>
                    <p className="block h-5 truncate text-sm">{selected?.label ? selected.label : placeholder}</p>
                  </div>
                  <div className="pointer-events-none flex items-center pr-2">
                    {!readOnly && open && <BsChevronUp className="h-4 w-4 text-gray-900" aria-hidden="true" />}
                    {!readOnly && !open && <BsChevronDown className="h-4 w-4 text-gray-900" aria-hidden="true" />}
                  </div>
                </div>
              </div>
            </Listbox.Button>

            <Transition show={!readOnly && open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options
                  .sort((a, b) => a?.label?.localeCompare(b?.label))
                  .map((option, i) => (
                    <Listbox.Option
                      key={i}
                      className={({ active }) => classNames(active ? "bg-blue-600 text-white" : "text-gray-900", "relative cursor-default select-none list-none py-2 pl-3 pr-9")}
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate text-sm")}>{option.label}</span>
                          {selected && (
                            <span className={classNames(active ? "text-white" : "text-blue-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                              <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
