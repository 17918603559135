import { CinemaIcon, TournageIcon, AdIcon } from "./Icons";

export const MovieBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-gray-badge py-1 px-2">
    <CinemaIcon />
    <p className="hzb">Long Métrage</p>
  </div>
);

export const SeriesBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#BDE0D6] py-1 px-2">
    <CinemaIcon />
    <p className="hzb">Série</p>
  </div>
);

export const ShortMovieBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#CDC6D4] py-1 px-2">
    <CinemaIcon />
    <p className="hzb">Court Métrage</p>
  </div>
);

export const VideoClipBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#FFF1B1] py-1 px-2">
    <TournageIcon />
    <p className="hzb">Vidéo-clip</p>
  </div>
);

export const AdBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#CAF1F6] py-1 px-2">
    <AdIcon />
    <p className="hzb">Pub</p>
  </div>
);

export const StreamBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#D49BA6] py-1 px-2">
    <CinemaIcon />
    <p className="hzb">Émission de Flux</p>
  </div>
);

export const DocumentaireBadge = () => (
  <div className="absolute top-2 left-2 flex items-center gap-2 border-2 border-white bg-[#EE4266] py-1 px-2">
    <TournageIcon />
    <p className="hzb">Documentaire</p>
  </div>
);

export const productionIcons = {
  "Long-Métrage": <MovieBadge />,
  Série: <SeriesBadge />,
  "Court-métrage": <ShortMovieBadge />,
  "Vidéo-clip": <VideoClipBadge />,
  Pub: <AdBadge />,
  "Émission de Flux": <StreamBadge />,
  Documentaire: <DocumentaireBadge />,
};
