import React, { useEffect, useState } from "react";
import { HiUserGroup } from "react-icons/hi2";
import api from "../../api/apiHandler";
import { FiEdit2 } from "react-icons/fi";
import { BsCheckLg } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { Modal } from "../../components/Modal";
import { HiOutlineX, HiInformationCircle, HiChevronDown } from "react-icons/hi";
import { BiTrash } from "react-icons/bi";
import Pagination from "@mui/material/Pagination";

import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { SuccessModal } from "../../components/SuccessModal";

import { AiFillCopy } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { Listbox } from "@headlessui/react";

import Select from "../../components/Select-bis";
import { TablePaginator } from "../../components/table";

const roleLabels = {
  owner: "Propriétaire",
  admin: "Administrateur",
  user: "Utilisateur",
};

const Users = () => {
  const { user } = useSelector((state) => state.Auth);
  const [selectedOrganization, setSelectedOrganization] = useState();

  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [users, setUsers] = useState();
  const [projects, setProjects] = useState([]);
  const [arrowUp, setArrowUp] = useState(false);

  const [showModalInvitation, setShowModalInvitation] = useState(searchParams.get("invit") ? true : false);
  const [modalDeleteUser, setModalDeleteUser] = useState({ isOpen: false, user: null });
  const [modalUpdateUser, setModalUpdateUser] = useState({ isOpen: false, user: null });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const pageSize = 6;

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    api
      .get(`/organization/user/${user._id}`)
      .then((res) => {
        if (res.data.ok) {
          setOrganizations(res.data.data);
          setSelectedOrganization(res.data.data[0]);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (!selectedOrganization) return;
    getData();
  }, [selectedOrganization]);

  const getData = async () => {
    api
      .get(`/organization/${selectedOrganization._id}/users`)
      .then((res) => {
        if (res.data.ok) {
          setUsers(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    api
      .get(`/organization/${selectedOrganization._id}/productions`)
      .then((res) => {
        if (res.data.ok) {
          setProjects(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  if (!users) return <div>Chargement...</div>;

  return (
    <div>
      <div className="mb-7 flex items-center justify-between">
        <div className="text-3xl font-bold text-secoset-green-700">Utilisateurs</div>
        <div className="flex items-center gap-4">
          <div
            onClick={() => setShowModalInvitation(true)}
            className="flex w-fit cursor-pointer items-center justify-center gap-2 rounded border-[1px] border-transparent bg-secoset-green-700 py-3   px-5 text-white hover:border-secoset-green-900 hover:bg-white hover:text-secoset-green-900">
            <HiUserGroup className="text-2xl" />
            <div className="font-bold">Inviter</div>
          </div>
          <div className="w-[360px]">
            <Select
              options={organizations.map((o) => ({ label: o.name, value: o }))}
              value={selectedOrganization}
              placeholder={selectedOrganization.name}
              onChange={setSelectedOrganization}
            />
          </div>
        </div>
      </div>

      <div className="mb-4 w-fit rounded-xl bg-secoset-green-5 px-2 py-1 text-xs font-medium text-secoset-green-700">
        {users.length > 1 ? users.length + " utilisateurs" : users.length + " utilisateur"}
      </div>

      {/* Users */}

      <TablePaginator
        data={users.slice((page - 1) * pageSize, page * pageSize)}
        length={users.length}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        renderHeader={() => (
          <>
            <div className="w-[30%] px-6">Utilisateurs</div>
            <div className="w-[25%] px-6">Projets</div>
            <div className="w-[15%] px-6">Date d'invitation</div>
            <div className="w-[15%] px-6">Status</div>
          </>
        )}
        renderItem={(item) => {
          return (
            <>
              <div className="flex w-[30%] items-center justify-between px-6">
                <div className="flex items-center">
                  <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-secoset-green-5 text-xs text-secoset-green-900">
                    <span className="font-bold">{item.firstName ? `${item.firstName?.charAt(0).toLocaleUpperCase()}${item.lastName?.charAt(0).toLocaleUpperCase()}` : "/"}</span>
                  </div>
                  <div className="ml-8 flex flex-col">
                    <span className="font-semibold">{`${item.firstName || ""} ${item.lastName || ""}`}</span>
                    <span>{item.email}</span>
                  </div>
                </div>
                <button onClick={() => setModalUpdateUser({ isOpen: true, user: item })}>
                  <FiEdit2 className="text-lg text-secoset-green-900" />
                </button>
              </div>
              <div className="line-clamp-2 w-[25%] px-6">{item.productions?.map((p) => p.title).join(", ")}</div>
              <div className="w-[15%] px-6">{new Date(item.createdAt).toLocaleDateString("fr")}</div>
              <div className="w-[10%] px-6">
                {item.status === "VALIDATED" ? (
                  <div className="flex w-fit items-center gap-1 rounded-xl bg-[#ECFDF3] px-2 py-1">
                    <div className="h-[6px] w-[6px] rounded-full bg-[#12B76A]" />
                    <div className="text-xs font-medium text-[#027A48]">Activé</div>
                  </div>
                ) : (
                  <div className="flex w-fit  items-center gap-1 rounded-xl bg-gray-200 px-2 py-1">
                    <div className="h-[6px] w-[6px] rounded-full bg-gray-500" />
                    <div className="truncate text-xs font-medium text-gray-700">En attente</div>
                  </div>
                )}
              </div>
              {console.log("user", user._id, item._id, user._id.toString() !== item._id.toString())}
              <div className="w-[5%] pl-3">
                {user._id.toString() !== item._id.toString() && (
                  <button onClick={() => setModalDeleteUser({ isOpen: true, user: item })}>
                    <BiTrash className="text-lg text-secoset-green-900" />
                  </button>
                )}
              </div>
            </>
          );
        }}
      />

      <Modal isOpen={showModalInvitation} onClose={() => setShowModalInvitation(false)}>
        <ModalInvitation
          isOpen={showModalInvitation}
          projects={projects}
          onSuccess={() => {
            getData();
            setShowModalInvitation(false);
            setShowSuccessModal(true);
          }}
          organizationId={selectedOrganization._id}
          onClose={() => setShowModalInvitation(false)}
        />
      </Modal>

      <Tooltip id="role-description" className="text-sm">
        Simple : peut suivre et remplir les critères Secoset de ses projets. <br />
        Administrateur : peut créer des projets, inviter des utilisateurs et gérer la facturation de la société.
      </Tooltip>

      <Modal isOpen={modalDeleteUser.isOpen} onClose={() => setModalDeleteUser({ isOpen: false, user: null })}>
        <ModalDeleteUser
          getData={getData}
          user={modalDeleteUser.user}
          onClose={() => setModalDeleteUser({ isOpen: false, user: null })}
          organizationId={selectedOrganization._id}
        />
      </Modal>

      <Modal isOpen={modalUpdateUser.isOpen} onClose={() => setModalUpdateUser({ isOpen: false, user: null })}>
        <ModalModifyUser
          onClose={() => setModalUpdateUser(false)}
          user={modalUpdateUser.user}
          // userProjects={userProjects}
          projects={projects}
          getData={getData}
          organizationId={selectedOrganization._id}
        />
      </Modal>
      <Modal isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <SuccessModal
          closeModal={() => setShowSuccessModal(false)}
          titre="Les membres ont été invités."
          text="Félicitations, vos utilisateurs ont bien été invités. Ils recevront sous peu un email d’invitation pour activer leur compte."
        />
      </Modal>
    </div>
  );
};

const ModalModifyUser = ({ onClose, user, projects, getData, organizationId }) => {
  const roleInit = user.organizations.find((e) => e.organization.toString() === organizationId)?.role;
  const [role, setRole] = useState(roleInit);
  const [projectsSelected, setProjectsSelected] = useState(projects.filter((p) => p.users.find((e) => e.toString() === user._id.toString())));
  const [loading, setLoading] = useState(false);

  const saveUser = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await api.put(`/organization/${organizationId}/user/${user._id}`, { role, productions: projectsSelected.map((e) => e._id) });
      getData();
      onClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  console.log("projectsSelected", projectsSelected);
  return (
    <div className=" w-[762px] rounded-2xl bg-white px-10">
      <div className="text-center text-2xl font-bold text-secoset-green-900">Modifier un utilisateur</div>
      <div className={`mt-1 flex w-full items-center bg-secoset-green-700 py-3 text-sm font-bold text-secoset-green-5`}>
        <div className="flex w-[50%] items-center gap-1 px-2">
          <div>Rôle</div>
          <HiInformationCircle data-tooltip-id="role-description-modal" className="text-secoset-green-5" />
        </div>
        <div className="w-[50%] px-2">Projet</div>
      </div>
      <div className="relative flex w-full font-bold">
        <div className="w-[50%]">
          <RoleDropDown value={role} handleChange={(role) => setRole(role)} />
        </div>
        <div className="w-[50%]">
          <ProjectDropDown options={projects} value={projectsSelected} handleChange={setProjectsSelected} />
        </div>
        <div className="absolute bottom-0  h-[5px] w-full  border-b-[1px] border-secoset-green-5" />
      </div>
      <div className="mb-8 mt-10 flex w-full gap-4">
        <div onClick={onClose} className="w-[50%] cursor-pointer rounded border-[1px] border-secoset-green-900 py-3 text-center font-bold text-secoset-green-900">
          Annuler
        </div>
        <div
          onClick={() => saveUser()}
          className={`flex w-[50%] cursor-pointer items-center justify-center rounded  bg-secoset-green-700 text-center font-bold text-secoset-green-5`}>
          {loading ? <CircularProgress style={{ color: "white" }} size={30} /> : <div className="py-3">Sauvegarder</div>}
        </div>
      </div>
      <Tooltip id="role-description-modal" className="text-sm">
        Simple : peut suivre et remplir les critères Secoset de ses projets. <br />
        Administrateur : peut créer des projets, inviter des utilisateurs et gérer la facturation de la société.
      </Tooltip>
    </div>
  );
};

const ModalInvitation = ({ isOpen, onClose, projects, organizationId, onSuccess }) => {
  const [emailView, setEmailView] = useState(true);

  const [emailInput, setEmailInput] = useState("");
  const [emailInputArray, setEmailInputArray] = useState([]);

  const [userArray, setUserArray] = useState([]);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const pageSize = 3;

  const [invitValue, setInvitValue] = useState({ role: null, project: [] });
  const [invitLink, setInvitLink] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // check if there is a space --> new email
    if (emailInput.includes(" ") && emailInput.length > 1) {
      setEmailInputArray([...emailInputArray, emailInput]);
      setEmailInput("");
    }
  }, [emailInput]);

  // check if tthere is user in userArray && if every user has a role and a project --> enable button
  const disabledButton = userArray.length === 0 || userArray.some((user) => !user.role || !user.project || (user.project.length === 0 && projects.length > 0));

  const disabledInvitButton = invitValue.role === null || (invitValue.project.length === 0 && projects.length > 0 && invitValue.role === "user");

  const add = () => {
    // handle the case where emailINputArray is empty but we have an email in emailInput
    if (emailInputArray.length === 0 && emailInput.length > 1) {
      setEmailInput("");
      // unique email
      if (userArray.some((user) => user.email === emailInput)) return setError("L'email est déjà dans la liste.");
      return setUserArray((oldArray) => [...oldArray, { email: emailInput, role: null, project: [] }]);
    }
    setUserArray((oldArray) => {
      // remove possible doublons
      const newArray = emailInputArray.map((email) => ({ email, role: null, project: [] }));
      const filteredArray = newArray.filter((newUser) => !oldArray.some((oldUser) => oldUser.email === newUser.email));
      return [...oldArray, ...filteredArray];
    });
    setEmailInputArray([]);
  };

  useEffect(() => {
    // dont know if its necessary but look better
    if (error !== "") {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  const removeEmail = (email) => {
    setEmailInputArray(emailInputArray.filter((e) => e !== email));
  };

  const removeUser = (user) => {
    setUserArray(userArray.filter((u) => u !== user));
  };

  const updateUserRole = (u2, role) => {
    if (role === "admin") return setUserArray(userArray.map((u) => (u === u2 ? { ...u, role, project: projects } : u)));
    setUserArray(userArray.map((u) => (u === u2 ? { ...u, role, project: [] } : u)));
  };
  const updateUserProjects = (u2, project) => {
    setUserArray((oldArray) => oldArray.map((u) => (u === u2 ? { ...u, project } : u)));
  };

  const inviteUsers = async () => {
    try {
      if (loading || disabledButton) return;
      setLoading(true);
      const res = await api.post(`/organization/${organizationId}/invite`, { users: userArray });
      setLoading(false);
      onSuccess();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const generateInviteLink = async () => {
    try {
      if (loading || disabledInvitButton) return;
      setLoading(true);
      const res = await api.post(`/organization/${organizationId}/generate-invite-link`, { projects: invitValue.project, role: invitValue.role });
      setInvitLink(res.data.link);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setInvitLink("");
    setCopied(false);
  }, [invitValue]);

  useEffect(() => {
    if (invitValue.role === "admin") {
      setInvitValue({ ...invitValue, project: projects });
    } else {
      setInvitValue({ ...invitValue, project: [] });
    }
  }, [invitValue.role]);

  return (
    <div className="flex w-[760px] flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center px-10">
        <div className="text-3xl font-bold text-secoset-green-700">Invitez des utilisateurs</div>

        <div className="mt-7 flex w-full items-center justify-start gap-4 pb-2">
          <div onClick={() => setEmailView(true)} className={`cursor-pointer p-2 font-bold text-gray-500 ${emailView && "rounded-lg bg-secoset-green-5 text-secoset-green-700"}`}>
            Par email
          </div>
          <div onClick={() => setEmailView(false)} className={`cursor-pointer p-2 font-bold text-gray-500 ${!emailView && "rounded-lg bg-secoset-green-5 text-secoset-green-700"}`}>
            Par lien
          </div>
        </div>
      </div>

      <div className="mt-2 mb-4 h-[2px] w-full bg-[#CDEAF4]" />

      {emailView ? (
        <div className="flex w-full flex-col items-start justify-center px-10">
          <div className="rounded-lg border-[1px] border-[#CDEAF4] p-2 text-xs font-medium text-secoset-green-700">
            <span className="font-bold">Entrez les adresses email</span> des personnes que vous souhaitez inviter sur Secoset. Elles recevront un email d’invitation et auront{" "}
            <span className="font-bold">15 jours pour activer leur compte</span>.
          </div>

          <div className="mt-6 text-start font-bold text-gray-500">1. Commencez par ajouter un email</div>
          <div className="mt-1 mb-6 flex w-full flex-row items-center justify-center gap-4">
            <div className=" flex w-[90%] flex-wrap gap-2 rounded border-[1px] border-secoset-green-700 px-4 py-3 focus:border-secoset-green-400">
              {emailInputArray.map((email) => (
                <div key={email} className="flex w-fit items-center justify-between gap-2 rounded border-[1px] border-secoset-green-700 px-2 py-1">
                  <div className="font-bold text-secoset-green-700">{email}</div>
                  <HiOutlineX onClick={() => removeEmail(email)} className="h-[14px] w-[14px] cursor-pointer" />
                </div>
              ))}
              <input
                className={`${emailInputArray.length === 0 && "w-[90%]"}`}
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder={`${emailInputArray.length === 0 ? "Ajoutez un ou plusieurs emails en les séparant par des espaces" : ""}`}
              />
            </div>

            <button className="blue-button" onClick={add}>
              Ajouter
            </button>
          </div>
          {error && <div className="text-sm font-medium text-red">{error}</div>}
          <div className={`mt-6 text-start font-bold text-gray-500 ${userArray.length === 0 && "opacity-30"}`}>2. Sélectionnez un rôle et le projet à attribuer</div>
          <div className={`mt-1 flex w-full items-center bg-secoset-green-700 py-3 text-sm font-bold text-secoset-green-5 ${userArray.length === 0 && "opacity-30"}`}>
            <div className="w-[35%] px-2">Utilisateurs</div>
            <div className="flex w-[30%] items-center gap-1 px-2">
              <div>Rôle</div>
              <HiInformationCircle data-tooltip-id="role-description-modal" className="text-secoset-green-5" />
            </div>
            <div className="w-[30%] px-2">Projet</div>
            <div className="w-[5%]" />
          </div>
          <div className="mb-4 max-h-[180px] min-h-[80px] w-full ">
            {userArray.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize).map((u) => (
              <div key={u.email}>
                <div className="flex h-full w-full items-center font-bold">
                  <div className="w-[35%] truncate px-2 py-3 font-normal">{u.email}</div>
                  <div className="w-[30%]">
                    <RoleDropDown value={u.role} handleChange={(role) => updateUserRole(u, role)} />
                  </div>
                  <div className="w-[30%]">
                    <ProjectDropDown
                      disabled={u.role === "admin" || projects.length === 0}
                      options={projects}
                      value={u.project}
                      handleChange={(value) => updateUserProjects(u, value)}
                    />
                  </div>
                  <BiTrash onClick={() => removeUser(u)} className="w-[5%] cursor-pointer " />
                </div>
                <div className="h-[5px] w-full  border-b-[1px] border-secoset-green-5" />
              </div>
            ))}
          </div>
          <div className="mt-2 mb-4">
            {userArray.length > 0 ? <Pagination count={Math.ceil(userArray.length / pageSize)} page={page} onChange={(event, page) => setPage(page)} /> : null}
          </div>

          <div className="mb-8 mt-2 flex w-full gap-4">
            <div onClick={onClose} className="w-[50%] cursor-pointer rounded border-[1px] border-secoset-green-900 py-3 text-center font-bold text-secoset-green-900">
              Annuler
            </div>
            <div
              onClick={() => inviteUsers()}
              className={`flex w-[50%] cursor-pointer items-center justify-center rounded bg-secoset-green-700  text-center font-bold text-secoset-green-5 ${
                disabledButton && "cursor-not-allowed opacity-30"
              }`}>
              {loading ? <CircularProgress style={{ color: "white" }} size={30} /> : <div className="py-3">Inviter</div>}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col items-start justify-center px-10">
          <div className={`text-start font-bold text-gray-500`}>1. Commencez par choisir un rôle et un/des projet(s)</div>
          <div className={`mt-1 flex w-full items-center bg-secoset-green-700 py-3 text-sm font-bold text-secoset-green-5`}>
            <div className="flex w-[50%] items-center gap-1 px-4">
              <div>Rôle</div>
              <HiInformationCircle data-tooltip-id="role-description-modal" className="text-secoset-green-5" />
            </div>
            <div className="w-[50%] px-4">Projet</div>
          </div>
          <div className="flex h-12 w-full items-center font-bold">
            <div className="w-[50%]">
              <RoleDropDown value={invitValue.role} handleChange={(role) => setInvitValue({ ...invitValue, role })} />
            </div>
            <div className="w-[50%]">
              <ProjectDropDown
                disabled={invitValue.role === "admin" || projects.length === 0}
                options={projects}
                value={invitValue.project}
                handleChange={(project) => setInvitValue({ ...invitValue, project })}
              />
            </div>
          </div>
          <div className="h-[5px] w-full  border-b-[1px] border-secoset-green-5" />
          <div className={`w-full ${disabledInvitButton && "opacity-30"}`}>
            <div className={`mt-6 text-start font-bold text-gray-500`}>2. Envoyez le lien ci-dessous aux utilisateurs que vous souhaitez inviter</div>
            <div className="mb-10 mt-1 flex w-full gap-4">
              {invitLink === "" ? (
                <>
                  <div className="saas-input w-[90%] flex-1 bg-secoset-green-5 text-sm font-bold text-[#AEAEAE]">https://www.secoset.fr/blablablabla...</div>
                  <button
                    onClick={generateInviteLink}
                    className={`flex  cursor-pointer items-center justify-center rounded bg-secoset-green-700  px-6 text-center font-bold text-secoset-green-5`}>
                    {loading ? <CircularProgress style={{ color: "white" }} size={30} /> : <div className="py-3">Générer</div>}
                  </button>
                </>
              ) : (
                <>
                  <div className="saas-input w-[90%] flex-1 truncate text-sm font-medium text-secoset-green-700">{invitLink}</div>
                  <button
                    onClick={() => {
                      setCopied(true);
                      navigator.clipboard.writeText(invitLink);
                    }}
                    className={`flex cursor-pointer items-center justify-center rounded bg-secoset-green-700  px-6 text-center font-bold text-secoset-green-5`}>
                    <AiFillCopy className="mr-2" />
                    <div className="py-3">{copied ? "Copié !" : "Copier le lien"}</div>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <Tooltip id="role-description-modal" className="text-sm">
        Simple : peut suivre et remplir les critères Secoset de ses projets. <br />
        Administrateur : peut créer des projets, inviter des utilisateurs et gérer la facturation de la société.
      </Tooltip>
    </div>
  );
};

const RoleDropDown = ({ value, handleChange, disabled = false }) => {
  const options = [
    { value: "admin", title: "Administrateur" },
    { value: "user", title: "Simple" },
  ];
  return (
    <div className="relative h-full w-full">
      <Listbox value={value} disabled={disabled} onChange={handleChange}>
        {({ open }) => (
          <>
            <Listbox.Button className={`relative h-full w-full px-2 py-3 text-start text-sm ${open && "bg-secoset-green-5"}`}>
              <div className="flex flex-row items-center justify-between">
                <div className="text-sm">{value ? options.find((o) => o.value === value).title : "Sélectionnez un rôle*"}</div>
                {!disabled ? <HiChevronDown className="h-4 w-4 text-gray-500" /> : null}
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute z-20 max-h-60 w-full overflow-auto bg-white shadow-lg">
              {options.map((option) => (
                <Listbox.Option className="relative cursor-pointer p-2 hover:bg-gray-50" key={option.title} value={option.value}>
                  <div className="flex flex-row items-center justify-between">
                    <div>{option.title}</div>
                    {value === option.value ? (
                      <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-secoset-green-700">
                        <BsCheckLg className="h-[10px] w-[12px] text-white" />
                      </div>
                    ) : (
                      <div className="flex h-[18px] w-[18px] rounded-full border-[2px] border-black" />
                    )}
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

const ProjectDropDown = ({ options, value, handleChange, disabled = false }) => {
  return (
    <div className="relative h-full w-full">
      <Listbox value={value} disabled={disabled} onChange={handleChange} multiple>
        {({ open }) => (
          <>
            <Listbox.Button className={`relative h-full w-full px-2 py-3 text-start text-sm ${open && "bg-secoset-green-5"}`}>
              <div className="flex flex-row items-center justify-between">
                {value.length === 0 && options.length === 0 ? (
                  <div>Aucun projet disponible</div>
                ) : value.length === 0 ? (
                  <div>Sélectionnez un projet*</div>
                ) : value.length === 1 ? (
                  <div>{value[0].title}</div>
                ) : value.length === options.length ? (
                  <div>Tous</div>
                ) : (
                  <div className={`flex items-center gap-2`}>
                    <div>{value[0]?.title}</div>
                    <div className="rounded-2xl bg-gray-200 px-1.5 py-1 text-xs">+{value.length - 1}</div>
                  </div>
                )}
                {!disabled ? <HiChevronDown className="h-4 w-4 text-gray-500" /> : null}
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute z-20 max-h-60 w-full overflow-auto bg-white shadow-lg">
              {options.map((option) => (
                <Listbox.Option className="relative cursor-pointer p-2 hover:bg-gray-50" key={option._id} value={option}>
                  <div className="flex flex-row items-center justify-between">
                    <div>{option?.title}</div>
                    {value.find((p) => p._id.toString() === option._id.toString()) !== undefined ? (
                      <div className="flex h-[18px] w-[18px] items-center justify-center rounded-sm bg-secoset-green-700">
                        <BsCheckLg className="h-[10px] w-[12px] text-white" />
                      </div>
                    ) : (
                      <div className="flex h-[18px] w-[18px] rounded-sm border-[2px] border-black" />
                    )}
                  </div>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

const ModalDeleteUser = ({ organizationId, user, onClose, getData }) => {
  const [loading, setLoading] = useState(false);
  const removeUser = async () => {
    try {
      await api.delete(`/organization/${organizationId}/user/${user._id}`);
      getData();
      onClose();
    } catch (err) {
      console.error(err);
      onClose();
    }
  };
  return (
    <div className="flex w-[760px] flex-col items-center justify-center px-10 ">
      <div className="mb-8 text-3xl font-bold text-secoset-green-700">Suppression de l'utilisateur</div>
      <div>Vous êtes sur le point de retirer l'utilisateur {user.email} de l'organisation</div>
      <div>L'utilisateur n'aura plus accès aux projets de l'organisation</div>
      <div className="mb-8 mt-8 flex w-full gap-4">
        <div onClick={onClose} className="w-[50%] cursor-pointer rounded border-[1px] border-secoset-green-900 py-3 text-center font-bold text-secoset-green-900">
          Annuler
        </div>
        <div
          onClick={() => removeUser()}
          className={`flex w-[50%] cursor-pointer items-center justify-center rounded  bg-secoset-green-700 text-center font-bold
          text-secoset-green-5`}>
          {loading ? <CircularProgress style={{ color: "white" }} size={30} /> : <div className="py-3">Confirmer</div>}
        </div>
      </div>
    </div>
  );
};

export default Users;
