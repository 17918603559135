// component for forgetting password or adding password to an account after invitation
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import validator from "validator";
import { Formik, Form } from "formik";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import api from "../../api/apiHandler";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import { LoginRight } from "./commons";

const Password = () => {
  const [searchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [changed, setChanged] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.password)) errors.password = "Ce champ est requis";
    else if (!validator.equals(values.password, values.confirmPassword)) errors.confirmPassword = "Les mots de passe ne correspondent pas";
    setErrors(errors);
    return !errors.password && !errors.confirmPassword;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    const token = searchParams.get("token");
    api
      .post("/saas-auth/reset-password", { ...values, token })
      .then((res) => setChanged(true))
      .catch((error) => {
        console.log("error", error);
        setErrors({ email: "Une erreur est survenue" });
      });
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <div className="flex items-center justify-center lg:w-[50%]">
        <div className="flex w-[360px] flex-col items-center">
          <img className="w-44" src={LogoSecoset} />
          <div className="text-[#667085]">Réinitiliser votre mot de passe</div>
          {!changed ? (
            <Formik initialValues={{ password: "", confirmPassword: "" }} onSubmit={handleSubmit}>
              {({ values, errors, handleChange }) => (
                <Form className="my-6 flex flex-col items-center justify-center gap-4">
                  <div>
                    <div className="mb-1 font-bold text-gray-500">Entrez votre nouveau mot de passe</div>
                    <div className="saas-input flex w-[360px] flex-row items-center justify-between ">
                      <input
                        className="w-full"
                        placeholder="Votre mot de passe"
                        name="password"
                        type={passwordVisible ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange}
                      />
                      {passwordVisible ? (
                        <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(false)} />
                      ) : (
                        <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setPasswordVisible(true)} />
                      )}
                    </div>
                    {errors.password && <div className="mt-1 text-sm text-red">{errors.password}</div>}
                  </div>
                  <div>
                    <div className="mb-1 font-bold text-gray-500">Confirmez votre mot de passe</div>
                    <div className="saas-input flex w-[360px] flex-row items-center justify-between ">
                      <input
                        className="w-full"
                        placeholder="Confirmer mot de passe"
                        name="confirmPassword"
                        type={passwordVisible ? "text" : "password"}
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                      {confirmPasswordVisible ? (
                        <AiFillEyeInvisible className="cursor-pointer text-2xl text-blue-dark" onClick={() => setConfirmPasswordVisible(false)} />
                      ) : (
                        <AiFillEye className="cursor-pointer text-2xl text-blue-dark" onClick={() => setConfirmPasswordVisible(true)} />
                      )}
                    </div>
                    {errors.confirmPassword && <div className="mt-1 text-sm text-red">{errors.confirmPassword}</div>}
                  </div>
                  <button className="blue-button mt-4 mb-8 w-[360px] text-lg" type="submit">
                    Réinitiliser le mot de passe
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="mt-6 flex flex-col items-center justify-center gap-4">
              <div className="text-center text-gray-500">Votre mot de passe a été réinitialisé avec succès</div>
              <Link to="/signin" className="blue-button mt-4 mb-8 w-[360px] text-lg">
                Se connecter
              </Link>
            </div>
          )}
        </div>
      </div>

      <LoginRight />
    </div>
  );
};

export default Password;
