import React, { useState, useEffect } from "react";
import { BiXCircle, BiCheckCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { Modal } from "../../../components/Modal";
import api from "../../../api/apiHandler";

import jsPDF from "jspdf";
import { evaluate } from "mathjs";

export const ModalDeleteProduction = (props) => {
  const { open, setOpen, production, close } = props;
  const [errorInfos, setErrorInfos] = useState(null);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await api.delete(`/productions/${production._id}`);
      navigate("/saas/");
    } catch {
      setErrorInfos;
    }
  };

  return (
    <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="bg-white px-12 pt-6 pb-12">
        <FeedBack message={errorInfos} />
        <p className="my-6 text-2xl font-bold"> Êtes-vous sûr.e de vouloir supprimer le projet "{production.title}" ?</p>
        <p>Vous ne pourrez plus retrouver les données par la suite, l'ensemble des informations seront définitivement supprimées.</p>
        <div className="my-6 flex justify-end gap-4">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Annuler
          </button>
          <button className="blue-button" onClick={handleDelete}>
            Confirmer
          </button>
        </div>
      </div>
    </Modal>
  );
};

const FeedBack = (props) => {
  const { setErrorInfos, message } = props;
  const handleClose = () => {
    setErrorInfos(null);
  };

  if (!message) {
    return null;
  }
  return (
    <div className="mb-4 flex items-center justify-between bg-[#FDEDED] p-2">
      <div className="flex items-center">
        <img src={`/images/icons/indicators/error.svg`} style={{ height: "32px" }} />
        <p className="mr-">{message}</p>
      </div>

      <button className="mr-2" onClick={handleClose} fontSize="small">
        <BiXCircle />
      </button>
    </div>
  );
};

export const ModalOfficialLabel = (props) => {
  const { open, setOpen, production } = props;
  const type = production.status;
  const [criterias, setCriterias] = useState([]);
  const [criteriasUnvalidated, setCriteriasUnvalidated] = useState([]);
  const criteriasValidated = calculateCriteriasValidated(production);

  useEffect(() => {
    const fetchData = async () => {
      await api.get(`/labels/all?productionId=${production._id}`).then((res) => {
        setCriterias(res.data.criterias);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (criterias.length > 0) {
      setCriteriasUnvalidated(reportCriteriasUnvalidated(production, criterias));
    }
  }, [criterias]);

  if (type === "validated") {
    return (
      <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
        <div className="bg-white px-12 pt-6 pb-12">
          <p className="my-4 text-2xl font-bold">Félicitations !</p>
          <p className="my-4">
            Votre projet est labellisé <strong className="font-bold"> Production Responsable® </strong>, félicitations, et merci pour votre travail.
          </p>
          <p className="my-4">
            Vous pouvez désormais télécharger votre label officiel et votre kit de communication <strong className="font-bold"> Production Responsable® </strong>.
          </p>
          <p className="my-4">À bientôt !</p>
          <p className="my-4 text-xl font-bold">Bilan</p>
          {production.documents.length > 0 &&
            production.documents.map((doc) => {
              return (
                <div className="flex">
                  <img src={`/images/icons/document.svg`} alt="document" />
                  <a className="text-blue-dark underline underline-offset-1" href={`${doc}`} download>
                    {doc.match(/\/([^\/?#]+)[^\/]*$/)[1]}
                  </a>
                </div>
              );
            })}

          {production.documents.length === 0 && (
            <div className="flex">
              <img src={`/images/icons/document.svg`} alt="document" />
              <p>Aucun document n'a été transmis pour cette production</p>
            </div>
          )}

          <div className="my-4 flex justify-end">
            <button className="blue-button flex items-center" onClick={() => setOpen(false)}>
              <BiCheckCircle className="mr-2" />
              Ok
            </button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === "rejected") {
    return (
      <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
        <div className="bg-white px-12 pt-6 pb-12">
          <p className="my-4 text-2xl font-bold">Félicitations pour votre engagement !</p>
          <p className="my-4">
            <strong className="font-bold">{(criteriasValidated / production.criterias.length) * 100} % des critères sont validés ! </strong>({criteriasValidated}/
            {production.criterias.length})
          </p>
          <p className="my-4">
            Vos engagements vous ont permis de mettre en place de réelles mesures RSE, Bravo ! (un petit encouragement complémentaire : en vrai faire le Secoset c’est déjà plus que
            95% des projets...)
          </p>
          <p>Cependant, les objectifs sur les critères suivants n'ont pas pu être validés : </p>
          {criteriasUnvalidated.length > 0 &&
            criteriasUnvalidated.map((criteria) => {
              return (
                <div className="my-3 flex">
                  <img src={`/images/icons/check.svg`} alt="document" />
                  <p>{criteria.actionTitle}</p>
                </div>
              );
            })}

          <p className="my-4"> C’est une démarche de transition qui se fait sur le long terme et nous sommes persuadés que votre prochain projet sera labellisé !</p>
          <p className="my-4">À bientôt !</p>
          <p className="my-4 text-xl font-bold">Bilan</p>
          {production.documents.length > 0 &&
            production.documents.map((doc) => {
              return (
                <div className="flex">
                  <img src={`/images/icons/document.svg`} alt="document" />
                  <a className="text-blue-dark underline underline-offset-1" href={`${doc}`} download>
                    {doc.match(/\/([^\/?#]+)[^\/]*$/)[1]}
                  </a>
                </div>
              );
            })}

          {production.documents.length === 0 && (
            <div className="flex">
              <img src={`/images/icons/document.svg`} alt="document" />
              <p>Aucun document n'a été transmis pour cette production</p>
            </div>
          )}

          <div className="my-4 flex justify-end">
            <button className="blue-button flex items-center" onClick={() => setOpen(false)}>
              <BiCheckCircle className="mr-2" />
              Ok
            </button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return <div></div>;
  }
};

const calculateCriteriasValidated = (production) => {
  let criteriasValidated = 0;
  Object.values(production.criterias).forEach((criteria) => {
    if (criteria.status === "ok") {
      criteriasValidated += 1;
    }
  });
  return criteriasValidated;
};

const reportCriteriasUnvalidated = (production, criterias) => {
  let criteriasUnvalidated = [];
  Object.values(production.criterias).forEach((criteria) => {
    if (criteria.status === "invalid " || criteria.status === "error") {
      let criteriaTemp = criterias.find((c) => c._id.toString() === criteria.criteria);
      criteriasUnvalidated.push(criteriaTemp);
    }
  });
  return criteriasUnvalidated;
};

export const ModalValidateProduction = (props) => {
  const { open, setOpen, production, setProduction } = props;
  const [errorInfos, setErrorInfos] = useState(null);
  const [isValidated, setIsValidated] = useState("false");
  const [criterias, setCriterias] = useState();
  const [thematics, setThematics] = useState();

  useEffect(() => {
    if (production.status === "validated") {
      setIsValidated("true");
    }
    setErrorInfos(null);

    const fetchData = async () => {
      await api.get(`/labels/all?productionId=${production._id}`).then((res) => {
        setThematics(res.data.thematics);
        setCriterias(res.data.criterias);
      });
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      await api.put(`/productions/validateProduction/${production._id}`, []);
      api.get(`/productions/${production._id}`).then((res) => {
        setProduction(res.data.production);
      });
      setIsValidated("true");
    } catch {
      setErrorInfos("Le projet n'a pas pu être validé");
    }
  };

  if (!criterias || !production || !thematics) {
    return <div> Loading... </div>;
  }

  const validatedCriterias = calculateValidatedCriteria(production.criterias);

  if (validatedCriterias !== production.criterias.length) {
    return (
      <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
        <div className="px-12 pt-6 pb-12">
          <p className="my-6 text-2xl font-bold"> Le projet "{production.title}" ne pas pas encore être validé</p>
          <p> Tous les critères ne sont pas validés, essayez de compléter tous les critères </p>
        </div>
      </Modal>
    );
  } else if (isValidated === "false") {
    return (
      <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
        <div className="px-12 pt-6 pb-12">
          <FeedBack message={errorInfos} setErrorInfos={setErrorInfos} />
          <p className="my-6 text-2xl font-bold"> Vous n'êtes qu'à un clic de valider votre projet "{production.title}" !</p>
          <p>Félicitations, 100% des critères sont validés pour ce projet!</p>
          <p>Vous pouvez désormais valider votre projet et acccéder au bilan contenant le résumé des critères et des documents transmis.</p>
          <div className="my-6 flex justify-end gap-4">
            <button className="empty-button" onClick={() => setOpen(false)}>
              Annuler
            </button>
            <button className="blue-button" onClick={handleSubmit}>
              Valider le projet
            </button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
        <div className="h-[40rem] overflow-y-auto bg-white px-12 pt-6 pb-12">
          <p className="my-8 text-2xl font-bold">Félicitations, votre projet "{production.title}" a été validé!</p>

          <div id="bilan">
            <div className="mb-4 flex justify-between">
              <p className="mb-4 text-2xl font-bold"> Bilan </p>
              <button className="blue-button" onClick={() => generatePDF(production, criterias, thematics)}>
                Télécharger le bilan
              </button>
            </div>
            {production.criterias.map((criteria, i) => {
              const criteriaTemp = criterias.find((c) => c._id === criteria.criteria);
              const average = getAverage(criteriaTemp.kpiRelation, criteria?.indicator1, criteria?.indicator2, criteria?.indicator3);
              return (
                <div id="bilan" key={i}>
                  {i % 2 === 0 && (
                    <p className="mb-2 font-bold underline">
                      {i / 2 + 1}. {thematics[i / 2].title}, {thematics[i / 2].subtitle}{" "}
                    </p>
                  )}
                  <div className="flex">
                    <img src={`/images/icons/check.svg`} alt="check" />
                    <p className="font-bold">{criteriaTemp.title}</p>
                  </div>
                  {criteriaTemp?.indicatorDescription1 && (
                    <div className="flex">
                      <p>{criteriaTemp.indicatorDescription1} : &nbsp;</p>
                      <div className="flex">
                        <p>{criteria?.indicator1}&nbsp;</p>
                        <p>{criteriaTemp.indicatorUnit1}</p>
                      </div>
                    </div>
                  )}
                  {!criteriaTemp?.indicatorDescription1 && criteriaTemp?.kpiDescription && (
                    <div className="flex">
                      <p>{criteriaTemp.kpiDescription} : &nbsp;</p>
                      <div className="flex">
                        <p>{criteria?.indicator1}&nbsp;</p>
                        <p>{criteriaTemp.kpiUnit}</p>
                      </div>
                    </div>
                  )}
                  {criteriaTemp?.indicatorDescription2 && (
                    <div className="flex">
                      <p>{criteriaTemp.indicatorDescription2} : &nbsp;</p>
                      <div className="flex">
                        <p>{criteria?.indicator2}&nbsp;</p>
                        <p>{criteriaTemp.indicatorUnit2}</p>
                      </div>
                    </div>
                  )}
                  {criteriaTemp?.indicatorDescription1 && criteriaTemp?.indicatorDescription2 && (
                    <div className="mb-4 flex">
                      <p>{criteriaTemp.kpiDescription} : &nbsp;</p>
                      <div className="flex">
                        <p>
                          {average.value && average.value.toFixed(2)}
                          &nbsp;
                        </p>
                        <p>{criteriaTemp.kpiUnit}</p>
                      </div>
                    </div>
                  )}

                  {criteria.documents.length > 0 &&
                    criteria.documents.map((doc, j) => {
                      return (
                        <div key={j}>
                          <p>Documents téléchargé(s): </p>
                          <div className="mb-4 flex">
                            <img src={`/images/icons/document.svg`} alt="document" />
                            <a className="text-blue-dark underline underline-offset-1" href={`${doc}`} download>
                              {doc.match(/\/([^\/?#]+)[^\/]*$/)[1]}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }
};

const generatePDF = (production, criterias, thematics) => {
  const doc = new jsPDF();
  let yPosition = 40; // initial vertical position
  const pageHeight = doc.internal.pageSize.height;

  doc.setTextColor(16, 60, 75);

  doc.setFontSize(14);
  doc.text(production.title, 10, 20);
  doc.text("BILAN DES CRITERES", 10, 30);
  const imgWidth = 35;
  const imgHeight = 15;
  const imgData = "/images/logo_secoset_png.png";
  const pageWidth = doc.internal.pageSize.width;
  doc.addImage(imgData, "PNG", pageWidth - imgWidth - 15, 15, imgWidth, imgHeight);

  production.criterias.map((criteria, i) => {
    const criteriaTemp = criterias.find((c) => c._id === criteria.criteria);

    const nextHeight = yPosition + 30;
    if (nextHeight > pageHeight) {
      doc.addPage();
      yPosition = 20;
    }
    if (i % 2 === 0) {
      doc.line(10, yPosition, 200, yPosition);
      yPosition += 10;
      doc.setFontSize(12);
      doc.text(thematics[i / 2].title, 10, yPosition);
      doc.setFontSize(10);
      doc.text(thematics[i / 2].subtitle, 10, yPosition + 5);
    }
    doc.setFontSize(10);
    const img = new Image();
    img.src = "/images/icons/check.png";

    doc.addImage(img, "PNG", 70, yPosition - 4, 5, 5);
    doc.text(criteriaTemp.title, 77, yPosition);
    yPosition += 3;

    if (criteriaTemp?.indicatorDescription1 && criteria.indicator1) {
      doc.setFontSize(9);
      const text = criteriaTemp.indicatorDescription1.concat(": ", criteria.indicator1, " ", criteriaTemp.indicatorUnit1);

      doc.text(text, 70, yPosition + 5);
      yPosition += 5;
    } else if (criteriaTemp?.kpiDescription && criteria.indicator1) {
      const text = criteriaTemp.kpiDescription.concat(": ", criteria.indicator1, " ", criteriaTemp.kpiUnit);
      doc.setFontSize(9);
      doc.text(text, 70, yPosition + 5);
      yPosition += 5;
    }

    if (criteriaTemp?.indicatorDescription2 && criteria.indicator2) {
      const text = criteriaTemp.indicatorDescription2.concat(": ", criteria.indicator2, " ", criteriaTemp.indicatorUnit2);
      doc.setFontSize(9);
      doc.text(text, 70, yPosition + 5);
      yPosition += 5;
    }

    if (criteriaTemp?.indicatorDescription1 && criteriaTemp?.indicatorDescription2) {
      if (criteriaTemp?.kpiDescription) {
        const average = getAverage(criteriaTemp.kpiRelation, criteria?.indicator1, criteria?.indicator2, criteria?.indicator3);
        const averageText = average.value ? average.value.toFixed(2) : "-";
        const text = criteriaTemp.kpiDescription.concat(": ", averageText, " ", criteriaTemp.kpiUnit);
        doc.setFontSize(9);
        doc.text(text, 70, yPosition + 5);
      }

      yPosition += 5;
    }

    yPosition += 10;
  });
  doc.save("myPDF.pdf");
};

const getAverage = (relation, a, b, c) => {
  if (!relation) return null;
  const matches = relation.match(/\((.*?)\)\.(.*)/);
  const formula = matches[1];
  const digits = parseInt(matches[2] || 0);
  if (!a && !b && !c) return { value: null, digits };

  const value = evaluate(formula.replace("a", a).replace("b", b).replace("c", c));
  return { value, digits };
};

const calculateValidatedCriteria = (criterias) => {
  let nbOkCriterias = 0;

  criterias.map((criteria) => {
    if (criteria.status === "ok") {
      nbOkCriterias += 1;
    }
  });

  return nbOkCriterias;
};
