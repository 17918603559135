import React from "react";
import { Modal } from "../../../components/Modal";
import ReactMarkdown from "react-markdown";

export const ModalEducationalSheet = (props) => {
  const { open, setOpen, thematic, criterias } = props;

  const criteriasByThematic = criterias.filter((c) => c.thematic === thematic._id.toString());

  return (
    <Modal className="w-full max-w-4xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="h-[44rem] overflow-y-auto px-12 pt-6 pb-12">
        <section className="my-4">
          <p className="">Fiche pédagogique de la thématique</p>
          <div className="m-4 flex justify-center">
            <div className="relative flex items-center">
              <img className="z-30 h-28 w-28" src={`/images/icons/thematics/${thematic.icon}.svg`} alt="Secoset" />
              <div className={`absolute h-28  w-28 rounded-full`} style={{ backgroundColor: thematic.color }} />
            </div>
          </div>
          <p className="flex justify-center text-3xl font-bold">{thematic.title}</p>
          <p className="flex justify-center">{thematic.subtitle}</p>
        </section>
        <section className="m-4 p-4">
          <p className="mb-4 text-xl font-bold"> De quoi parlons-nous ? </p>
          <p className="">{thematic.description}</p>
        </section>
        <section className="m-4 bg-blue-gray p-4">
          <p className="mb-4 text-xl font-bold"> Pourquoi est-ce important ? </p>
          <p className="">{thematic.why}</p>
        </section>
        <section className="m-4 p-4">
          <p className="mb-4 text-xl font-bold"> Lien vers ODD </p>
          <p className="mb-4">
            Cette section présente les liens vers les Objectifs de Développement Durable (ODD) de l'ONU ciblés par la Thématique. Ils constituent un plan d’action pour la paix,
            l’humanité, la planète et la prospérité pour l'horizon 2030 (cliquez sur les icônes pour en savoir plus).{" "}
          </p>
          <div className="flex gap-4">
            {thematic.odd.map((odd, i) => {
              return (
                <a href={`https://sdgs.un.org/fr/goals/goal${odd}`} rel="noopener noreferrer" key={i}>
                  <img className="h-28 w-28" src={`/images/icons/odd/F-WEB-Goal-${odd}.png`} alt={`ODD ${odd}`} />
                </a>
              );
            })}
          </div>
        </section>
        <section className="m-4 p-4">
          <p className="mb-4 text-xl font-bold"> Critères </p>
          <div className="grid grid-cols-[20%_40%_40%] gap-2">
            <p className="font-bold">Critères</p>
            {criteriasByThematic.map((crit, i) => {
              return (
                <div className="mb-6" key={i}>
                  <p className="font-bold">#{i + 1}</p>
                  <p className="font-bold">{crit.title}</p>
                  <p className="">{crit.description}</p>
                </div>
              );
            })}

            <p className="font-bold">Action(s) à réaliser</p>
            {criteriasByThematic.map((crit, i) => {
              return (
                <div className="mb-6" key={i}>
                  <p className="">{crit.actionTitle}</p>
                </div>
              );
            })}

            <p className="font-bold">Explication(s)</p>
            {criteriasByThematic.map((crit, i) => {
              return (
                <div className="mb-4" key={i}>
                  <p className="">{crit.why}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section className="m-4 bg-blue-gray p-4">
          <p className="mb-4 text-xl font-bold"> Pour aller plus loin! </p>
          {thematic.links.map((link, i) => {
            return (
              <div key={i} className="flex h-full w-full items-center gap-3">
                <div className="mb-4 flex items-start gap-4">
                  <img src={`/images/icons/check.svg`} alt="check" />
                  <ReactMarkdown>{link.replace("\\n", "\n")}</ReactMarkdown>
                </div>
              </div>
            );
          })}
        </section>
        <div className="flex justify-center">
          <button className="empty-button" onClick={() => setOpen(false)}>
            Quitter
          </button>
        </div>
      </div>
    </Modal>
  );
};
