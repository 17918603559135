import React, { useState, useEffect } from "react";
import { evaluate } from "mathjs";

import { Doughnut } from "./graph";
import { ErrorIcon, InvalidIcon, PendingIcon, TodoIcon, ValidIcon } from "../../components/Icons";
import { ProgressBar } from ".";

export const icons = {
  ok: <ValidIcon className="h-5 w-5 text-green-light" />,
  pending: <PendingIcon className="h-5 w-5 text-blue-dark" />,
  todo: <TodoIcon className="h-5 w-5 text-blue-mid" />,
  error: <ErrorIcon className="h-5 w-5 text-orange" />,
  invalid: <InvalidIcon className="h-5 w-5 text-red" />,
};

const statusLabels = {
  invalid: "Invalide",
  valid: "Validé",
  error: "Erreur",
  pending: "Soumis, en attente",
  todo: "A renseigner",
};

const statusColors = {
  invalid: "#FE5E33",
  valid: "#35ecb8",
  error: "#ff3333",
  pending: "#97bac7",
  todo: "#ffdd4a",
};

export const CriteriaSynthesis = ({ productions, criterias, thematics }) => {
  const [criteriaSynthesis, setCriteriaSynthesis] = useState();
  const [doughnutData, setDoughnutData] = useState();
  useEffect(() => {
    const criteriaSynthesis = buildSynthesis();
    setDoughnutData(buildDoughnutData(criteriaSynthesis));
    setCriteriaSynthesis(criteriaSynthesis);
  }, []);

  const buildSynthesis = () => {
    const criteriaSynthesis = { valid: 0, pending: 0, todo: 0, error: 0, invalid: 0, total: 0 };
    productions.forEach((p) => {
      p.criterias.forEach((c) => {
        if (c.status === "ok") criteriaSynthesis.valid++;
        else if (c.status === "pending") criteriaSynthesis.pending++;
        else if (c.status === "todo") criteriaSynthesis.todo++;
        else if (c.status === "error") criteriaSynthesis.error++;
        else if (c.status === "invalid") criteriaSynthesis.invalid++;
        criteriaSynthesis.total++;
        const criteria = criterias.find((criteria) => criteria._id.toString() === c.criteria.toString());
        if (!criteria) return console.error("criteria not found", c.criteria, criterias);
        const { value } = getAverage(criteria.kpiRelation, c.indicator1, c.indicator2, c.indicator3);
        if (!criteriaSynthesis[c.criteria.toString()]) criteriaSynthesis[c.criteria.toString()] = [value ? value : " -- "];
        else criteriaSynthesis[c.criteria.toString()].push(value ? value : " -- ");
      });
    });
    return criteriaSynthesis;
  };

  const buildDoughnutData = (criteriaSynthesis) => {
    const labels = [];
    const datasets = { data: [], backgroundColor: [], borderColor: [], borderWidth: 1 };
    Object.entries(criteriaSynthesis).forEach(([statusKey, statusSum]) => {
      if (!Object.keys(statusLabels).includes(statusKey)) return;
      labels.push(statusLabels[statusKey] + " : " + statusSum);
      datasets.data.push(statusSum);
      datasets.backgroundColor.push(statusColors[statusKey]);
      datasets.borderColor.push(statusColors[statusKey]);
    });
    return { labels, datasets: [datasets] };
  };

  if (!criteriaSynthesis) return <div>Loading...</div>;
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 items-stretch gap-5">
        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Statistiques générales</h1>
          <div className="mb-8 grid grid-cols-2">
            <div>
              <p>Critères</p>
              <div className="flex h-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-mid" />
                <p>{criteriaSynthesis.total}</p>
              </div>
            </div>
            <div>
              <p>Critères validés</p>
              <div className="flex h-16 items-center py-3 text-3xl font-bold">
                <div className="mr-2 h-4 w-4 rounded-full bg-blue-dark" />
                <p>{criteriaSynthesis.valid}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center">
              <div className="mr-5 w-[85%]">
                <ProgressBar progress={(criteriaSynthesis.valid * 100) / criteriaSynthesis.total} />
              </div>
              <p className="font-bold"> {((criteriaSynthesis.valid * 100) / criteriaSynthesis.total).toFixed(0)} % </p>
            </div>
          </div>
        </section>

        <section className="rounded-md bg-white p-8">
          <h1 className="mb-4 text-xl font-bold text-blue-dark">Statuts des critères</h1>
          <div className="m-4 ">
            <Doughnut data={doughnutData} />
          </div>
        </section>
      </div>

      <section className="rounded bg-white ">
        <div className="flex justify-between p-8">
          <h1 className="text-xl font-bold text-blue-dark">Tableau synthétique</h1>
          <button className="rounded-sm border bg-blue-dark px-3 py-2 text-center text-white " type="button" onClick={() => downloadCSV()}>
            Télécharger
          </button>
        </div>

        <div className="mb-8 items-center justify-center gap-2 overflow-x-auto">
          <table className="w-full table-auto rounded-sm">
            <thead>
              <tr className="border-b border-blue-gray bg-blue-dark text-white text-left">
                <th className="p-3">Thématique</th>
                <th className="p-3">Nom du critère</th>
                <th className="p-3">Moyenne</th>
                <th className="p-3">Unité</th>
                {productions.map((p, i) => {
                  return (
                    <th className="p-3" key={`prod-${i}`}>
                      {p.title}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody className="border-b border-blue-dark">
              {criterias
                .sort((a, b) => {
                  const thematicA = thematics.find((t) => t._id === a.thematic);
                  const thematicB = thematics.find((t) => t._id === b.thematic);
                  return thematicA.title.localeCompare(thematicB.title);
                })
                .map((criteria, i) => {
                  // if (criteria.title !== "Respect de la parité") return null;
                  const thematic = thematics.find((t) => t._id === criteria.thematic);
                  const synthesis = criteriaSynthesis[criteria._id.toString()];
                  // calculer la moyenne des projets
                  const { digits } = getAverage(criteria.kpiRelation, 0, 0, 0);
                  const validValues = synthesis.filter((value) => typeof value === "number");
                  const averageLabel = synthesis ? (validValues.reduce((a, b) => a + b, 0) / validValues.length).toFixed(digits) : " -- ";
                  return (
                    <tr className="border-b border-blue-gray hover:bg-gray-100" key={`${criteria}-${i}`}>
                      <td className="p-3">{thematic.title}</td>
                      <td className="p-3"> {criteria.title}</td>
                      <td className="whitespace-nowrap p-3">{!isNaN(averageLabel) ? averageLabel : <span className="text-gray-400">-</span>}</td>
                      <td className="whitespace-nowrap p-3">
                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                          {!criteria.indicatorUnit2 ? criteria.indicatorUnit1 : criteria.kpiUnit}
                        </span>
                      </td>
                      {productions.map((p, j) => {
                        const key = `${criteria}-${i}-${j}`;
                        const c = p.criterias.find((c) => c.criteria === criteria._id);
                        if (!c)
                          return (
                            <td key={key} className="p-3">
                              <span className="text-gray-400">-</span>
                            </td>
                          );
                        const average = getAverage(criteria.kpiRelation, c.indicator1, c.indicator2, c.indicator3);
                        if (isNaN(average.value))
                          return (
                            <td key={key} className="p-3">
                              <span className="text-gray-400">-</span>
                            </td>
                          );
                        return (
                          <td key={key} className="p-3">
                            {c.indicator1 && !c.indicator2 ? (
                              c.indicator1
                            ) : average.value !== null ? (
                              average.value.toFixed(average.digits)
                            ) : (
                              <span className="text-gray-400">-</span>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

const getAverage = (relation, a, b, c) => {
  try {
    if (a && !b && !c) return { value: parseFloat(a), digits: 2 };
    if (!relation) return { value: null, digits: 0 };
    // Extract values from the string using regular expressions
    const matches = relation.match(/\((.*?)\)\.(.*)/);
    const formula = matches[1];
    const digits = parseInt(matches[2] || 0);

    // Calculate the average
    if (!a && !b && !c) return { value: null, digits };

    if (formula.includes("a") && !a) return { value: null, digits };
    if (formula.includes("b") && !b) return { value: null, digits };
    if (formula.includes("c") && !c) return { value: null, digits };

    const value = evaluate(formula.replace("a", a).replace("b", b).replace("c", c));
    return { value, digits };
  } catch (error) {
    return { value: null, digits: 2 };
  }
};

const downloadCSV = () => {
  const table = document.querySelector("table");
  const rows = table.rows;
  const data = [];

  for (let i = 0; i < rows.length; i++) {
    const cells = rows[i].cells;
    const rowData = [];
    for (let j = 0; j < cells.length; j++) {
      rowData.push(cells[j].textContent);
    }
    data.push(rowData);
  }

  const csvData = data.map((row) => row.join(",")).join("\n");

  const link = document.createElement("a");
  link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvData));
  link.setAttribute("download", "criteres_synthese.csv");
  document.body.appendChild(link);
  link.click();
};
