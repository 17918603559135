import React, { useState, useEffect } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import Loader from "../../../components/Loader";
import api from "../../../api/apiHandler";
import { Modal } from "../../../components/Modal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Notification = ({ production }) => {
  const [notifications, setNotifications] = useState();
  const [open, setOpen] = useState(false);

  const getUnSeenNotifications = async () => {
    try {
      const res = await api.get(`/productions/${production._id}/discussions?seen=false`);
      setNotifications(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnSeenNotifications();
  }, []);

  if (!notifications)
    return (
      <div className="bg-white p-5">
        <Loader />
      </div>
    );
  return (
    <div className="bg-white p-5">
      <div className="mb-8 flex items-center justify-between">
        <div>
          <h2>Actualité {notifications.length > 0 && "(" + notifications.length + ")"}</h2>
        </div>
        <div className="empty-button flex cursor-pointer items-center" onClick={() => setOpen(true)}>
          Voir historique
          <HiOutlineChevronRight className="ml-2" />
        </div>
      </div>
      {notifications.length === 0 ? <div>Aucune notification reçue</div> : <DisplayActualite notifications={notifications} />}
      <ModalNotification open={open} production={production} setOpen={setOpen} />
    </div>
  );
};

const ModalNotification = ({ production, open, setOpen }) => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState();

  const getNotifications = async () => {
    try {
      const res = await api.get(`/productions/${production._id}/discussions`);

      setNotifications(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const getAction = (status) => {
    switch (status) {
      case "Soumission":
        return "check";
      case "Validation":
        return "ok";
      case "error":
        return "error";
      case "ValidationProjet":
        return "ok";
      case "RefusProjet":
        return "error";
      case "Attente":
        return "pending";
      case "Invalidation":
        return "invalid";
    }
  };

  const handleClick = async (notification) => {
    // check if notification was seen
    if (!notification.seen) api.patch("/discussions/" + notification._id.toString(), [], { seen: true });
    // redirect to
    navigate("/saas/productions/" + production._id.toString() + "/criteria/" + notification.criteriaId._id);
  };
  return (
    <Modal isOpen={open} onClose={() => setOpen(false)}>
      <div className="flex w-[760px] flex-col justify-center  bg-white px-8 pb-8">
        <div className="text-left text-2xl font-bold">Notifications</div>
        <div className="my-4 text-sm">
          Liste des notifications du projet <span className="font-bold uppercase">{production.title}</span>
        </div>
        <div className="mt-4 flex flex-row items-center bg-secoset-green-5 py-2">
          <div className="w-[10%] px-2 text-xs font-bold">Réponse</div>
          <div className="w-[35%] px-2 text-xs font-bold">Thématique / Critères</div>
          <div className="w-[20%] px-2 text-xs font-bold">Document</div>
          <div className="w-[20%] px-2 text-xs font-bold">Message Secoya</div>
          <div className="w-[15%] px-2 text-xs font-bold">Date</div>
        </div>
        {notifications ? (
          <div className="flex max-h-[500px] flex-col overflow-y-auto">
            {notifications.length > 0 ? (
              <>
                {notifications.map((notification) => (
                  <div
                    onClick={() => handleClick(notification)}
                    key={notification._id}
                    className="flex cursor-pointer flex-row items-center border-b-[1px] py-2 hover:bg-[#97BAC7]">
                    <div className="flex w-[10%] items-center justify-center px-2">
                      <img className="h-[24px] w-[24px]" src={`/images/icons/indicators/${getAction(notification?.action)}.svg`} alt={"pending"} />
                    </div>
                    <div className="w-[35%] px-2 text-xs">
                      <div className="font-bold uppercase">{production.title}</div>
                      <div className="flex flex-row items-center gap-2">
                        <div className="w-fit rounded-full" style={{ backgroundColor: notification?.criteriaId?.thematic?.color }}>
                          <img className="h-[24px] w-[24px]" src={`/images/icons/thematics/${notification?.criteriaId?.thematic?.icon}.svg`} alt={"pending"} />
                        </div>
                        <div>{notification?.criteriaId?.title}</div>
                      </div>
                    </div>
                    <div className="w-[20%] px-2 text-xs font-bold">{notification?.criteriaId?.actionTitle}</div>
                    <div className="w-[20%] px-2 text-xs">{notification?.message} </div>
                    <div className="w-[15%] px-2 text-xs">{dayjs(notification?.createdAt).format("DD-MM-YYYY")}</div>
                  </div>
                ))}
              </>
            ) : (
              <div className="my-4 text-center">Aucune notification reçue</div>
            )}
          </div>
        ) : (
          <div className="my-8">
            <Loader />
          </div>
        )}
      </div>
    </Modal>
  );
};

const DisplayActualite = ({ notifications }) => {
  const { pending, validated, refused, unvalidated } = getDiscussionsStats(notifications);
  return (
    <div>
      {pending.docs > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/pending.svg`} alt={"pending"} />
          <div>
            {pending.docs} {pending.docText}
          </div>
        </div>
      )}
      {validated.docs > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/ok.svg`} alt={"validated"} />
          <div>
            {validated.docs} {validated.docText}
          </div>
        </div>
      )}
      {refused.docs > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/error.svg`} alt={"error"} />
          <div>
            {refused.docs} {refused.docText}
          </div>
        </div>
      )}
      {unvalidated.docs > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/invalid.svg`} alt={"invalid"} />
          <div>
            {unvalidated.docs} {unvalidated.docText}
          </div>
        </div>
      )}
      {/* projects */}
      {validated.projects > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/ok.svg`} alt={"validated"} />
          <div>
            {validated.projects} {validated.projectsText}
          </div>
        </div>
      )}
      {refused.projects > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/error.svg`} alt={"error"} />
          <div>
            {refused.projects} {refused.projectsText}
          </div>
        </div>
      )}
      {unvalidated.projects > 0 && (
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/indicators/invalid.svg`} alt={"invalid"} />
          <div>
            {unvalidated.projects} {unvalidated.projectsText}
          </div>
        </div>
      )}
    </div>
  );
};

const getDiscussionsStats = (discussions) => {
  if (!discussions) return { nbDiscussions: 0, validated: 0, refused: 0, unvalidated: 0 };
  const nbValidatedDocs = discussions.filter((discussion) => discussion.action === "Validation").length;
  const nbValidatedProjects = discussions.filter((discussion) => discussion.type === "project" && discussion.action === "ValidationProjet").length;
  const nbRefusedDocs = discussions.filter((discussion) => discussion.action === "Refus").length;
  const nbRefusedProjects = discussions.filter((discussion) => discussion.type === "project" && discussion.action === "RefusProjet").length;

  const nbUnvalidatedDocs = discussions.filter((discussion) => discussion.action === "Invalidation").length;

  const nbPendingDocs = discussions.filter((discussion) => discussion.action === "Attente").length;

  const nbValidatedDiscussions = nbValidatedDocs + nbValidatedProjects;
  const nbRefusedDiscussions = nbRefusedDocs + nbRefusedProjects + nbUnvalidatedDocs;
  const nbDiscussions = discussions.length;
  return {
    nbDiscussions,
    pending: {
      docs: nbPendingDocs,
      docText: nbPendingDocs > 1 ? "documents laissés en attente" : "document laissé en attente",
    },
    validated: {
      total: nbValidatedDiscussions,
      docs: nbValidatedDocs,
      docText: nbValidatedDocs > 1 ? "documents validés" : "document validé",
      projects: nbValidatedProjects,
      projectsText: nbValidatedProjects > 1 ? "projets validés" : "projet validé",
    },
    refused: {
      total: nbRefusedDiscussions,
      docs: nbRefusedDocs,
      docText: nbRefusedDocs > 1 ? "documents refusés" : "document refusé",
      projects: nbRefusedProjects,
      projectsText: nbRefusedProjects > 1 ? "projets refusés" : "projet refusé",
    },
    unvalidated: {
      total: nbUnvalidatedDocs,
      docs: nbUnvalidatedDocs,
      docText: nbUnvalidatedDocs > 1 ? "documents invalidés" : "document invalidé",
    },
  };
};

export default Notification;
