import React, { useEffect, useState } from "react";
import { BiLastPage, BiFirstPage, BiRightArrow } from "react-icons/bi";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

export const Paginator = ({ page, setPage, end }) => {
  const [pages, setPages] = useState([...Array(end).keys()].map((i) => i + 1));

  useEffect(() => {
    if (page > end) setPage(end);
    setPages([...Array(end).keys()].map((i) => i + 1));
  }, [end]);

  return (
    <div className="w-full flex flex-row items-center justify-between gap-1">
      <div className="flex flex-row items-center gap-1">
        {end > 5 ? (
          page < 4 ? (
            <>
              {pages.slice(0, 4).map((p) => (
                <button
                  key={p}
                  className={`flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ${p === page ? "bg-gray-50 text-gray-800 rounded-lg" : ""}`}
                  onClick={() => setPage(p)}
                >
                  {p}
                </button>
              ))}
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ">...</button>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium " onClick={() => setPage(end)}>
                {end}
              </button>
            </>
          ) : page > end - 3 ? (
            <>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium " onClick={() => setPage(1)}>
                1
              </button>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ">...</button>
              {pages.slice(end - 4, end).map((p) => (
                <button
                  key={p}
                  className={`flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ${p === page ? "bg-gray-50 text-gray-800 rounded-lg" : ""}`}
                  onClick={() => setPage(p)}
                >
                  {p}
                </button>
              ))}
            </>
          ) : (
            <>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium " onClick={() => setPage(1)}>
                1
              </button>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ">...</button>
              {pages.slice(page - 2, page + 1).map((p) => (
                <button
                  key={p}
                  className={`flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ${p === page ? "bg-gray-50 text-gray-800 rounded-lg" : ""}`}
                  onClick={() => setPage(p)}
                >
                  {p}
                </button>
              ))}
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium " disabled={true}>
                ...
              </button>
              <button className="flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium " onClick={() => setPage(end)}>
                {end}
              </button>
            </>
          )
        ) : (
          pages.map((p) => (
            <button
              key={p}
              className={`flex h-8 w-8 items-center justify-center hover:bg-gray-hover text-gray-600 font-medium ${p === page ? "bg-gray-50 text-gray-800 rounded-lg" : ""}`}
              onClick={() => setPage(p)}
            >
              {p}
            </button>
          ))
        )}
      </div>

      <div className="flex flex-row justify-center gap-2 items-center">
        {page !== 1 && (
          <div className="flex flex-row gap-1 cursor-pointer items-center justify-center p-2 border-[1px] rounded-lg text-gray-600 font-[600]" onClick={() => setPage(page - 1)}>
            <FiArrowLeft className="text-gray-600" />
            <div>Retour</div>
          </div>
        )}

        {page !== end && (
          <div className="flex flex-row gap-1 cursor-pointer items-center justify-center p-2 border-[1px] rounded-lg text-gray-600 font-[600]" onClick={() => setPage(page + 1)}>
            <div>Suivant</div>
            <FiArrowRight className="text-gray-600" />
          </div>
        )}
      </div>
    </div>
  );
};
