import React, { useState } from "react";

import { Modal } from "../../components/Modal";

import api from "../../api/apiHandler";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";
import { Formik, Form } from "formik";
import validator from "validator";

import { useDispatch } from "react-redux";
import { setUser } from "../../redux/authSlice";
import CircularProgress from "@mui/material/CircularProgress";

const ModalInformations = ({ user, open, close }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.lastName)) errors.lastName = "Ce champ est requis";
    if (validator.isEmpty(values.firstName)) errors.firstName = "Ce champ est requis";
    if (validator.isEmpty(values.organizationJob)) errors.organizationJob = "Ce champ est requis";
    if (validator.isEmpty(values.phoneNumber)) errors.phoneNumber = "Ce champ est requis";
    else if (!validator.isMobilePhone(values.phoneNumber)) errors.phophoneNumberne = "Le format du numéro est incorrect";
    setErrors(errors);
    return !errors.firstName && !errors.lastName && !errors.organizationJob && !errors.phone;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;
    if (loading) return;
    setLoading(true);
    api
      .put("/saas-auth/onboarding", values)
      .then((res) => {
        setLoading(false);
        if (res.data.ok) {
          dispatch(setUser(res.data.data));
          close(false);
        }
      })
      .catch(() => {
        setErrors({ error: "Une erreur est survenue" });
        setLoading(false);
      });
  };
  console.log("user", open);

  return (
    <Modal onClose={() => null} isOpen={open} showCross={false}>
      <div className="p-10">
        <div className="flex w-[550px] flex-col items-center justify-center">
          <img className="w-44" src={LogoSecoset} />
          <div className=" my-10 flex w-full items-center justify-center gap-8">
            <div className="w-[260px]">
              <div className="flex items-center justify-center gap-4">
                <div className="text-xl font-medium text-secoset-green-700">À propos de vous</div>
              </div>
              <div className={"mt-3 h-1 w-full rounded-lg bg-secoset-green-700"} />
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: user.firstName || "",
              lastName: user.lastName || "",
              email: user.email,
              phoneNumber: user.phoneNumber || "",
              organizationJob: user.organizationJob || "",
            }}
            onSubmit={handleSubmit}>
            {({ values, errors, handleChange }) => (
              <Form className="flex flex-col gap-6">
                <>
                  <div className="flex items-center justify-around gap-8">
                    <div>
                      <div className="mb-1 font-bold text-gray-500">Prénom</div>
                      <input className="saas-input w-[260px]" placeholder="John" name="firstName" type="text" value={values.firstName} onChange={handleChange} />
                      {errors.firstName && <div className="text-sm text-red">{errors.firstName}</div>}
                    </div>
                    <div>
                      <div className="mb-1 font-bold text-gray-500">Nom</div>
                      <input className="saas-input w-[260px]" placeholder="Doe" name="lastName" type="text" value={values.lastName} onChange={handleChange} />
                      {errors.lastName && <div className="text-sm text-red">{errors.lastName}</div>}
                    </div>
                  </div>
                  <div className="flex items-center justify-around gap-8">
                    <div>
                      <div className="mb-1 font-bold text-gray-500">Email</div>
                      <input disabled className="saas-input w-[260px]" placeholder="john@ugc.fr" name="email" type="text" value={values.email} onChange={handleChange} />
                      {errors.email && <div className="text-sm text-red">{errors.email}</div>}
                    </div>
                    <div>
                      <div className="mb-1 font-bold text-gray-500">Téléphone</div>
                      <input className="saas-input w-[260px]" placeholder="0612345678" name="phoneNumber" type="text" value={values.phoneNumber} onChange={handleChange} />
                      {errors.phoneNumber && <div className="text-sm text-red">{errors.phoneNumber}</div>}
                    </div>
                  </div>
                  <div>
                    <div className="mb-1 font-bold text-gray-500">Poste</div>
                    <input className="saas-input w-[260px]" placeholder="Votre poste" name="organizationJob" type="text" value={values.organizationJob} onChange={handleChange} />
                    {errors.organizationJob && <div className="text-sm text-red">{errors.organizationJob}</div>}
                  </div>
                  {errors.error && <div className="text-sm text-red">Une erreur est survenue, veuillez réessayer</div>}
                  {loading ? (
                    <button className="blue-button mt-4 mb-8 flex w-[180px] items-center justify-center self-center text-lg" type="submit">
                      <CircularProgress style={{ color: "white" }} size={27} />
                    </button>
                  ) : (
                    <button className="blue-button mt-4 mb-8 w-[180px] self-center text-lg" type="submit">
                      Continuer
                    </button>
                  )}
                </>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInformations;
