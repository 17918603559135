import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Loader from "../../components/Loader";
import api from "../../api/apiHandler";
import Select from "../../components/Select-bis";

const filtersCorrespondance = {
  carbon_impact: "Empreinte Carbone",
  social: "Démarche Sociétale",
  social_real: "Droits Sociaux",
  food: "Alimentation",
  transportation: "Mobilité",
  communication: "Communication",
  purchasing: "Consommation Responsable",
  biodiversity: "Biodiversité",
  waste: "Déchets",
  energy: "Energie",
};
// TODO : refacto
const Directory = () => {
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(null);
  const [providers, setProviders] = useState(null);
  const [filteredProviders, setFilteredProviders] = useState(null);
  const [searchParams] = useSearchParams();
  const defaultFilters = {
    thematic: searchParams.get("thematic") ? filtersCorrespondance[searchParams.get("thematic")] : null,
    department: searchParams.get("department") ? searchParams.get("department") : null,
    service: searchParams.get("service") ? searchParams.get("service") : null,
  };
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
  const { user } = useSelector((state) => state.Auth);
  const [synchronising, setSynchronising] = useState(false);

  useEffect(() => {
    api
      .get(`/directory`)
      .then((res) => {
        setProviders(res.data.data);
        setFilteredProviders(res.data.data);
      })
      .catch((err) => console.log(err));
    api
      .get(`/directory/filters`)
      .then((res) => {
        const newFilters = res.data.data;

        setFilters(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!providers) return;
    if ((!selectedFilters || !Object.values(selectedFilters).filter((f) => f).length) && !search) {
      setFilteredProviders(providers);
    } else {
      let filtered = providers.filter((p) => {
        const thematicMatch = !selectedFilters?.thematic || p.thematics.includes(selectedFilters.thematic);
        const departmentMatch = !selectedFilters?.department || p.departments.includes(selectedFilters.department);
        const serviceMatch = !selectedFilters?.service || p.services.includes(selectedFilters.service);
        const regionMatch = !selectedFilters?.region || p.regions.includes(selectedFilters.region);
        const searchMatch = !search || p.name?.toLowerCase().includes(search.toLowerCase()) || p.details?.toLowerCase().includes(search.toLowerCase());

        return thematicMatch && departmentMatch && serviceMatch && regionMatch && searchMatch;
      });
      setFilteredProviders(filtered);
    }
  }, [providers, selectedFilters, search]);

  const handleSync = async () => {
    try {
      setSynchronising(true);
      const res = await api.post("/directory/update");
      setSynchronising(false);
      toast.success("Annuaire mis à jour");
    } catch (err) {
      setSynchronising(false);
      console.log(err);
      toast.error("Erreur lors de la mise à jour de l'annuaire");
    }
  };

  if (!providers || !filteredProviders || !filters) return <Loader />;

  return (
    <div className="h-full w-full px-16 pb-8 pt-4">
      {["admin", "eco-manager"].includes(user.role) ? (
        <div className="my-4">
          <button onClick={handleSync} disabled={synchronising} className="blue-button">
            Synchroniser avec le airtable
          </button>
        </div>
      ) : null}
      <section className="mb-4 flex w-full items-center justify-center gap-4">
        <div className="flex flex-1 flex-col">
          <h1 className="text-lg font-bold text-blue-dark">Thématique</h1>

          <Select
            options={filters.thematics.map((f) => ({ label: f._id, value: f._id }))}
            value={selectedFilters?.thematic}
            placeholder={"Sélectionnez une thématique"}
            onChange={(e) =>
              selectedFilters?.thematic === e ? setSelectedFilters({ ...selectedFilters, thematic: null }) : setSelectedFilters({ ...selectedFilters, thematic: e })
            }
          />
        </div>
        <div className="flex flex-1 flex-col">
          <h1 className="text-lg font-bold text-blue-dark">Département</h1>

          <Select
            options={filters.departments.map((f) => ({ label: f._id, value: f._id }))}
            value={selectedFilters?.department}
            placeholder={"Sélectionnez une thématique"}
            onChange={(e) =>
              selectedFilters?.department === e ? setSelectedFilters({ ...selectedFilters, department: null }) : setSelectedFilters({ ...selectedFilters, department: e })
            }
          />
        </div>
        <div className="flex flex-1 flex-col">
          <h1 className="text-lg font-bold text-blue-dark">Service</h1>

          <Select
            options={filters.services.map((f) => ({ label: f._id, value: f._id }))}
            value={selectedFilters?.service}
            placeholder={"Sélectionnez une thématique"}
            onChange={(e) => (selectedFilters?.service === e ? setSelectedFilters({ ...selectedFilters, service: null }) : setSelectedFilters({ ...selectedFilters, service: e }))}
          />
        </div>
        <div className="flex flex-1 flex-col">
          <h1 className="text-lg font-bold text-blue-dark">Région</h1>

          <Select
            options={filters.regions.map((f) => ({ label: f._id, value: f._id }))}
            value={selectedFilters?.region}
            placeholder={"Sélectionnez une thématique"}
            onChange={(e) =>
              selectedFilters?.region === e ? setSelectedFilters({ ...selectedFilters, region: undefined }) : setSelectedFilters({ ...selectedFilters, region: e })
            }
          />
        </div>
        <div className="flex flex-1 flex-col">
          <h1 className="text-lg font-bold text-blue-dark">Rechercher</h1>
          <input className="rounded border border-blue-dark p-2" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Rechercher un prestataire" />
        </div>
      </section>

      <div className="grid w-full grid-cols-4 gap-4 overflow-auto">
        {filteredProviders.length > 0 ? (
          filteredProviders.map((p, i) => (
            <a
              key={i}
              href={p.website}
              className="flex min-h-[300px] cursor-pointer flex-col justify-between rounded border-2 border-white bg-white p-8 hover:border-secoset-green-900"
              target="_blank">
              <div>
                <p className="text-xl font-bold">{p.name}</p>
                <p className="my-3 text-base font-normal">{p.details}</p>
              </div>
              <p target="_blank" className="truncate underline">
                {p.website}
              </p>
            </a>
          ))
        ) : (
          <p className="hxr">Aucun résultat pour les catégories sélectionnées.</p>
        )}
      </div>
    </div>
  );
};

export default Directory;
