import React, { Fragment } from "react";
import { HiOutlineHome, HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

export const BreadCrumps = ({ crumbs, setCrumbs }) => {
  const handleClick = (i) => setCrumbs(crumbs.slice(0, i + 1));

  return (
    <nav className=" ml-4 flex items-center gap-6 p-2">
      <Link className="text-lg" to="/saas/">
        <HiOutlineHome />
      </Link>
      {crumbs.map((link, i) => (
        <Fragment key={i}>
          <HiChevronRight />
          <Link className="text-sm font-semibold" to={link.link} onClick={() => handleClick(i)}>
            {link.label}
          </Link>
        </Fragment>
      ))}
    </nav>
  );
};
