import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { Disclosure } from "@headlessui/react";
import { evaluate } from "mathjs";

import { ErrorIcon, InvalidIcon, PendingIcon, TodoIcon, ValidIcon } from "../../components/Icons";
import { LineChart } from "./graph";

const moyennes = require("./moyennes_nationales.json");
const icons = {
  ok: <ValidIcon className="h-5 w-5 text-green-light" />,
  pending: <PendingIcon className="h-5 w-5 text-blue-dark" />,
  todo: <TodoIcon className="h-5 w-5 text-blue-mid" />,
  error: <ErrorIcon className="h-5 w-5 text-orange" />,
  invalid: <InvalidIcon className="h-5 w-5 text-red" />,
};

const statusLabels = {
  invalid: "Invalide",
  ok: "Validé",
  error: "Ereur",
  pending: "Soumis, en attente",
  todo: "A renseigner",
};

export const CriteriaList = ({ productions, criterias, thematics }) => {
  const [selectedCriteria, setSelectedCriteria] = useState(criterias[0]);

  return (
    <div className="flex flex-col gap-4">
      <section className="gap-4 rounded-md bg-white p-4">
        <div className="m-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="h-16 w-16 py-3 text-center text-4xl font-bold">{criterias.length}</div>
            <p className="text-xl">Critères</p>
          </div>
        </div>
      </section>
      <div className="flex gap-4">
        <section className="flex w-[30%] flex-col rounded-md bg-white p-6">
          {thematics.map((thematic, i) => (
            <Thematic
              key={i}
              thematic={thematic}
              criterias={criterias.filter((c) => c.thematic === thematic._id)}
              setSelectedCriteria={setSelectedCriteria}
              selectedCriteria={selectedCriteria}
            />
          ))}
        </section>
        <section className="flex flex-1 flex-col rounded-md bg-white p-6">
          <Criteria criteria={selectedCriteria} productions={productions} />
        </section>
      </div>
    </div>
  );
};

const Thematic = ({ thematic, criterias, setSelectedCriteria, selectedCriteria }) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full items-center justify-between px-4 py-2 text-blue-dark hover:bg-blue-light">
            <span className="font-bold">{thematic.title}</span>
            <HiChevronDown className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-blue-dark transition-all duration-75`} />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
            {criterias.map((criteria, i) => (
              <button key={i} className="cursor-pointor w-full py-2 text-left text-blue-dark" onClick={() => setSelectedCriteria(criteria)}>
                <span className={`${selectedCriteria._id === criteria._id ? "underline" : ""}`}>{criteria.title}</span>
              </button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const Criteria = ({ criteria, productions }) => {
  const [lineChartData, setLineChartData] = useState();
  const [criteriaAverages, setCriteriaAverages] = useState();

  useEffect(() => {
    const averages = [];
    const labels = [];

    const datasets = { label: "Moyennes des projets", data: [], backgroundColor: "#97bac7", borderColor: "#97bac7", borderWidth: 2, pointRadius: 5 };
    productions.forEach((production) => {
      const c = production.criterias.find((c) => c.criteria === criteria._id);
      const average = getAverage(criteria.kpiRelation, c?.indicator1, c?.indicator2, c?.indicator3);
      if (average.value !== null) {
        labels.push(new Date(production.preparationStart).toLocaleDateString("fr"));
        datasets.data.push(average.value);
        averages.push(average.value);
      }
    });

    // Temporary average system
    const averageData = averages.reduce((a, b) => a + b, 0) / averages.length;
    const data = [];
    for (let i = 0; i < productions.length; i++) {
      data.push(averageData);
    }
    const averageDataset = {
      label: "Moyenne du critère",
      data: data,
      backgroundColor: "#2BA1C9",
      borderColor: "#2BA1C9",
      borderWidth: 2,
      pointRadius: 3,
    };
    const nationalData = [];

    for (let i = 0; i < productions.length; i++) {
      nationalData.push(moyennes[criteria.title]);
    }
    const nationalDataset = {
      label: "Moyenne nationale",
      data: nationalData,
      backgroundColor: "#E5CC5E",
      borderColor: "#E5CC5E",
      borderWidth: 2,
      pointRadius: 3,
    };
    setLineChartData({ labels, datasets: [datasets, averageDataset, nationalDataset] });
    setCriteriaAverages(averages);
  }, [criteria, productions]);

  if (!lineChartData) return <div>Chargement</div>;
  if (!criteria) return <div>Aucun critères</div>;

  return (
    <div>
      <section className="flex flex-col gap-4 bg-white p-2">
        <div className="flex items-center justify-between ">
          <div>
            <h2 className="mr-5 text-xl font-bold">{criteria.title}</h2>
            <p className="mb-3">{criteria.kpiDescription}</p>
            {criteria.indicatorUnit1 && criteria.indicatorDescription1 ? <p>Indicateur 1 : {criteria.indicatorDescription1}</p> : null}
            {criteria.indicatorUnit2 && <p>Indicateur 2 : {criteria.indicatorDescription2}</p>}
            {criteria.indicatorUnit3 && <p className="mb-6">Indicateur 3 : {criteria.indicatorDescription3}</p>}
          </div>
          <div className="flex items-center gap-2">
            {criteriaAverages.length > 0 ? (
              <>
                <div className="ml-2 rounded-sm p-3 text-center text-2xl font-bold ring-2 ring-blue-dark ">
                  {(criteriaAverages.reduce((a, b) => a + b, 0) / criteriaAverages.length).toFixed(2)}
                </div>
                <p className="p-3 text-center font-bold">{criteria.kpiUnit ? criteria.kpiUnit : criteria.indicatorUnit1}</p>
              </>
            ) : (
              <div className="rounded-sm p-3 text-center text-xl font-bold ring-2 ring-blue-dark ">Aucune Moyenne disponible</div>
            )}
          </div>
        </div>

        <div className="gap-2 overflow-auto">
          <table className="w-full table-auto rounded-sm text-center">
            <thead>
              <tr className="border-b border-blue-gray">
                <th className="p-3">Projet</th>
                <th>Statut</th>
                {criteria.indicatorUnit1 ? <th className="w-[15%] px-2">{`Indicateur 1 (${criteria.indicatorUnit1})`}</th> : null}
                {criteria.indicatorUnit2 ? <th className="w-[15%] px-2">{`Indicateur 2 (${criteria.indicatorUnit2})`}</th> : null}
                {criteria.indicatorUnit3 ? <th className="w-[15%] px-2">{`Indicateur 3 (${criteria.indicatorUnit3})`}</th> : null}
                <th>Bilan</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {productions.map((p, i) => {
                const c = p.criterias.find((c) => c.criteria === criteria._id);
                const average = getAverage(criteria.kpiRelation, c?.indicator1, c?.indicator2, c?.indicator3);

                return (
                  <tr key={i} className="border-b border-blue-gray">
                    <td className="p-3">{p.title}</td>
                    <td className="flex items-center justify-center gap-2 p-3">
                      {c ? (
                        <>
                          {statusLabels[c.status]}
                          {icons[c.status]}
                        </>
                      ) : (
                        "--"
                      )}
                    </td>
                    {criteria.indicatorUnit1 ? <td className="w-[15%] px-2">{c?.indicator1 ? c?.indicator1 : "--"}</td> : null}
                    {criteria.indicatorUnit2 ? <td className="w-[15%] px-2">{c?.indicator2 ? c?.indicator2 : "--"}</td> : null}
                    {criteria.indicatorUnit3 ? <td className="w-[15%] px-2">{c?.indicator3 ? c?.indicator3 : "--"}</td> : null}
                    <td className="p-3">
                      {average.value !== null
                        ? criteria.indicatorUnit1
                          ? `${average.value.toFixed(2)} ${criteria.indicatorUnit1}`
                          : `${average.value.toFixed(2)} ${criteria.kpiUnit}`
                        : "--"}
                    </td>
                    <td className="p-3">{new Date(p.preparationStart).toLocaleDateString("fr")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>

      <section className="p-5">
        <LineChart data={lineChartData} />
      </section>
    </div>
  );
};

const getAverage = (relation, a, b, c) => {
  try {
    if (a && !b && !c) return { value: parseFloat(a), digits: 2 };
    if (!relation) return { value: null, digits: 0 };
    // Extract values from the string using regular expressions
    const matches = relation.match(/\((.*?)\)\.(.*)/);
    const formula = matches[1];
    const digits = parseInt(matches[2] || 0);

    // Calculate the average
    if (!a && !b && !c) return { value: null, digits };

    if (formula.includes("a") && !a) return { value: null, digits };
    if (formula.includes("b") && !b) return { value: null, digits };
    if (formula.includes("c") && !c) return { value: null, digits };

    const value = evaluate(formula.replace("a", a).replace("b", b).replace("c", c));
    return { value, digits };
  } catch (error) {
    return { value: null, digits: 0 };
  }
};
