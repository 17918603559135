import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useField, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

// import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { DayPicker } from "react-day-picker";
import { RiCloseLine } from "react-icons/ri";

import api from "../../api/apiHandler";
import { Modal } from "../../components/Modal";

const PROD_TYPES = ["Long-Métrage", "Série", "Court-métrage", "Documentaire", "Vidéo-clip", "Pub", "Émission de Flux"];
const PROD_ERAS = ["Film d'époque", "Contemporain", "Futuriste"];

const ModalNewProduction = ({ open, setOpen, setProduction, production, edit = false }) => {
  const [selectedImage, setSelectedImage] = useState(production?.image || null);

  const navigate = useNavigate();

  const validate = (values, setErrors) => {
    const errors = {};
    if (!values.title) errors.title = "Champ obligatoire";
    if (!values.type) errors.type = "Champ obligatoire";
    if (!values.era) errors.era = "Champ obligatoire";
    if (!values.director) errors.director = "Champ obligatoire";
    if (!values.productor) errors.productor = "Champ obligatoire";
    if (!values.productionDirector) errors.productionDirector = "Champ obligatoire";
    if (!values.preparationStart) errors.preparationStart = "Champ obligatoire";
    if (!values.preparationEnd) errors.preparationEnd = "Champ obligatoire";
    if (!values.shootingStart) errors.shootingStart = "Champ obligatoire";
    if (!values.shootingEnd) errors.shootingEnd = "Champ obligatoire";

    setErrors(errors);
    const isErrors = Object.keys(errors).length > 0;
    if (isErrors) errors.global = "Veuillez vérifier les champs du formulaire";
    return !isErrors;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    if (edit) {
      api
        .put(`/productions/${production._id.toString()}`, values)
        .then((res) => {
          if (selectedImage && selectedImage !== values.image) {
            const formData = new FormData();
            formData.append(`file`, selectedImage);
            api.post(`/productions/image/${production._id.toString()}`, formData).then((res) => setProduction(res.data));
          } else if (!selectedImage && values.image) {
            api.delete(`/productions/image/${production._id.toString()}`);
            res.data.image = "";
            setProduction(res.data);
          }
          setOpen(false);
        })
        .catch((err) => {
          console.error(err);
          setErrors({ global: "Erreur lors de la modification" });
        });
    } else {
      api
        .post(`/productions/`, values)
        .then((res) => {
          const production = res.data.data;
          if (selectedImage) {
            const formData = new FormData();
            formData.append(`file`, selectedImage);
            api.post(`/productions/image/${production._id.toString()}`, formData);
          }
          navigate(`/saas/productions/${production._id.toString()}?new=true`);
        })
        .catch(() => setErrors({ global: "Erreur lors de la création" }));
    }
  };

  return (
    <Modal className="w-full max-w-[70%]" isOpen={open} onClose={() => setOpen(false)}>
      <div className="h-[40rem] overflow-y-auto p-16">
        <Formik initialValues={production ? production : {}} onSubmit={handleSubmit}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form className="flex flex-col gap-8">
              <p className="mb-8 text-2xl font-bold leading-8">{edit ? "Modifier" : "Nouvelle Production"}</p>
              <section>
                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end font-bold" htmlFor="title">
                    Titre de la production*
                  </label>
                  <div className="col-span-2">
                    <input className="w-full border-b border-blue-gray" type="text" name="title" onChange={handleChange} value={values.title} />
                    {errors.title && <p className="font-semi-bold text-xs text-[#f95620]">{errors.title}</p>}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end" htmlFor="type">
                    Type de production*
                  </label>
                  <div className="col-span-2 grid grid-cols-3">
                    {PROD_TYPES.map((type) => (
                      <label className="flex items-center" key={type}>
                        <input className="mr-2" type="radio" name="type" value={type} checked={values.type === type} onChange={handleChange} />
                        {type}
                      </label>
                    ))}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label htmlFor="era" className="flex justify-end">
                    Temporalité*
                  </label>
                  <div className="col-span-2 grid grid-cols-3">
                    {PROD_ERAS.map((era) => (
                      <label className="flex items-center" key={era}>
                        <input className="mr-2" type="radio" name="era" value={era} checked={values.era === era} onChange={handleChange} />
                        {era}
                      </label>
                    ))}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end" htmlFor="director">
                    Réalisation*
                  </label>
                  <div className="col-span-2">
                    <input className="w-full border-b border-blue-gray" type="text" name="director" onChange={handleChange} value={values.director} />
                    {errors.director && <p className="font-semi-bold text-xs text-[#f95620]">{errors.director}</p>}
                  </div>
                </div>
              </section>
              <section>
                <p className="mb-4 font-bold">Informations sur le projet</p>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end" htmlFor="productor">
                    Producteur/trice*
                  </label>
                  <div className="col-span-2">
                    <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="productor" onChange={handleChange} value={values.productor} />
                    {errors.productor && <p className="font-semi-bold text-xs text-[#f95620]">{errors.productor}</p>}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end" htmlFor="productionDirector">
                    Direction de production*
                  </label>
                  <div className="col-span-2">
                    <input className="w-full border-b border-blue-gray" type="text" name="productionDirector" onChange={handleChange} value={values.productionDirector} />
                    {errors.productionDirector && <p className="font-semi-bold text-xs text-[#f95620]">{errors.productionDirector}</p>}
                  </div>
                </div>
              </section>
              <section>
                <p className="mb-4 font-bold">Dates</p>

                <div className="mb-4 flex">
                  <p className="font-normal">
                    Les dates suivantes sont requises. Elles permettront de vous proposer un planning pour le pilotage de la démarche
                    <strong className="mx-1 font-bold">Production Responsable®</strong>
                    de votre projet.
                  </p>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end">Préparation (Décoration / Technique / HMC)</label>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">du :*</p>
                      <div className="flex-1">
                        <DateField value={values.preparationStart} onChange={(v) => setFieldValue("preparationStart", v)} />
                      </div>
                    </div>
                    {errors.preparationStart && <p className="font-semi-bold text-xs text-[#f95620]">{errors.preparationStart}</p>}
                  </div>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">au :*</p>
                      <div className="flex-1">
                        <DateField value={values.preparationEnd} onChange={(v) => setFieldValue("preparationEnd", v)} position="right-0" />
                      </div>
                    </div>
                    {errors.preparationEnd && <p className="font-semi-bold text-xs text-[#f95620]">{errors.preparationEnd}</p>}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end">Tournage</label>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">du :*</p>
                      <div className="flex-1">
                        <DateField value={values.shootingStart} onChange={(v) => setFieldValue("shootingStart", v)} />
                      </div>
                    </div>
                    {errors.shootingStart && <p className="font-semi-bold text-xs text-[#f95620]">{errors.shootingStart}</p>}
                  </div>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">au :*</p>
                      <div className="flex-1">
                        <DateField value={values.shootingEnd} onChange={(v) => setFieldValue("shootingEnd", v)} position="right-0" />
                      </div>
                    </div>
                    {errors.shootingEnd && <p className="font-semi-bold text-xs text-[#f95620]">{errors.shootingEnd}</p>}
                  </div>
                </div>

                <p className="font-normal">Les dates suivantes sont optionnelles.</p>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end">Pré-préparation (Production / MES / Régie)</label>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">du :</p>
                      <div className="flex-1">
                        <DateField value={values.prePreparationStart} onChange={(v) => setFieldValue("prePreparationStart", v)} />
                      </div>
                    </div>
                    {errors.prePreparationStart && <p className="font-semi-bold text-xs text-[#f95620]">{errors.prePreparationStart}</p>}
                  </div>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">au :</p>
                      <div className="flex-1">
                        <DateField value={values.prePreparationEnd} onChange={(v) => setFieldValue("prePreparationEnd", v)} position="right-0" />
                      </div>
                    </div>
                    {errors.prePreparationEnd && <p className="font-semi-bold text-xs text-[#f95620]">{errors.prePreparationEnd}</p>}
                  </div>
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end">Finitions de fin de tournage</label>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">du :</p>
                      <div className="flex-1">
                        <DateField value={values.postProductionStart} onChange={(v) => setFieldValue("postProductionStart", v)} />
                      </div>
                    </div>
                    {errors.postProductionStart && <p className="font-semi-bold text-xs text-[#f95620]">{errors.postProductionStart}</p>}
                  </div>
                  <div>
                    <div className="flex items-start mb-2">
                      <p className="mr-6">au :</p>
                      <div className="flex-1">
                        <DateField value={values.postProductionEnd} onChange={(v) => setFieldValue("postProductionEnd", v)} position="right-0" />
                      </div>
                    </div>
                    {errors.postProductionEnd && <p className="font-semi-bold text-xs text-[#f95620]">{errors.postProductionEnd}</p>}
                  </div>
                </div>
              </section>
              <section>
                <p className="mb-4 font-bold">Informations complémentaires</p>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end" htmlFor="countries">
                    Nom des pays / durée
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="countries" onChange={handleChange} value={values.countries} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="regions">
                    Nom des régions / durée
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="regions" onChange={handleChange} value={values.regions} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="studios">
                    Nom des studios / durée
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="studios" onChange={handleChange} value={values.studios} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="techniciansNumber">
                    Nombre de technicien.es (en moyenne)
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="techniciansNumber" onChange={handleChange} value={values.techniciansNumber} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="actorsNumber">
                    Nombre de comédien.es (en moyenne)
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="actorsNumber" onChange={handleChange} value={values.actorsNumber} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="extrasNumber">
                    Nombre de figurant.es (en moyenne)
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="extrasNumber" onChange={handleChange} value={values.extrasNumber} />
                </div>

                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="flex justify-end whitespace-nowrap" htmlFor="location">
                    Lieu(x) de tournage
                  </label>
                  <input className="col-span-2 w-full border-b border-blue-gray" type="text" name="location" onChange={handleChange} value={values.location} />
                </div>
              </section>
              <section>
                <div className="mb-4 grid grid-cols-3 items-center gap-4">
                  <label className="m-2 whitespace-nowrap font-bold" htmlFor="notes">
                    Notes
                  </label>
                  <textarea
                    className="col-span-2 h-20 w-full resize-y"
                    row={4}
                    name="notes"
                    placeholder="Entrez ici tous les renseignements utiles pour l’équipe Secoya ou le staff du tournage"
                    onChange={handleChange}
                    value={values.notes}
                  />
                </div>
              </section>
              <section>
                <div className="mb-4 grid grid-cols-3 gap-4">
                  <label className="m-2 whitespace-nowrap font-bold">Vignette du projet</label>
                  <div className="col-span-2">
                    <ImageUpload image={values.image} title={values.title} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                    <p className="font-normal">
                      Le fichier attendu doit être d'un format image (png, jpeg, jpg, gif, tiff, bmp), d'une taille inférieure à 2 Mo et d'un ratio idéal de 16:9.
                    </p>
                  </div>
                </div>
              </section>
              {errors.global && <div className="w-full text-center text-[#f95620]">{errors.global}</div>}
              <section className="flex place-content-center items-center gap-4">
                <button className=" rounded-sm border border-blue-dark bg-white px-5 py-2 font-bold" onClick={() => setOpen(false)}>
                  Annuler
                </button>
                <button className="rounded-sm border border-blue-dark bg-blue-dark px-5 py-2 font-bold text-white" type="submit">
                  {edit ? "Modifier" : "Enregistrer"}
                </button>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalNewProduction;

const DateField = ({ value, onChange, position = "left-0" }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (v) => {
    setOpen(false);
    onChange(v);
  };
  return (
    <div className="relative border-b border-blue-gray h-8">
      <button className="font-semibold w-full text-left h-full" type="button" onClick={() => setOpen(!open)}>
        <span>{value ? new Date(value).toLocaleString("fr").split(" ")[0] : ""}</span>
      </button>
      {open && (
        <div className={`absolute top-10 ${position} z-40 bg-white border border-blue-dark rounded`}>
          <DayPicker className="w-full" selectedDays={value} locale={fr} onDayClick={handleChange} />
        </div>
      )}
    </div>
  );
};

const ImageUpload = ({ image, title, setSelectedImage }) => {
  const [imageUrl, setImageUrl] = useState(image);

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    const newImageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageFile);
    setImageUrl(newImageUrl);
  };

  const handleDelete = () => {
    setImageUrl(null);
    setSelectedImage(null);
  };

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="relative h-56 flex-1">
          {imageUrl ? (
            <>
              <button className="top-o absolute right-0 bg-white p-2" onClick={handleDelete} type="button">
                <RiCloseLine />
              </button>
              <img src={imageUrl} className="h-full w-full object-contain" alt="Secoya avatar" />
            </>
          ) : (
            <div className="absolute top-0 left-0 h-full w-full overflow-hidden bg-blue-light">
              <p className="-mt-[50px] ml-5 text-[200px] text-blue-gray">{title}</p>
            </div>
          )}
        </div>
      </div>
      <div className="blue-button mt-5">
        <label htmlFor="image" className="w-full">
          {imageUrl ? "Changer d'image" : "Choisir une image"}
          <input name="image" id="image" className="hidden" type="file" accept="image/*" onChange={handleImageChange} />
        </label>
      </div>
    </>
  );
};
