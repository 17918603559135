import React from "react";
import ReactMarkdown from "react-markdown";

export const ActionInformation = (props) => {
  const { description, tips, services } = props;
  return (
    <div className="w-full">
      <p className="h4b">Informations générales</p>
      <div className="mt-4 grid w-full grid-cols-[40%_30%_30%] gap-4">
        <div>
          <p className="h5b mb-2 p-0">Description</p>
          <p className="hxr">{description}</p>
        </div>
        {tips && tips.length > 0 && (
          <div>
            <p className="h5b mb-2 p-0">Conseils</p>
            {tips.map((tip, i) => (
              <DocumentCheck key={i} text={tip} />
            ))}
          </div>
        )}
        {services && services.length > 0 && (
          <div>
            <p className="h5b mb-2 p-0">Départements concernés</p>
            {services.map((service, i) => (
              <DocumentCheck key={i} text={service} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const DocumentCheck = (props) => {
  const { object, text } = props;
  const isString = typeof text === "string" || typeof object === "string";
  if (isString) {
    const finalText = isString && text ? text : object;
    return (
      <section className="mb-2 flex items-center space-x-2">
        <img src={`/images/icons/check.svg`} alt="check" />
        <p>{finalText}</p>
      </section>
    );
  }
  const isMarkDown = typeof object === "object" && object.type === "markdown";
  if (isMarkDown) {
    const finalText = object.text;
    return (
      <section className="flex items-center space-x-2">
        <img src={`/images/icons/check.svg`} alt="check" />
        <div className="markdown flex flex-col">
          <ReactMarkdown>{finalText}</ReactMarkdown>
        </div>
      </section>
    );
  }
  const isFile = typeof object === "object"; //old projects are without type for files
  if (isFile) {
    const filesPath = `${process.env.REACT_APP_BACKEND_URL}/api/files/download_sample/${object.link}`;
    return (
      <section className="flex items-center space-x-2">
        <img src={`/images/icons/check.svg`} alt="check" />
        <a href={filesPath} download target="_blank" className="flex flex-col items-center" rel="noreferrer">
          <img src={`/images/icons/document.svg`} alt="document" className="w-12" />
          <p>{object.name}</p>
        </a>
      </section>
    );
  }
  return null;
};
