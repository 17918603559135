import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { HiQuestionMarkCircle, HiBell } from "react-icons/hi";
import { HiUserGroup } from "react-icons/hi2";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { IoLockClosed } from "react-icons/io5";
import { IoMdPlayCircle } from "react-icons/io";

// import { Modal } from "./Modal";
import LogoSecoset from "../assets/svg/logo-secoset.svg";
import api from "../api/apiHandler";

import { useDispatch } from "react-redux";
import { setUser } from "../redux/authSlice";

export const HeaderAuth = () => {
  return (
    <header className="flex h-16 items-center justify-center bg-white shadow">
      <img className="w-32" src={LogoSecoset} />
    </header>
  );
};

export const Header = () => {
  const { user } = useSelector((state) => state.Auth);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (!user) return;
    const getOrganizations = async () => {
      try {
        const res = await api.get(`/organization`);
        const { ok, data } = res.data;
        if (ok) setOrganizations(data);
      } catch (e) {
        console.log(e);
      }
    };
    getOrganizations();
  }, [user]);

  if (user.status === "WAITING_ACCEPTATION") return <></>;

  return (
    <header>
      <div className="flex h-24 items-center justify-between bg-white py-4 px-4 shadow">
        <div className="flex items-center gap-6">
          <Link to="/saas/">
            <img className="w-24 pb-1" src={LogoSecoset} />
          </Link>

          <span>|</span>
          <Link to="/saas/">Accueil</Link>
          {organizations.findIndex((o) => o.accessDashboard) !== -1 && <Link to="/saas/dashboard">Tableau de bord</Link>}
        </div>

        <div className="flex items-center gap-6">
          <a href="https://www.youtube.com/watch?v=BJFGkrcKhBg" target="_blank" className="flex gap-1 items-center text-blue-semi-dark hover:underline">
            <IoMdPlayCircle className="text-2xl " />
            <p className="block text-sm">Vidéo démo</p>
          </a>
          <span className="ml-1 text-3xl text-[#B9E1F0]">|</span>
          <AccountMenu user={user} />
        </div>
      </div>
    </header>
  );
};

const AccountMenu = ({ user }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    api
      .post("/saas-auth/logout")
      .then(() => {
        dispatch(setUser(null));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as="button">
          <div id="account-menu" className="flex items-center gap-2 rounded-full bg-secoset-green-300 py-2 pl-2 pr-3">
            <div className="rounded-full bg-secoset-green-700 p-2 text-xs text-white">
              {user.firstName?.charAt(0)}
              {user.lastName?.charAt(0)}
            </div>
            <p className="text-xs font-bold text-secoset-green-700">Mon compte</p>
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="divide-y-blue-gray absolute right-0 z-40 mt-2 flex w-48 origin-top-right flex-col divide-y bg-white shadow">
            <Menu.Item>
              <Link to="/saas/account" className="p-4 hover:bg-blue-gray">
                Mon compte
              </Link>
            </Menu.Item>
            <Menu.Item>
              <button onClick={handleLogout} className="cursor-pointer p-4 text-left hover:bg-blue-gray">
                Déconnexion
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
