// component for forgetting password or adding password to an account after invitation
import React, { useState } from "react";
import validator from "validator";
import { Formik, Form } from "formik";

import api from "../../api/apiHandler";
import LogoSecoset from "../../assets/svg/logo-secoset.svg";

import { LoginRight } from "./commons";

const Password = () => {
  const [sent, setSent] = useState(false);

  const validate = (values, setErrors) => {
    const errors = {};
    if (validator.isEmpty(values.email)) errors.email = "Ce champ est requis";
    else if (!validator.isEmail(values.email)) errors.email = "Email invalide";
    setErrors(errors);
    return !errors.email;
  };

  const handleSubmit = async (values, { setErrors }) => {
    if (!validate(values, setErrors)) return;

    api
      .post("/saas-auth/forgot-password", values)
      .then(() => setSent(true))
      .catch((error) => {
        console.log("error", error);
        setErrors({ email: "Une erreur est survenue" });
      });
  };

  return (
    <div className="flex h-full w-full flex-col bg-white lg:flex-row">
      <div className="flex items-center justify-center lg:w-[50%]">
        <div className="flex w-[360px] flex-col items-center">
          <img className="w-44" src={LogoSecoset} />
          <div className="text-[#667085]">Mot de passe oublié</div>
          {!sent ? (
            <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
              {({ values, errors, handleChange }) => (
                <Form className="my-6 flex flex-col items-center justify-center gap-4">
                  <div>Renseigner votre email pour réinitialiser votre mot de passe.</div>
                  <div>
                    <div className="mb-1 font-bold text-gray-500">Email</div>
                    <input className="saas-input w-[360px]" name="email" type="text" value={values.email} onChange={handleChange} />
                    {errors.email && <div className="mt-1 text-sm text-red">{errors.email}</div>}
                  </div>
                  <button className="blue-button mt-4 mb-8 w-[360px] text-lg" type="submit">
                    Réinitiliser le mot de passe
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="my-6">Un email vous a été envoyé si vous avez un compte sur Secoset afin de réinitialiser votre mot de passe</div>
          )}
        </div>
      </div>

      <LoginRight />
    </div>
  );
};

export default Password;
