import React from "react";
import { useNavigate } from "react-router-dom";
import { HiChevronRight } from "react-icons/hi";

const resetThematicExpandedStatuses = () => {
  localStorage.removeItem("expandedStatuses");
  localStorage.removeItem("lastThematicExpanded");
};

const ProductionLabel = ({ production, thematics }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    resetThematicExpandedStatuses();
    navigate(`/saas/productions/${production._id}/thematics`);
  };

  return (
    <div className=" bg-white p-5">
      <div className="mb-10 flex w-full items-center justify-between">
        <p className="h4b">Tableau de bord: {labelProductionStatus[production.status]}</p>
        <button className="blue-button flex items-center" onClick={handleClick}>
          Continuer le formulaire
          <HiChevronRight className="ml-2" />
        </button>
      </div>
      <div className="flex w-full items-center gap-4">
        <div className="relative">
          <img className="h-28" src="/images/Logo label production responsable.png" alt="Secoset" />
          <div className={`full absolute top-0.5 right-0.5 h-6 w-6 rounded-full bg-${production.status}`}> </div>
        </div>

        <LabelStatusSynthesis production={production} />
        <LabelStatusDetails thematics={thematics} production={production} />
      </div>
    </div>
  );
};

export const LabelStatusSynthesis = ({ production }) => {
  const { criterias } = production;

  const progress = criterias.reduce((prev, curr) => {
    if (["ok", "pending", "error", "invalid"].includes(curr.status)) return prev + 1;
    return prev;
  }, 0);

  return (
    <div className={`flex w-60 flex-col gap-4 rounded-sm border p-4`}>
      <p className="flex justify-center font-bold text-blue-semi-dark">{` ${(progress / criterias.length) * 100} % complétés (${progress}/${criterias.length})`}</p>

      <div className="relative">
        <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
          <div style={{ width: `${(progress / criterias.length) * 100}%` }} className="h-full bg-blue-dark" />
        </div>
      </div>
    </div>
  );
};

export const LabelStatByStatus = (props) => {
  const { stat, total } = props;
  const { icon, color, number, text } = stat;
  return (
    <div className="flex w-full space-x-1">
      <img src={`/images/icons/indicators/${icon}.svg`} alt={icon} />
      <p className="hxr" style={{ color: color }}>
        {`${number}/${total}`}
      </p>
      <p className="hxr">{text}</p>
    </div>
  );
};

export const LabelStatusDetails = ({ thematics, production }) => {
  return (
    <div className="z-0 grid grid-cols-3 gap-x-8 gap-y-3">
      {thematics.map((_thematic, i) => (
        <ThematicStatusDetails
          key={i}
          thematic={_thematic}
          index={i}
          production={production}
          criterias={production.criterias.filter(({ thematic }) => _thematic._id === thematic.toString())}
        />
      ))}
    </div>
  );
};

const ThematicStatusDetails = ({ thematic, production, criterias }) => {
  const navigate = useNavigate();

  const redirectToForm = () => {
    resetThematicExpandedStatuses();
    navigate(`/saas/productions/${production._id}/thematics`);
  };

  const renderStatus = (status) => {
    if (["ok", "pending", "error", "invalid"].includes(status)) return <div className="w-5 h-5 rounded-full border-2 border-blue-semi-dark bg-blue-semi-dark" />;
    return <div className="w-5 h-5 rounded-full border-2 border-blue-semi-dark" />;
  };

  return (
    <div className="grid cursor-pointer grid-cols-[190px_50px] justify-between space-x-3" onClick={redirectToForm}>
      <div className="flex items-center">
        <div className="relative flex items-center">
          <img className="z-30 ml-1 h-8 w-8" src={`/images/icons/thematics/${thematic.icon}.svg`} alt="Secoset" />
          <div className={`absolute h-8  w-8 rounded-full`} style={{ backgroundColor: thematic.color }} />
        </div>

        <p className="hxr ml-5 w-60">{thematic.title}</p>
      </div>
      <div className="flex min-w-min items-center gap-2">
        {renderStatus(criterias[0].status)}
        {renderStatus(criterias[1].status)}
      </div>
    </div>
  );
};

export default ProductionLabel;

export const labelProductionStatus = {
  ongoing: "En cours de complétion",
  validated: "Validée",
  error: "En attente",
  pending: "En attente de validation",
  rejected: "Finalisée et non validée",
};
