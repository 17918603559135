import React, { useState } from "react";
import { Formik, Form } from "formik";
import { BiDownload, BiChevronRight, BiXCircle, BiCheckCircle } from "react-icons/bi";
import { IoCopyOutline } from "react-icons/io5";

import api from "../../../api/apiHandler";
import { Modal } from "../../../components/Modal";

export const Indicators = ({ criteria, production, setProduction }) => {
  const productionCriteria = production.criterias.find((c) => c.criteria === criteria._id.toString());
  const [feedBackInfos, setFeedBackInfos] = useState({ type: null, message: null });
  const [sent, setSent] = useState(false);
  const [feedBackDocInfos, setFeedBackDocInfos] = useState({ type: null, message: null });
  const [openUnvalidatable, setOpenUnvalidatable] = useState(false);
  const [openConfirmSubmission, setOpenConfimrSubmision] = useState(false);
  const [documents, setDocuments] = useState(productionCriteria.documents);
  const [uploading, setUploading] = useState(false);

  const disableInput = productionCriteria.status === "ok" || productionCriteria.status === "pending";
  const disableDeleteFile = productionCriteria.status === "ok" || productionCriteria.status === "pending";
  const disableSubmit = (values) => {
    if (sent) return true;
    if (productionCriteria.status === "ok") return true;

    if (!values.indicator1 && criteria.indicatorUnit1) return true;
    if (!values.indicator2 && criteria.indicatorUnit2) return true;
    if (!values.indicator3 && criteria.indicatorUnit3) return true;
    if (documents.length === 0) return true;
    return false;
  };

  const handleSubmit = async (data) => {
    api
      .put(`/productions/indicators/${production._id}/${criteria._id}`, data)
      .then((res) => {
        setProduction(res.data.production);
        setFeedBackInfos({
          type: "success",
          message: "Les informations ont bien été transmises",
        });
        setSent(true);
      })
      .catch((err) => {
        setFeedBackInfos({
          type: "error",
          message: "Erreur lors la transmission des documents",
        });
      });
  };

  const handleDeleteFile = async (i) => {
    api
      .delete(`productions/files/${production._id}/${criteria._id}/${i}`)
      .then((res) => {
        setDocuments(res.data.documents);
        setFeedBackDocInfos({
          type: "success",
          message: "Le document a bien été supprimé",
        });
      })
      .catch((err) => {
        setFeedBackDocInfos({
          type: "error",
          message: "Erreur lors la suppression du fichier",
        });
      });
  };

  const handleAddFile = async (e) => {
    const files = e.target.files;
    const check = checkFiles(files);

    if (check.status === "ok") {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      setUploading(true);
      api
        .post(`/productions/files/${production._id}/${criteria._id}`, formData)
        .then((res) => {
          setDocuments(res.data.documents);
          setFeedBackDocInfos({
            type: "success",
            message: "Le fichier a bien été enregistré",
          });
          setUploading(false);
        })
        .catch((err) => {
          setFeedBackDocInfos({
            type: "error",
            message: "Erreur lors de l'enregistrement du fichier",
          });
          setUploading(false);
        });
    } else {
      setFeedBackDocInfos({
        type: "error",
        message: check.errorMessage,
      });
    }
  };

  if (!criteria) return <div>Chargement...</div>;

  return (
    <section className="mb-4 w-full bg-white p-4">
      <Formik className="w-full" initialValues={productionCriteria}>
        {({ values, handleChange }) => (
          <Form>
            <ConfirmModal open={openConfirmSubmission} setOpen={setOpenConfimrSubmision} handleSubmit={handleSubmit} data={values} />
            <NotValidModal open={openUnvalidatable} setOpen={setOpenUnvalidatable} />

            <p className="m-4 w-full text-xl font-bold"> Informations à transmettre </p>
            <FeedBack message={feedBackInfos.message} type={feedBackInfos.type} setFeedBackInfos={setFeedBackInfos} />

            <div className="m-4 grid w-full grid-cols-[25%_40%_30%] gap-4">
              <div className="border border-blue-dark bg-blue-light p-4">
                <h2 className="text-lg font-bold ">Indicateurs</h2>
                <p className="my-2 font-semibold">{criteria.kpiDescription}</p>
                {criteria.indicatorDescription1 && <p>Indicateur 1: {criteria.indicatorDescription1}</p>}
                <div className="relative mb-3 flex w-full flex-wrap items-center">
                  <input className="input pr-20" name="indicator1" value={values.indicator1} onChange={handleChange} disabled={disableInput} />
                  <p className="absolute right-2 z-10">{criteria.indicatorUnit1}</p>
                </div>
                {criteria.indicatorUnit2 && (
                  <>
                    {criteria.indicatorDescription2 && <p>Indicateur 2: {criteria.indicatorDescription2}</p>}
                    <div className="relative mb-3 flex w-full flex-wrap items-center">
                      <input className="input pr-20" name="indicator2" value={values.indicator2} onChange={handleChange} disabled={disableInput} />
                      <p className="absolute right-2 z-10">{criteria.indicatorUnit2}</p>
                    </div>
                  </>
                )}
                {criteria.indicatorUnit3 && (
                  <>
                    {criteria.indicatorDescription3 && <p>Indicateur 3: {criteria.indicatorDescription3}</p>}
                    <div className="relative flex w-full flex-wrap items-center">
                      <input className="input pr-20" name="indicator3" value={values.indicator3} onChange={handleChange} disabled={disableInput} />
                      <span className="absolute right-2 z-10">{criteria.indicatorUnit3}</span>
                    </div>
                  </>
                )}
                {criteria.enablePercentage ? (
                  <>
                    <p>Pourcentage:</p>
                    <div className="relative flex w-full flex-wrap items-center">
                      <input disabled className="input pr-20" value={(100 * (values.indicator1 / (values.indicator2 || 1))).toFixed(2)} />
                      <span className="absolute right-2 z-10">%</span>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="border border-blue-dark bg-blue-light p-4">
                <h2 className="mb-4 text-lg font-bold">Documents preuves</h2>
                <FeedBack message={feedBackDocInfos.message} type={feedBackDocInfos.type} setFeedBackInfos={setFeedBackDocInfos} />
                <h3 className="mb-2 font-bold">Points de controle:</h3>
                <div>
                  {criteria.checkpoints &&
                    criteria.checkpoints.map((point, i) => {
                      return (
                        <div className="flex" key={i}>
                          <img src={`/images/icons/check.svg`} alt="check" />
                          <p>{point}</p>
                        </div>
                      );
                    })}
                </div>

                <h3 className="mb-2 font-bold">Documents téléchargés:</h3>
                <div className="mb-4">
                  {documents.length > 0
                    ? documents.map((doc, i) => {
                        const j = doc.lastIndexOf("/");
                        const k = doc.lastIndexOf("?");
                        const filename = doc.substring(j + 1, k).replace(/%20/g, " ");

                        return (
                          <div className="flex" key={i}>
                            <img src={`/images/icons/document.svg`} alt="document" />
                            <a className="flex-1 truncate text-blue-dark underline underline-offset-1 hover:font-semibold" href={`${doc}`} download>
                              {filename}
                            </a>
                            {!disableDeleteFile && <img className="cursor ml-2" src={`/images/icons/delete.svg`} alt="delete file" onClick={() => handleDeleteFile(i)} />}
                          </div>
                        );
                      })
                    : `Cliquer sur "importer" pour transmettre votre document. Afin de limiter le stockage de données et ainsi de limiter ses impacts, les documents attendus ne doivent pas dépasser 5 Mo et doivent être d'un des formats suivants : pdf, doc, docx, xls, xlsx, jpg, jpeg, png, bmp.`}
                </div>
                <div className="flex justify-end">
                  <input
                    type="file"
                    name="documents"
                    id="documents"
                    multiple
                    accept=".pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png,.bmp"
                    onChange={handleAddFile}
                    disabled={disableInput}
                    hidden
                  />
                  <label
                    className={`empty-button mr-0 flex items-center gap-2 ${disableInput ? "cursor-default opacity-50 hover:bg-transparent" : "cursor-pointer"}`}
                    disabled={disableInput}
                    htmlFor="documents">
                    <BiDownload className="mr-2" />
                    {uploading ? "Chargement..." : "Importer un document"}
                  </label>
                </div>
              </div>

              <div className="border border-blue-dark bg-blue-light p-4">
                <p className="mb-4 text-lg font-bold">Message</p>
                <p className="mb-4">Facultatif. Vous pouvez précisez ici toutes les informations que vous pensez utiles pour ce critère.</p>
                <textarea className="input" rows={3} name="message" value={values.message || ""} onChange={handleChange} disabled={disableInput} />
              </div>
            </div>
            <div className="my-6 mx-4 flex justify-end gap-4">
              {/* <button className="empty-button cursor">
                Afficher l'historique
              </button> */}
              {console.log("disableSubmit", disableSubmit(values))}
              <button className="empty-button" type="button" onClick={() => setOpenUnvalidatable(true)}>
                Le projet ne peut valider ce critère
              </button>
              <button className="blue-button flex items-center" type="button" onClick={() => setOpenConfimrSubmision(true)} disabled={disableSubmit(values)}>
                Transmettre mes informations <BiChevronRight className="ml-2 text-xl font-bold" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

// To change ?
const checkFiles = (files) => {
  const maximumSize = 5000000; //octets
  const MIMETypeAccepted = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
    "image/bmp",
  ];
  const fileList = Object.values(files);
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    if (file.size > maximumSize) {
      return {
        file,
        errorType: "File size",
        status: "error",
        errorMessage: `Le fichier ${file.name} est trop volumineux`,
      };
    }
    if (!MIMETypeAccepted.includes(file.type)) {
      return {
        file,
        errorType: "File format",
        status: "error",
        errorMessage: `Le format du fichier ${file.name} n'est pas accepté`,
      };
    }
  }
  const maxSize = Math.max(...fileList.map((file) => file.size));
  const file = fileList.filter((file) => file.size === maxSize)[0];
  return {
    file,
    status: "ok",
  };
};

const FeedBack = ({ type, message, setFeedBackInfos }) => {
  if (!message) return null;
  return (
    <div className="mb-4 flex items-center justify-between p-2" style={{ backgroundColor: type === "error" ? "#FDEDED" : "#F3FFF9" }}>
      <div className="flex items-center">
        {type === "error" ? <BiXCircle className="text-red-700" /> : <BiCheckCircle className="text-green-700" />}
        <p className="ml-3">{message}</p>
      </div>
      <button className="mr-2" onClick={() => setFeedBackInfos({ type: null, message: null })}>
        <BiXCircle />
      </button>
    </div>
  );
};

const ConfirmModal = ({ open, setOpen, handleSubmit, data }) => {
  const handleConfirm = () => {
    handleSubmit(data);
    setOpen(false);
  };
  return (
    <Modal className="w-full max-w-4xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="bg-white px-12 pt-6 pb-12">
        <h1 className="m-4 text-2xl font-bold">Confirmation</h1>
        <p className="m-4">Vous êtes sur le point de soumettre ces informations. Avant de confirmer, notez bien que : </p>
        <div className="m-2 flex items-center">
          <BiCheckCircle className="mr-2" />
          <p>Vous ne pourrez plus modifier ces documents avant le retour de Secoya</p>
        </div>
        <div className="m-2 flex items-center">
          <BiCheckCircle className="mr-2" />
          <p>Secoya vous répond sous 48h</p>
        </div>
        <div className="m-2 flex items-center">
          <BiCheckCircle className="mr-2" />
          <p>Une fois validées, ces informations ne seront pas modifiables</p>
        </div>

        <div className="m-6 flex justify-end gap-4">
          <button className="empty-button flex items-center" onClick={() => setOpen(false)}>
            <BiXCircle className="mr-2" />
            Fermer
          </button>
          <button className="blue-button flex items-center" onClick={handleConfirm}>
            <BiCheckCircle className="mr-2" />
            Confirmer
          </button>
        </div>
      </div>
    </Modal>
  );
};

const NotValidModal = ({ open, setOpen }) => {
  return (
    <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="bg-white px-12 pt-6 pb-12">
        <h1 className="mb-4 text-xl font-bold">Critère non validable ?</h1>
        <p>
          Si votre projet n'est pas en mesure de valider ce critère, nous vous recommandons néanmoins de nous transmettre les informations et mesures à votre disposition pour
          finaliser votre projet.
        </p>
        <div className="m-4 flex justify-end">
          <button className="blue-button" onClick={() => setOpen(false)}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};
