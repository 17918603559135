import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";

import { BreadCrumps } from "../../components/BreadCrumps";
import { ModalEducationalSheet } from "./criteria/modal_educational_sheet";

import api from "../../api/apiHandler";

import { useNavigate } from "react-router-dom";
import { BsChevronRight, BsChevronDown, BsChevronUp } from "react-icons/bs";

export const Thematics = () => {
  const { productionId } = useParams();

  const [production, setProduction] = useState();
  const [thematics, setThematics] = useState([]);
  const [criterias, setCriterias] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  useEffect(() => {
    api.get(`/productions/${productionId}`).then(({ data }) => {
      setProduction(data.production);
      setCrumbs([
        { label: data.production.title, link: `/saas/productions/${data.production._id}` },
        { label: "Formulaire", link: `/saas/productions/${data.production._id}/thematics` },
      ]);
    });
    api.get(`/labels/all?productionId=${productionId}`).then(({ data }) => {
      setThematics(data.thematics);
      console.log(data);
      setCriterias(data.criterias);
    });
  }, []);

  if (!production)
    return (
      <div className="h-full bg-white p-5">
        <Loader />
      </div>
    );

  return (
    <div className="w-full p-4">
      <div className="mb-4">
        <BreadCrumps crumbs={crumbs} setCrumbs={setCrumbs} />
      </div>

      <div className="mb-4 flex w-full items-center justify-between bg-white p-4">
        <div className="flex items-center space-x-4">
          <div className="relative">
            <img className="h-16" src="/images/Logo label production responsable.png" alt="Secoset" />
            <div className={`full absolute top-0.5 right-0.5 h-4 w-4 rounded-full bg-${production.status}`}> </div>
          </div>

          <div>
            <p className="h3b">Tableau de bord : {production.title}</p>
            <p className="hxb">{labelProductionStatus[production.status]}</p>
            <p className="hxr">{textProductionStatus[production.status]}</p>
          </div>
        </div>
        <div className="flex h-full items-center justify-end">
          <div className="relative w-60">
            <p className="text-blue-semi-dark">
              {(production.criteriaDone / production.criterias.length) * 100}% complétés ({production.criteriaDone}/{production.criterias.length})
            </p>
            <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
              <div style={{ width: `${(production.criteriaDone / production.criterias.length) * 100}%` }} className="h-full bg-blue-dark" />
            </div>
          </div>
        </div>
      </div>
      <ProductionFormDetail thematics={thematics} criterias={criterias} production={production} />
    </div>
  );
};

const ProductionFormDetail = ({ thematics, criterias, production }) => {
  if (!thematics || !criterias)
    return (
      <div className="pu-4 m-4 h-[500px] rounded-xl bg-white">
        <Loader />
      </div>
    );

  return (
    <div className="rounded-xl bg-white">
      <div className="flex flex-col gap-4">
        {thematics.map((thematic, index) => {
          // Add default to avoid duplicate criterias --> to be removed when criterias are fixed
          const c = criterias.filter((c) => c.thematic.toString() === thematic._id.toString());
          return <Action key={index} thematic={thematic} criterias={c} canView={production?.plan !== "demo"} production={production} />;
        })}
      </div>
    </div>
  );
};

const Action = ({ thematic, criterias, canView, production }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [openEducationalSheet, setOpenEducationalSheet] = useState(false);

  return (
    <div className="relative">
      <ModalEducationalSheet open={openEducationalSheet} setOpen={setOpenEducationalSheet} thematic={thematic} criterias={criterias} />
      <div
        onClick={() => setIsOpened(!isOpened)}
        className={`sticky top-0 z-20 flex w-full cursor-pointer flex-row items-center justify-between p-2`}
        style={{ backgroundColor: thematic.color }}>
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/thematics/${thematic.icon}.svg`} alt={thematic.icon} className="h-[32px] w-[32px]" />
          <div className="text-xl font-bold text-[#103c4b]">{thematic.title}</div>
        </div>
        <div className="flex gap-3 items-center">
          {canView ? (
            <button className="empty-button" onClick={() => setOpenEducationalSheet(true)}>
              Voir fiche pédagogique
            </button>
          ) : null}
          <div className="px-3">{isOpened ? <BsChevronUp className="h-6 w-6" /> : <BsChevronDown className="h-6 w-6" />}</div>
        </div>
      </div>
      <div className="relative">
        {isOpened && (
          <>
            {!canView && (
              <div className="absolute left-[50%] top-2 z-10 ">
                <div className="relative left-[-50%] w-[400px] bg-white p-10 text-center shadow-md">
                  <div className="text-sm">Les critères détaillés sont accessibles après activation du projet. Sélectionner votre offre.</div>
                  <div className="mt-6">
                    <a className="rounded bg-secoset-green-700 p-3 text-sm text-white" href="http://calendly.com/mathieu-delahousse" target="_blank">
                      Activez votre forfait
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className={canView ? "" : "blur-sm"}>
              <div className=" my-4 flex flex-row items-center text-xs">
                <div className="w-[40%] px-2">Critère d'évaluation</div>
                <div className="w-[40%] px-2">Actions</div>
                <div className="w-[20%] px-2">Statut</div>
              </div>
              {criterias.map((c, i) => (
                <CriteriaAction key={i} criteria={c} production={production} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CriteriaAction = ({ criteria, production }) => {
  const navigate = useNavigate();
  const productionCriteria = production?.criterias?.find((c) => c.criteria === criteria._id);
  return (
    <div
      className="flex cursor-pointer flex-row items-center py-4 px-2 hover:bg-secoset-green-5"
      onClick={() => navigate(`/saas/productions/${production._id}/criteria/${productionCriteria.criteria}`)}>
      <div className="flex w-[40%] flex-col self-start text-start">
        <div className="font-bold">{criteria.title}</div>
      </div>
      <div className="flex w-[40%] flex-col items-start  gap-2 px-2">
        <div>{criteria.actionTitle}</div>
      </div>

      <div className="flex w-[20%] flex-row justify-between self-start px-2">
        <div className="flex items-center">
          <img src={`/images/icons/indicators/${productionCriteria?.status}.svg`} alt={productionCriteria?.status} />
          <div>{labelStatus[productionCriteria?.status]}</div>
        </div>

        <BsChevronRight className="h-[25px] w-[25px]" />
      </div>
    </div>
  );
};

const labelStatus = {
  ok: "validé",
  pending: "en attente",
  todo: "à soumettre",
  error: "non conforme",
  invalid: "non validé",
};

const labelProductionStatus = {
  ongoing: "En cours",
  validated: "Labelisé",
  error: "En erreur",
  pending: "En attente de label",
  rejected: "Rejeté",
};

const textProductionStatus = {
  ongoing: "Vous êtes en bonne voie pour valider votre projet, continuez !",
  validated: "Félicitations, vous avez validé votre projet !",
  error: "Au moins l'un des documents est non conforme. Remédiez-y !",
  pending: "Votre projet a bien été soumis, Secoya se penche actuellement dessus !",
  rejected: "Félicitations, votre projet est terminé, bien que non validé !",
};
