import React, { useState, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import { HiUserGroup, HiPencilAlt } from "react-icons/hi";
import { HiOutlineX, HiInformationCircle, HiChevronDown } from "react-icons/hi";
import { BsCheckLg } from "react-icons/bs";
import { AiFillCopy } from "react-icons/ai";

import ModalNewProduction from "../new_modal";
import { ModalDeleteProduction, ModalOfficialLabel } from "./modals";
import { PRODUCTION_PLAN } from "../../../utils/constants";

import { productionIcons } from "../../../components/ProductionBadges";
import { Modal } from "../../../components/Modal";

import api from "../../../api/apiHandler";
import { Tooltip } from "react-tooltip";
import { BiTrash } from "react-icons/bi";

import Pagination from "@mui/material/Pagination";
import { SuccessModal } from "../../../components/SuccessModal";
import Loader from "../../../components/Loader";

const planBadge = {
  demo: (
    <div className="border-2 border-dashed border-plan-free px-2 py-1">
      <p className="text-base text-plan-free">Découverte</p>
    </div>
  ),
  label: (
    <div className="border-2 border-dashed border-plan-label px-2 py-1">
      <p className="text-base text-plan-label">Outil</p>
    </div>
  ),
  custom: (
    <div className="border-2 border-dashed border-plan-custom px-2 py-1">
      <p className="text-base text-plan-custom">Pilotage</p>
    </div>
  ),
  strategy: (
    <div className="border-2 border-dashed border-plan-strategy px-2 py-1">
      <p className="text-base text-plan-strategy">Stratégie</p>
    </div>
  ),
};

const ProductionInformation = ({ production, setProduction }) => {
  const [openInvitatonModa, setOpenInvitationModal] = useState(false);
  const [openEditProduction, setOpenEditProduction] = useState(false);
  const [openDeleteProduction, setOpenDeleteProduction] = useState(false);
  const [openOfficialLabel, setOpenOfficialLabel] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <div className="bg-white p-5">
      <ModalNewProduction open={openEditProduction} setOpen={setOpenEditProduction} edit production={production} setProduction={setProduction} />

      <ModalDeleteProduction open={openDeleteProduction} setOpen={setOpenDeleteProduction} production={production} />

      <ModalOfficialLabel open={openOfficialLabel} setOpen={setOpenOfficialLabel} production={production} />

      <InvitationModal
        open={openInvitatonModa}
        setOpen={setOpenInvitationModal}
        production={production}
        onSuccess={() => {
          setOpenInvitationModal(false);
          setShowSuccessModal(true);
        }}
      />

      <Modal isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <SuccessModal
          closeModal={() => setShowSuccessModal(false)}
          titre="Les membres ont été invités."
          text="Félicitations, vos utilisateurs ont bien été invités. Ils recevront sous peu un email d’invitation pour activer leur compte."
        />
      </Modal>

      {/* <ModalValidateProduction open={openValidateProduction} setOpen={setOpenValidateProduction} production={production} setProduction={setProduction} /> */}

      <div className="grid w-full grid-cols-4 gap-x-2.5">
        <div className="relative h-full w-full" stickerposition="left">
          {production.image ? (
            <img className="absolute top-0 left-0 h-full w-full overflow-hidden rounded-sm object-contain" src={production.image} alt="illustration" />
          ) : (
            <div className="absolute top-0 left-0 h-full w-full overflow-hidden bg-blue-light">
              <p className="-mt-[50px] ml-5 text-[240px] text-blue-gray">{production.title.slice(0, 3)}</p>
            </div>
          )}
          {productionIcons[production.type]}
        </div>
        <div className="col-span-2">
          <div className="flex items-center gap-3">
            <h2 className="text-3xl font-bold">{production.title}</h2>
            {planBadge[production.plan]}
          </div>
          <p className="text-base font-semibold" style={{ marginBottom: "8px" }}>
            {production.production}
          </p>
          <div className="flex items-center">
            <p className="min-w-[200px]">Direction de prod.</p>
            <p className="text-base font-semibold">{production.productionDirector}</p>
          </div>
          <div className="flex items-center">
            <p className="min-w-[200px]">Réalisation</p>
            <p className="text-base font-semibold">{production.director}</p>
          </div>
          <div className="flex items-end">
            <p className="min-w-[200px]">Tournage</p>
            <p className="text-base font-semibold">{`du ${new Date(production.shootingStart).toLocaleDateString("fr")} au ${new Date(production.shootingEnd).toLocaleDateString(
              "fr",
            )}`}</p>
          </div>
        </div>
        <div className="flex flex-col items-end justify-between">
          <div className="flex items-center gap-6">
            <button className="text-2xl text-secoset-green-900" onClick={() => setOpenEditProduction(true)}>
              <HiPencilAlt />
            </button>
            <button className="empty-button flex items-center gap-2" onClick={() => setOpenInvitationModal(true)}>
              <HiUserGroup />
              Inviter
            </button>
          </div>
          <div className="flex flex-col items-end gap-2">
            <p>
              Type de production: <span className="rounded-xs ml-1 bg-secoset-green-300 p-1 text-secoset-green-700">{PRODUCTION_PLAN[production.plan] || production.plan}</span>
            </p>
            {production.plan === "demo" && (
              <p className="whitespace-nowrap font-semibold text-sky-400">
                Vous êtes sur une version démo.
                <a href="https://calendly.com/mathieu-delahousse" className="ml-2 underline" target="_blank">
                  Passez en version payante
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionInformation;

const InvitationModal = ({ open, setOpen, onSuccess, production }) => {
  const [view, setView] = useState("email");
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [users, setUsers] = useState([]);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [invitLink, setInvitLink] = useState("");
  const [invitLinkRole, setInvitRole] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // check if there is a space --> new email
    if (emailInput.includes(" ") && emailInput.length > 1) {
      setEmails([...emails, emailInput]);
      setEmailInput("");
    }
  }, [emailInput]);

  useEffect(() => {
    // dont know if its necessary but look better
    if (error !== "") {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    setInvitLink("");
    setCopied(false);
  }, [invitLinkRole]);

  const handleAddEmail = () => {
    // handle the case where emailINputArray is empty but we have an email in emailInput
    if (emails.length === 0 && emailInput.length > 1) {
      // unique email
      if (users.some((user) => user.email === emailInput)) setError("L'email est déjà dans la liste.");
      setUsers([...users, { email: emailInput, role: null, project: [] }]);
    } else {
      const newUsers = emails.map((email) => ({ email, role: null, project: [production._id] })).filter((newUser) => !users.some((oldUser) => oldUser.email === newUser.email));
      setUsers([...users, ...newUsers]);
    }
    setEmailInput("");
    setEmails([]);
  };

  const handleRemoveEmail = (email) => setEmails(emails.filter((e) => e !== email));
  const handleRemoveUser = (user) => setUsers(users.filter((u) => u !== user));

  const handleInviteEmails = async () => {
    try {
      if (loading) return;
      setLoading(true);
      await api.post(`/productions/${production._id}/invite`, { users });
      setLoading(false);
      onSuccess();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const generateInviteLink = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await api.post(`/productions/${production._id}/generate-invite-link`, { role: invitLinkRole });
      setInvitLink(res.data.link);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Modal className="w-full max-w-3xl" isOpen={open} onClose={() => setOpen(false)}>
      <div className="flex w-[760px] flex-col items-center justify-center ">
        <div className="flex w-full flex-col items-center justify-center px-10">
          <div className="text-3xl font-bold text-secoset-green-700">Invitez des utilisateurs sur {production.title}</div>

          <div className="mt-7 flex w-full items-center justify-start gap-4 pb-2">
            <div
              onClick={() => setView("email")}
              className={`cursor-pointer p-2 font-bold text-gray-500 ${view === "email" && "rounded-lg bg-secoset-green-5 text-secoset-green-700"}`}>
              Par email
            </div>
            {/* <div
              onClick={() => setView("link")}
              className={`cursor-pointer p-2 font-bold text-gray-500 ${view !== "email" && "rounded-lg bg-secoset-green-5 text-secoset-green-700"}`}>
              Par lien
            </div> */}
          </div>
        </div>

        <div className="mt-2 mb-4 h-[2px] w-full bg-[#CDEAF4]" />

        {view === "email" ? (
          <div className="flex w-full flex-col items-start justify-center px-10">
            <div className="rounded-lg border-[1px] border-[#CDEAF4] p-2 text-xs font-medium text-secoset-green-700">
              <span className="font-bold">Entrez les adresses email</span> des personnes que vous souhaitez inviter sur Secoset. Elles recevront un email d’invitation et auront{" "}
              <span className="font-bold">15 jours pour activer leur compte</span>.
            </div>

            <div className="my-6 flex w-full flex-row items-center justify-center gap-4">
              <div className=" flex w-[90%] flex-wrap gap-2 rounded border-[1px] border-secoset-green-700 px-4 py-3 focus:border-secoset-green-400">
                {emails.map((email) => (
                  <div key={email} className="flex w-fit items-center justify-between gap-2 rounded border-[1px] border-secoset-green-700 px-2 py-1">
                    <div className="font-bold text-secoset-green-700">{email}</div>
                    <HiOutlineX onClick={() => handleRemoveEmail(email)} className="h-[14px] w-[14px] cursor-pointer" />
                  </div>
                ))}
                <input
                  className={`${emails.length === 0 && "w-[90%]"} focus:outline-none focus:ring-0`}
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  placeholder={`${emails.length === 0 ? "Ajoutez un ou plusieurs emails en les séparant par des espaces" : ""}`}
                />
              </div>
              <button className="blue-button" onClick={handleAddEmail}>
                Ajouter
              </button>
            </div>
            {error && <div className="text-sm font-medium text-red">{error}</div>}
            <div className={`mt-1 flex w-full items-center bg-secoset-green-700 py-3 text-sm font-bold text-secoset-green-5 ${users.length === 0 && "opacity-30"}`}>
              <div className="w-[35%] px-2">Utilisateurs</div>
            </div>
            <div className="mb-4 max-h-[180px] min-h-[80px] w-full">
              {users.map((u) => (
                <div key={u.email} className="flex h-12 w-full items-center justify-between border-b border-secoset-green-5 px-2 py-2 font-bold">
                  <div className="w-[35%] font-normal">{u.email}</div>
                  <BiTrash onClick={() => handleRemoveUser(u)} className="w-[5%] cursor-pointer " />
                </div>
              ))}
            </div>

            <div className="mb-8 mt-2 flex w-full gap-4">
              <button onClick={() => setOpen(false)} className="w-[50%] rounded border-[1px] border-secoset-green-900 py-3 text-center font-bold text-secoset-green-900">
                Annuler
              </button>
              <button
                onClick={handleInviteEmails}
                className="flex w-[50%] items-center justify-center rounded bg-secoset-green-700 text-center font-bold text-secoset-green-5 disabled:opacity-30"
                disabled={!users.length}>
                {loading ? <Loader /> : <div className="py-3">Inviter</div>}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col items-start justify-center px-10">
            <div className={`w-full ${!invitLinkRole && "opacity-30"}`}>
              <div className={`mt-6 text-start font-bold text-gray-500`}>Envoyez le lien ci-dessous aux utilisateurs que vous souhaitez inviter</div>
              <div className="mb-10 mt-1 flex w-full gap-4">
                {invitLink === "" ? (
                  <>
                    <div className="saas-input w-[90%] flex-1 bg-secoset-green-5 text-sm font-bold text-[#AEAEAE]">https://www.secoset.fr/blablablabla...</div>
                    <div
                      onClick={generateInviteLink}
                      className={`flex  cursor-pointer items-center justify-center rounded bg-secoset-green-700  px-6 text-center font-bold text-secoset-green-5`}>
                      {loading ? <Loader /> : <div className="py-3">Générer</div>}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="saas-input w-[90%] flex-1 truncate text-sm font-medium text-secoset-green-700">{invitLink}</div>
                    <div
                      onClick={() => {
                        setCopied(true);
                        navigator.clipboard.writeText(invitLink);
                      }}
                      className={`flex cursor-pointer items-center justify-center rounded bg-secoset-green-700  px-6 text-center font-bold text-secoset-green-5`}>
                      <AiFillCopy className="mr-2" />
                      <div className="py-3">{copied ? "Copié !" : "Copier le lien"}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <Tooltip id="role-description-modal" className="text-sm">
          Simple : peut suivre et remplir les critères Secoset de ses projets. <br />
          Administrateur : peut créer des projets, inviter des utilisateurs et gérer la facturation de la société.
        </Tooltip>
      </div>
    </Modal>
  );
};
