import React from "react";
import { Route, Routes } from "react-router-dom";

import Edit from "./edit";
import { Thematics } from "./thematics";
import { Criteria } from "./criteria";

export default () => {
  return (
    <Routes>
      <Route path="/:productionId" element={<Edit />} />
      <Route path="/:productionId/thematics" element={<Thematics />} />
      <Route path="/:productionId/criteria/:criteriaKey" element={<Criteria />} />
    </Routes>
  );
};
