import React, { useEffect } from "react";
import { Outlet, Route, Routes, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//Views
import Directory from "./scenes/directory";

// New Scenes
import Home from "./scenes/home";
import Account from "./scenes/account";
import Productions from "./scenes/productions";
import Dashboard from "./scenes/dashboard";
import Signin from "./scenes/auth/signin";
import Signup from "./scenes/auth/signup";
import LoginAs from "./scenes/auth/loginas";
import VerifyEmail from "./scenes/auth/verify-email";
import SentEmail from "./scenes/auth/sent-email";
import ForgotPassword from "./scenes/auth/forgot-password";
import ResetPassword from "./scenes/auth/reset-password";
import JoinEmail from "./scenes/auth/join-email";
import ActionsSaas from "./scenes/help/actions";

import { Header } from "./components/Headers";

import { useDispatch, useSelector } from "react-redux";
import api from "./api/apiHandler";
import { setUser } from "./redux/authSlice";

const AuthLayout = ({ user }) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "/saas";
  const location = useLocation();

  // if user is logged in and it's not loginas feature, redirect to saas
  if (user && !location.pathname.includes("loginas")) return <Navigate to={redirect} replace={true} />;

  return (
    <div className="flex h-full w-full flex-col bg-blue-light">
      <Outlet />
    </div>
  );
};

const ProtectedLayout = ({ user }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  if (!user) return <Navigate to={`/signin?redirect=${encodeURIComponent(location.pathname)}`} replace={true} />;
  // forcer le user sur la page pour compléter ses informations
  if (user.status === "WAITING_INFORMATION" && location.pathname !== "/saas") return <Navigate to="/saas" replace={true} />;

  useEffect(() => {
    api
      .get("/saas-auth/is-loggedin")
      .then((res) => {
        if (res.data.data) dispatch(setUser(res.data.data));
        else dispatch(setUser(null));
      })
      .catch((err) => {
        dispatch(setUser(null));
      });
  }, []);

  return (
    <div className="flex h-full w-full flex-col">
      <Header />
      <div className="flex flex-1 flex-col bg-blue-light">
        <Outlet />
      </div>
    </div>
  );
};

const contextClass = {
  success: "bg-secoset-success-100 text-secoset-success-700",
  error: "bg-secoset-error-100 text-secoset-error-700",
  default: "bg-secoset-primary-100 text-secoset-primary-700",
};

export default () => {
  const user = useSelector((state) => state.Auth.user);
  return (
    <>
      <ToastContainer
        className="p-0"
        toastClassName={({ type }) => contextClass[type || "default"] + ` flex justify-between py-4 px-3 min-h-10 shadow-none rounded-none`}
        bodyClassName={() => "flex gap-4 text-sm"}
        progressClassName="m-0.5"
        position="top-right"
        autoClose={5000}
      />
      <Routes>
        <Route element={<AuthLayout user={user} />}>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/loginas" element={<LoginAs />} />
          <Route path="/join-email" element={<JoinEmail />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/sent-email" element={<SentEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<ProtectedLayout user={user} />}>
          <Route path="*" element={<Home />} />
          <Route path="/saas/" element={<Home />} />
          <Route path="/saas/account/" element={<Account />} />
          <Route path="/saas/productions/*" element={<Productions />} />
          <Route path="/saas/dashboard/*" element={<Dashboard />} />
          <Route path="/saas/help/directory" element={<Directory />} />
          <Route path="/saas/help/actions" element={<ActionsSaas />} />
        </Route>
      </Routes>
    </>
  );
};
