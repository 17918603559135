import React, { useState, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import api from "../../api/apiHandler";
import Loader from "../../components/Loader";
import Select from "../../components/Select-bis";

const SERVICES = ["PROD", "MISE EN SCÈNE", "REGIE", "DECORATION", "TECHNIQUE", "CANTINE", "HMC"];

const Actions = () => {
  const [thematics, setThematics] = useState(null);
  const [filters, setFilters] = useState({ services: "" });

  useEffect(() => {
    const params = new URLSearchParams(filters);
    api
      .get("/action?" + params.toString())
      .then(({ data }) => {
        const newThematics = [];
        data.data.forEach((action) => {
          const index = newThematics.findIndex((a) => a.id === action.thematicId);
          if (index === -1) {
            newThematics.push({
              id: action.thematicId,
              title: action.thematicTitle,
              icon: action.thematicIcon,
              color: action.thematicColor,
              actions: [action],
            });
          } else {
            newThematics[index].actions.push(action);
          }
        });

        setThematics(newThematics);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [filters]);

  if (!thematics)
    return (
      <div className="pu-4 m-4 h-[500px] rounded-xl bg-white">
        <Loader />
      </div>
    );

  return (
    <div className="m-4 rounded-xl bg-white py-4">
      <div className="mx-4 mb-4 grid grid-cols-12">
        <div className="col-span-12 md:col-span-10">
          <p className="text-2xl font-bold">Guide d'actions</p>
          <p className="text-sm font-normal">
            Dans ce guide, retrouvez une liste d’actions et conseils répartis par thématiques et départements afin de mener à bien votre démarche "
            <span className="font-semibold">Production Responsable®</span>". Parcourez les différentes rubriques pour être accompagnés dans la mise en place d’actions concrètes
            pour obtenir votre label officiel. (NB: "
            <span className="font-semibold">Des actions se retrouvent dans plusieurs thématiques, cela indique qu'elles permettent de répondre à plusieurs critères!</span>")
          </p>
        </div>
        <div className="z-30 col-span-12 md:col-span-2">
          <Select
            options={[{ label: "Toutes les thématiques", value: "" }, ...SERVICES.map((f) => ({ label: f, value: f }))]}
            value={filters?.services}
            onChange={(e) => (filters?.services === e ? setFilters({ ...filters, services: "" }) : setFilters({ ...filters, services: e }))}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {thematics.map((t, i) => (
          <Action key={i} thematic={t} />
        ))}
      </div>
    </div>
  );
};

const Action = ({ thematic }) => {
  const [isOpened, setIsOpened] = useState(false);
  const canView = true;

  return (
    <div className="">
      <div
        onClick={() => setIsOpened(!isOpened)}
        className={`sticky top-0 z-20 flex w-full cursor-pointer flex-row items-center justify-between p-2`}
        style={{ backgroundColor: thematic.color }}>
        <div className="flex flex-row items-center gap-2">
          <img src={`/images/icons/thematics/${thematic.icon}.svg`} alt={thematic.icon} className="h-[32px] w-[32px]" />
          <div className="text-xl font-bold text-[#103c4b]">{thematic.title}</div>
        </div>
        {isOpened ? <BsChevronUp className="h-[25px] w-[25px]" /> : <BsChevronDown className="h-[25px] w-[25px]" />}
      </div>
      <div className="relative">
        {isOpened && (
          <>
            {!canView && (
              <div className="absolute left-[50%] top-6 z-10 ">
                <div className="relative left-[-50%] w-[400px] bg-white p-8 text-center shadow-md">
                  <div>Le guide d'action est disponible après activation du projet. Sélectionner votre offre.</div>
                  <button className="blue-button mt-4 w-full">Voir les offres</button>
                </div>
              </div>
            )}
            <div className={canView ? "" : "blur-sm"}>
              <div className=" my-4 flex flex-row items-center text-xs">
                <div className="w-[40%] px-2">Action</div>
                <div className="w-[40%] px-2">Conseil</div>
                <div className="w-[20%] px-2">Département concerné</div>
              </div>
              {thematic.actions.map((a, i) => (
                <div key={i} className="flex flex-row items-center py-4 px-2 hover:bg-secoset-green-5">
                  <div className="flex w-[40%] flex-col self-start text-start">
                    <div className="font-bold">{a.title}</div>
                    <div>{a.description}</div>
                  </div>
                  <div className="flex w-[40%] flex-col items-start  gap-2 px-2">
                    {a.tips.map((tip, j) => (
                      <div key={j} className="flex flex-row items-center justify-start gap-2">
                        <img src="/images/icons/check.svg" alt={tip} className="h-[32px] w-[32px]" />
                        <div>{tip}</div>
                      </div>
                    ))}
                  </div>
                  <div className="flex w-[20%] flex-col justify-start self-start px-2">
                    {a.services.map((service, k) => (
                      <div key={k} className="flex h-full w-full flex-row items-center justify-start self-center">
                        <img src="/images/icons/check.svg" alt={service} className="h-[32px] w-[32px]" />
                        <div>{service}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Actions;
