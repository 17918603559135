import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import api from "../../api/apiHandler";
import Select from "../../components/Select-bis";
import { useSelector } from "react-redux";

const Society = () => {
  const { user } = useSelector((state) => state.Auth);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    if (!user) return;
    const getOrganizations = async () => {
      try {
        const res = await api.get(`/organization/user/${user._id}`);
        const { ok, data } = res.data;
        if (ok) {
          setOrganizations(data);
          setSelectedOrganization(data[0]);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getOrganizations();
  }, [user]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [organization, setOrganization] = useState();
  const mustBeDefined = ["name", "director", "contact", "address", "postalCode", "city", "siret"];

  function setFormValue(orga) {
    mustBeDefined.forEach((field) => {
      if (!orga[field]) orga[field] = "";
    });
  }

  useEffect(() => {
    if (!selectedOrganization) return;
    api
      .get(`/organization/${selectedOrganization._id}`)
      .then((res) => {
        if (res.data.ok) {
          console.log("organization", res);
          setOrganization(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [selectedOrganization]);

  const handleSubmit = (values) => {
    api
      .put("/organization/", values)
      .then((res) => {
        if (res.data.ok) {
          setFormValue(res.data.data);
          setSelectedOrganization(res.data.data);
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  if (!organization) return <div>Chargement...</div>;
  if (!selectedOrganization) return <div>Pas d'organisation</div>;
  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-3xl font-bold text-blue-dark">Ma société</h1>
        <div className="w-[360px]">
          <Select
            options={organizations.map((o) => ({ label: o.name, value: o }))}
            value={selectedOrganization}
            placeholder={selectedOrganization.name}
            onChange={setSelectedOrganization}
          />
        </div>
      </div>
      <Formik enableReinitialize initialValues={organization} onSubmit={handleSubmit}>
        {({ values, errors, handleChange, setValues }) => (
          <Form className="flex flex-col ">
            <div className="my-12 grid grid-cols-2 gap-6">
              <div className="col-span-2 flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="name">
                  Nom de la société
                </label>
                {console.log("values", values)}
                <input className="saas-input" type="text" name="name" id="name" value={values.name} disabled />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="director">
                  Directeur général
                </label>
                <input className="saas-input" type="text" name="director" id="director" value={values.director} onChange={handleChange} />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="contact">
                  Email professionnel
                </label>
                <input className="saas-input" type="text" name="contact" id="contact" value={values.contact} onChange={handleChange} />
              </div>
              <div className="col-span-2 flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="address">
                  Adresse
                </label>
                <input className="saas-input" type="text" name="address" id="address" value={values.address} onChange={handleChange} />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="postalCode">
                  Code postal
                </label>
                <input className="saas-input" type="text" name="postalCode" id="postalCode" value={values.postalCode} onChange={handleChange} />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="city">
                  Ville
                </label>
                <input className="saas-input" type="text" name="city" id="city" value={values.city} onChange={handleChange} />
              </div>
              <div className="col-span-2 flex flex-col">
                <label className="mb-1 font-bold text-gray-500" htmlFor="siret">
                  Numéro de TVA / SIRET
                </label>
                <input className="saas-input" type="text" name="siret" id="siret" value={values.siret} onChange={handleChange} />
              </div>
            </div>
            <div>
              <button className="blue-button px-6" type="submit">
                Enregister
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Society;
