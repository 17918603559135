import React, { useEffect, useState } from "react";
import api from "../../api/apiHandler";
import { useNavigate } from "react-router-dom";
import { AiOutlineMinusCircle, AiFillPlusCircle, AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { Modal } from "../../components/Modal";
import { createTheme, Switch, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { HiArrowDownTray, HiExclamationTriangle } from "react-icons/hi2";

const CALENDY = "";

export default function Facturation() {
  const [products, setProducts] = useState();
  const [invoices, setInvoices] = useState();
  const navigate = useNavigate();

  // check if params in url to show modal
  const urlParams = new URLSearchParams(window.location.search);
  const [modal, setModal] = useState({ isOpen: urlParams.get("success") ? true : false, success: urlParams.get("success") === "true" });

  const [page, setPage] = useState(1);

  const organization = useSelector((state) => state.Auth.organization);

  const getProducts = async () => {
    try {
      const products = await api.get("/stripe/products");
      // order by product price
      products.data.sort((a, b) => a.metadata.number_of_projects - b.metadata.number_of_projects);
      setProducts(products.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getInvoices = async () => {
    try {
      const invoices = await api.get("/stripe/invoices");
      setInvoices(invoices.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProducts();
    getInvoices();
  }, []);

  return (
    <div className="rounded-lg bg-white p-2 ">
      <Modal isOpen={modal.isOpen} onClose={() => setModal({ isOpen: false })} showCross={false}>
        <ModalAfterPayment closeModal={() => setModal({ isOpen: false })} success={modal.success} />
      </Modal>
      <div className="text-3xl font-bold text-secoset-green-700">Facturation</div>
      <div className="my-4 flex items-center gap-8 text-xl font-bold text-secoset-green-700">
        <div onClick={() => setPage(1)} className={`cursor-pointer border-b-4 ${page === 1 ? "border-secoset-green-700" : "border-[#CDEAF4]"} pb-2`}>
          Détail de l'abonnement
        </div>
        <div onClick={() => setPage(2)} className={`cursor-pointer border-b-4 ${page === 2 ? "border-secoset-green-700" : "border-[#CDEAF4]"} pb-2`}>
          Historique de facturation
        </div>
      </div>
      {page === 1 ? (
        <>
          {organization.subscriptionPlan !== "free" ? (
            <Subscribed product={products ? products.find((pro) => pro.id === organization.subscriptionPlan) : null} subscriptionReccurence={organization.subscriptionReccurence} />
          ) : (
            <NonSubscribed products={products} />
          )}
        </>
      ) : (
        <>
          {/* Onget Factures */}
          {invoices ? (
            <>
              {invoices.length === 0 ? (
                <div className="mt-8 text-sm font-medium text-secoset-green-700">Aucune donnée de facturation pour le moment</div>
              ) : (
                <div className="mt-8">
                  <div className="flex items-center border-b-[1px] border-gray-200 bg-gray-50 py-3">
                    <div className="w-[45%] px-6"></div>
                    <div className="w-[15%] text-xs font-medium text-secoset-green-700">Montant</div>
                    <div className="w-[15%] text-xs font-medium text-secoset-green-700">Date</div>
                    <div className="w-[15%] text-xs font-medium text-secoset-green-700">Statut</div>
                    <div className="w-[5%] text-xs font-medium text-secoset-green-700" />
                  </div>
                  {invoices.map((invoice) => (
                    <div key={invoice.id}>
                      <div className="flex items-center border-b-[1px] border-gray-200 py-3">
                        <div className="w-[45%] px-6 text-sm font-medium text-secoset-green-700">
                          Facture "{invoice.name}" - {dayjs(invoice.date).format("MMM YYYY")}
                        </div>
                        <div className="w-[15%]  text-sm  text-secoset-green-700">{invoice.amount / 100} €</div>
                        <div className="w-[15%] text-sm text-secoset-green-700">{dayjs(invoice.date).format("MMM DD, YYYY")}</div>
                        <div className="w-[15%]">
                          {invoice.status === "paid" ? (
                            <div className="flex w-fit flex-row items-center justify-center gap-0.5 rounded-2xl bg-[#ECFDF3] px-2 py-0.5 text-xs font-medium text-[#027A48]">
                              <AiOutlineCheck className="h-[10px] w-[13px] text-[#12B76A]" />
                              <div>Payé</div>
                            </div>
                          ) : (
                            <div className="w-fit rounded-2xl bg-[#FFF4ED] px-2 py-0.5 text-xs font-medium text-[#B93815]">En cours</div>
                          )}
                        </div>
                        <div className="w-[5%]">
                          <HiArrowDownTray onClick={() => window.open(invoice.pdf, "_blank")} className="h-[16px] w-[16px] cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div>Chargement...</div>
          )}
        </>
      )}
    </div>
  );
}

const Subscribed = ({ product, subscriptionReccurence }) => {
  if (!product) return null;
  const price = subscriptionReccurence === "year" ? product.yearlyPrice.unit_amount / 100 : product.monthlyPrice.unit_amount / 100;
  return (
    <div className="mt-8">
      <div className="relative flex items-center justify-center bg-[#CDEAF4] pb-2 pt-4">
        <div className="absolute top-0 left-4">
          <div className="relative top-[-6px] rounded-full border-[1px] border-secoset-green-700 bg-white py-1 px-2 text-xs font-bold">Votre forfait en cours</div>
        </div>
        <div key={product.id} className={`flex h-[308px] w-[260px] flex-col justify-around rounded p-2 text-center text-secoset-green-700`}>
          <div>
            <div className="mt-5 text-xl font-bold">{product.name}</div>
            <div className="text-3xl font-bold ">{product.metadata.number_of_projects} projets/an</div>
          </div>

          {subscriptionReccurence === "year" ? (
            <div className="text-sm font-medium">
              facturé en une fois <br /> {price}€/HT
            </div>
          ) : (
            <div className="text-sm font-medium">
              facturé pendant 1 an <br /> soit {price}€/HT par mois
            </div>
          )}

          <div className="mt-4 text-2xl font-bold">{Math.round((price * (subscriptionReccurence === "year" ? 1 : 12)) / product.metadata.number_of_projects)}€/projet</div>

          <div
            onClick={() => window.open(CALENDY, "_blank")}
            className={`mx-2 cursor-pointer rounded border border-secoset-green-700 bg-white py-3 font-bold  text-secoset-green-900 transition ease-in-out  hover:bg-secoset-green-700  hover:text-white  `}
          >
            Modifier mon forfait
          </div>
        </div>
      </div>
      <div className="mt-6 w-full text-secoset-green-700">
        <div className="text-xl font-bold ">Votre forfait inclut :</div>
        <div className="flex flex-col  justify-start text-sm font-medium md:flex-row md:gap-20">
          <ul className="!list-inside !list-disc">
            <li className="text-secoset-green-700">Mise à disposition de l'outil Secoset</li>
            <li className="text-secoset-green-700">Accès inclus à votre dashboard</li>
            <li className="text-secoset-green-700">Pilotez la démarche RSE de votre portefeuille de projets</li>
            <li className="text-secoset-green-700">Valorisez vos engagements RSE</li>
          </ul>
          <ul className="!list-inside !list-disc">
            <li className="text-secoset-green-700">1h de visio de prise en main</li>
            <li className="text-secoset-green-700">Plateforme de support </li>
            <li className="text-secoset-green-700">Données protégées et sécurisées</li>
            <li className="text-secoset-green-700">Guide d'action complet</li>
          </ul>
        </div>
      </div>
      <div className="mt-8 rounded-lg bg-secoset-green-5 p-4">
        <div>
          Vous souhaitez également inclure un accompagnement personnalisé sur vos projets ? Nos experts Secoset sont à votre disposition pour vos problématiques de
          <span className="font-bold">structuration, formation, sensibilisation et stratégie.</span> Prenez contact ci-dessous pour obtenir un devis sur mesure.
        </div>
        <div
          onClick={() => window.open(CALENDY, "_blank")}
          className="mt-4 w-fit cursor-pointer rounded border-[1px] border-secoset-green-700 bg-white py-3 px-5 font-bold text-secoset-green-700 hover:bg-secoset-green-700 hover:text-white"
        >
          Passer en accompagnement
        </div>
      </div>

      <div onClick={() => window.open(CALENDY, "_blank")} className="mt-6 cursor-pointer font-medium text-secoset-green-700 underline">
        Résilier mon contrat
      </div>
    </div>
  );
};

const NonSubscribed = ({ products }) => {
  const [yearlyPayment, setYearlyPayment] = useState(true);
  const [projectNumber, setProjectNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const colors = ["bg-[#CDEAF4]", "bg-[#EFE8F8]", "bg-[#F6EECA]", "bg-[#D9E8E6]"];

  const buy = async (product) => {
    if (loading) return;
    setLoading(true);
    try {
      const session = await api.post("/stripe/checkout-session", {
        productId: product.id,
        yearly: yearlyPayment,
      });
      window.location.replace(session.data.url);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "white",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "white",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: "#195E76",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 1,
              backgroundColor: "#195E76",
            },
          },
        },
      },
    },
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full items-center justify-between">
        <div className="mt-14 mb-10 flex items-center gap-4 font-bold text-secoset-green-700">
          <div>Combien de projets réalisez-vous par an ?</div>
          <div className="flex items-center">
            <AiOutlineMinusCircle onClick={() => setProjectNumber((prev) => (prev > 1 ? prev - 1 : prev))} className="h-[30px] w-[30px] cursor-pointer " />
            <div className=" mx-2 rounded-lg bg-[#F0F6F7] py-1 px-2 text-xl">{projectNumber}</div>
            <AiFillPlusCircle onClick={() => setProjectNumber((prev) => prev + 1)} className="h-[30px] w-[30px] cursor-pointer " />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className={`text-xl font-bold text-secoset-green-700 ${yearlyPayment && "opacity-40"}`}>Paiement mensuel</div>
          <ThemeProvider theme={theme}>
            <Switch checked={yearlyPayment} onChange={() => setYearlyPayment((old) => !old)} inputProps={{ "aria-label": "controlled" }} />
          </ThemeProvider>
          <div className={`text-xl font-bold text-secoset-green-700 ${!yearlyPayment && "opacity-40"}`}>Paiement en une fois</div>
        </div>
      </div>
      {products ? (
        <div className="flex flex-row flex-wrap items-center justify-around gap-4">
          {products.map((product, index) => {
            const isRecommanded =
              (index === 0 && projectNumber <= 5) || (index === 1 && 5 < projectNumber && projectNumber <= 15) || (index === 2 && 15 < projectNumber && projectNumber <= 30);
            const price = yearlyPayment ? product.yearlyPrice.unit_amount / 100 : product.monthlyPrice.unit_amount / 100;
            return (
              <div key={product.id} className={`relative p-2 text-center text-secoset-green-700 ${colors[index]} flex h-[308px] w-[260px] flex-col justify-around rounded`}>
                {isRecommanded && (
                  <div className="absolute left-1/2 top-0 ">
                    <div className="relative top-[-6px] left-[-50%]  rounded-full border-[1px] border-secoset-green-700 bg-white py-1 px-2 text-xs font-bold">
                      Offre recommandée
                    </div>
                  </div>
                )}

                <div>
                  <div className="mt-5 text-xl font-bold">{product.name}</div>
                  <div className="text-3xl font-bold ">{product.metadata.number_of_projects} projets/an</div>
                </div>

                {yearlyPayment ? (
                  <div className="text-sm font-medium">
                    facturé en une fois <br /> {price}€/HT
                  </div>
                ) : (
                  <div className="text-sm font-medium">
                    facturé pendant 1 an <br /> soit {price}€/HT par mois
                  </div>
                )}

                <div className="mt-4 text-2xl font-bold">{Math.round((price * (yearlyPayment ? 1 : 12)) / product.metadata.number_of_projects)}€/projet</div>

                <div
                  onClick={() => buy(product)}
                  className={`mx-2 cursor-pointer rounded border border-secoset-green-700 ${
                    isRecommanded
                      ? "bg-secoset-green-700 text-white hover:bg-white hover:text-secoset-green-900"
                      : "bg-white text-secoset-green-900 hover:bg-secoset-green-700 hover:text-white"
                  } py-3 font-bold  transition  ease-in-out  `}
                >
                  Choisir cette option
                </div>
              </div>
            );
          })}
          <div className={` relative p-2 text-center text-secoset-green-700 ${colors[3]} flex h-[308px] w-[260px] flex-col justify-around rounded`}>
            {projectNumber > 30 && (
              <div className="absolute left-1/2 top-0 ">
                <div className="relative top-[-6px] left-[-50%]  rounded-full border-[1px] border-secoset-green-700 bg-white py-1 px-2 text-xs font-bold">Offre recommandée</div>
              </div>
            )}
            <div>
              <div className="mt-5 text-xl font-bold ">Taille XL</div>
              <div className="text-3xl font-bold ">Offre sur mesure</div>
            </div>

            <div className="h-[40px] text-sm font-medium"></div>
            <div className="mt-4 text-2xl font-bold">Plus de 30 projets/an</div>

            <div
              onClick={() => window.open(CALENDY, "_blank")}
              className={`mx-2 cursor-pointer rounded border border-secoset-green-700 ${
                projectNumber > 30
                  ? "bg-secoset-green-700 text-white hover:bg-white hover:text-secoset-green-900"
                  : "bg-white text-secoset-green-900 hover:bg-secoset-green-700 hover:text-white"
              } py-3 font-bold  transition  ease-in-out  `}
            >
              Demander un devis
            </div>
          </div>
        </div>
      ) : (
        <h1>Chargement...</h1>
      )}
      <div className="mt-6 w-full text-secoset-green-700">
        <div className="text-xl font-bold ">Toutes les offres incluent : </div>
        <div className="flex flex-col  justify-start text-sm font-medium md:flex-row md:gap-20">
          <ul className="!list-inside !list-disc">
            <li className="text-secoset-green-700">Mise à disposition de l'outil Secoset</li>
            <li className="text-secoset-green-700">Accès inclus à votre dashboard</li>
            <li className="text-secoset-green-700">Pilotez la démarche RSE de votre portefeuille de projets</li>
            <li className="text-secoset-green-700">Valorisez vos engagements RSE</li>
          </ul>
          <ul className="!list-inside !list-disc">
            <li className="text-secoset-green-700">1h de visio de prise en main</li>
            <li className="text-secoset-green-700">Plateforme de support </li>
            <li className="text-secoset-green-700">Données protégées et sécurisées</li>
            <li className="text-secoset-green-700">Guide d'action complet</li>
          </ul>
        </div>
      </div>
      <div className="flex w-full items-start justify-start">
        <a href="/CONTRAT_CADRE_SECOSET.pdf" target="_blank" className="mt-6 cursor-pointer text-sm font-medium text-secoset-green-700 underline">
          Voir conditions
        </a>
      </div>

      <div className="mt-8 w-full rounded-lg bg-secoset-green-5 p-4">
        <div>
          Vous souhaitez également inclure un accompagnement personnalisé sur vos projets ? Nos experts Secoset sont à votre disposition pour vos problématiques de
          <span className="font-bold">structuration, formation, sensibilisation et stratégie.</span> Prenez contact ci-dessous pour obtenir un devis sur mesure.
        </div>
        <div
          onClick={() => window.open(CALENDY, "_blank")}
          className="mt-4 w-fit cursor-pointer rounded border-[1px] border-secoset-green-700 bg-white py-3 px-5 font-bold text-secoset-green-700 hover:bg-secoset-green-700 hover:text-white"
        >
          Passer en accompagnement
        </div>
      </div>
    </div>
  );
};

const ModalAfterPayment = ({ isOpen, closeModal, success }) => {
  if (success) {
    return (
      <div className="flex w-[760px] flex-col items-center justify-center p-10">
        <div className="rounded-full bg-[#ECFDF3] p-2">
          <div className="rounded-full bg-[#D1FADF] p-2">
            <AiOutlineCheckCircle className="text-4xl text-[#039855]" />
          </div>
        </div>

        <div className="mt-6 text-3xl font-bold text-secoset-green-700">Le paiement a été effectué avec succès</div>
        <div className="mt-2 text-center text-secoset-green-700">
          Félicitations, vous êtes abonné. Un email de confirmation vient de vous être envoyé. Consultez vos factures à tout moment depuis la section "Historique de facturation".
        </div>
        <button onClick={closeModal} className="blue-button mt-6 w-[352px]">
          Terminer
        </button>
      </div>
    );
  }
  return (
    <div className="flex w-[760px] flex-col items-center justify-center p-10">
      <div className="rounded-full bg-[#FDF9E7] p-2">
        <div className="rounded-full bg-[#FAECB7] p-2 pt-1.5">
          <HiExclamationTriangle className="text-4xl text-[#EABA0F]" />
        </div>
      </div>

      <div className="mt-6 text-center text-3xl font-bold text-secoset-green-700">Nous avons rencontré un problème avec votre paiement</div>
      <div className="mt-2 text-center text-secoset-green-700">Veuillez vérifier les informations de paiement que vous avez saisies</div>
      <button onClick={closeModal} className="blue-button mt-6 w-[352px]">
        Réessayer
      </button>
    </div>
  );
};
