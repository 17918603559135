import React from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";

import { setUser } from "../../redux/authSlice";
import api from "../../api/apiHandler";

const MyAccount = () => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    api
      .put("/user/", values)
      .then((res) => {
        const user = res.data;
        Sentry.setUser({ firstName: user.firstName, lastName: user.lastName, email: user.email });
        dispatch(setUser(user));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Formik initialValues={user} onSubmit={handleSubmit}>
      {({ values, errors, handleChange }) => (
        <Form className="flex flex-col">
          <h1 className="text-3xl font-bold text-blue-dark">Mon compte</h1>
          <div className="my-12 grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="mb-1 font-bold text-gray-500" htmlFor="firstName">
                Prénom
              </label>
              <input className="saas-input" type="text" name="firstName" id="firstName" value={values.firstName} onChange={handleChange} />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-bold text-gray-500" htmlFor="lastName">
                Nom
              </label>
              <input className="saas-input" type="text" name="lastName" id="lastName" value={values.lastName} onChange={handleChange} />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-bold text-gray-500" htmlFor="email">
                Email
              </label>
              <input className="saas-input" type="text" name="email" id="email" value={values.email} onChange={handleChange} disabled />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-bold text-gray-500" htmlFor="phoneNumber">
                Telephone
              </label>
              <input className="saas-input" type="text" name="phoneNumber" id="phoneNumber" value={values.phoneNumber} onChange={handleChange} />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 font-bold text-gray-500" htmlFor="organizationJob">
                Poste
              </label>
              <input className="saas-input" type="text" name="organizationJob" id="organizationJob" value={values.organizationJob} onChange={handleChange} />
            </div>
          </div>
          <div>
            <button className="blue-button px-6" type="submit">
              Enregister
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MyAccount;
