import axios from "axios";
const { API_URL } = require("../config");

class APIHandler {
  constructor() {
    this.name = "APIHandler";
    if (!API_URL) throw new Error("A target backend URL must be specified in .env");
    this.api = axios.create({
      baseURL: API_URL + "/api",
      // withCredentials: infos && infos.credentials ? true : false
      withCredentials: true,
    });
  }

  checkRoute(route, config) {
    try {
      if (!route) throw new Error("please provide the requested server route");
      if (config && typeof config !== "object") throw new Error("Config must be an object");
    } catch (err) {
      return console.error(err);
    }
  }

  addAuthorization(config) {
    const token = localStorage.getItem("jwt");
    if (!config) config = { headers: { Authorization: token } };
    else if (!config.header) config.headers = { Authorization: token };
    else if (!config.header.Authorization) config.headers.Authorization = token;
    return config;
  }

  post(route, payload, config) {
    this.checkRoute(route, config);
    if (payload && typeof payload !== "object") throw new Error(`${this.name} post() function expects payload argument to be of type Object`);
    config = this.addAuthorization(config);
    return this.api.post(route, payload, config);
  }

  get(route, query, config) {
    this.checkRoute(route, config);
    var queryString = "";
    if (query) {
      if (typeof query !== "object") throw new Error(`${this.name} get() function expects query argument to be of type Object`);
      let count = 0;
      let keyCount = Object.keys(query);
      for (let key in query) {
        if (!count) queryString += "?";
        if (count && count < keyCount.length) queryString += "&";
        queryString += `${key}=${query[key]}`;
        count++;
      }
    }

    config = this.addAuthorization(config);
    return this.api.get(route + (queryString || ""), config);
  }

  patch(route, query, payload, config) {
    this.checkRoute(route, config);
    if (!payload || typeof payload !== "object") throw new Error(`${this.name} patch() function expects payload argument to be of type Object`);
    var queryString = "";
    if (query) {
      if (typeof query !== "object") throw new Error(`${this.name} get() function expects query argument to be of type Object`);
      let count = 0;
      let keyCount = Object.keys(query);
      for (let key in query) {
        if (!count) queryString += "?";
        if (count && count < keyCount.length) queryString += "&";
        queryString += `${key}=${query[key]}`;
        count++;
      }
    }
    config = this.addAuthorization(config);
    return this.api.patch(route + (queryString || ""), payload, config);
  }

  put(route, payload, config) {
    this.checkRoute(route, config);
    if (!payload || typeof payload !== "object") throw new Error(`${this.name} patch() function expects payload argument to be of type Object`);
    config = this.addAuthorization(config);
    return this.api.put(route, payload, config);
  }

  replace(route, payload, config) {
    this.checkRoute(route, config);
    if (!payload || typeof payload !== "object") throw new Error(`${this.name} replace() function expects payload argument to be of type Object`);
    config = this.addAuthorization(config);
    return this.api.put(route, payload, config);
  }

  delete(route, config) {
    this.checkRoute(route, config);
    config = this.addAuthorization(config);
    return this.api.delete(route, config);
  }
}

export default new APIHandler();
