import React, { useState, useEffect } from "react";
import { BsPlusCircle } from "react-icons/bs";

import ModalNewProduction from "../productions/new_modal";
import api from "../../api/apiHandler";
import HomeBackground from "../../assets/svg/home-background.svg";
import BadgeRespProd from "../../assets/img/badge-responsable-production.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalInformations from "./infos-modal";
import ModalTutoriel from "./tutorial-modal";

const planLabel = {
  demo: (
    <div className="border-2 border-dashed border-plan-free px-2 py-1">
      <p className="text-xs text-plan-free">Démo</p>
    </div>
  ),
  autonomy: (
    <div className="border-2 border-dashed border-plan-strategy px-2 py-1">
      <p className="text-xs text-plan-strategy">Pilotage</p>
    </div>
  ),
  supported: (
    <div className="border-2 border-dashed border-plan-custom px-2 py-1">
      <p className="text-xs text-plan-custom">Labelisation</p>
    </div>
  ),
};

export const labelProductionStatus = {
  ongoing: "En cours",
  validated: "Validée",
  error: "En attente",
  pending: "En attente",
  rejected: "Finalisée et non validée",
};

export default () => {
  const [productions, setProductions] = useState([]);
  const user = useSelector((state) => state.Auth.user);

  const [infoModalIsShowing, setInfoModalIsShowing] = useState(false);
  const [openNewProduction, setOpenNewProduction] = useState(false);
  const [openTutoriel, setOpenTutoriel] = useState(false);

  useEffect(() => {
    if (user.status === "WAITING_INFORMATION") setInfoModalIsShowing(true);
    api.get("/productions").then((res) => {
      setProductions(res.data.productions);
    });
  }, [user]);

  const closeOnboarding = () => {
    setInfoModalIsShowing(false);
    // just so the transition between 2 modals looks better
    setTimeout(() => {
      setOpenTutoriel(true);
    }, 300);
  };

  if (!productions) return <div>Loading...</div>;

  return (
    <main className="flex flex-1 flex-col">
      <ModalInformations user={user} open={infoModalIsShowing} close={closeOnboarding} />
      <ModalTutoriel user={user} open={openTutoriel} setOpen={setOpenTutoriel} />
      <ModalNewProduction open={openNewProduction} setOpen={setOpenNewProduction} type={"create"} />

      <section className="relative grid w-full grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 p-6">
        <img className="absolute right-12 top-24 z-0 w-[35%]" src={HomeBackground} alt="illustration tournage secoya" />
        <div className="flex h-[340px] items-center justify-center rounded bg-white">
          <button className="blue-button flex h-12 items-center" onClick={() => setOpenNewProduction(true)}>
            Nouvelle Production <BsPlusCircle className="ml-2" />
          </button>
        </div>

        {productions.map((production, i) => {
          if (production.deleted === false) {
            return <ProductionCard key={i} production={production} />;
          }
        })}
      </section>
    </main>
  );
};

const productionTypeInfo = {
  "Long-Métrage": {
    color: "#d6d7db",
    icon: "cinema",
  },
  Série: {
    color: "#BDE0D6",
    icon: "cinema",
  },
  "Court-métrage": {
    color: "#CDC6D4",
    icon: "cinema",
  },
  Documentaire: {
    color: "#EE4266",
    icon: "tournage",
  },
  "Vidéo-clip": {
    color: "#FFF1B1",
    icon: "tournage",
  },
  Pub: {
    color: "#CAF1F6",
    icon: "ad",
  },
  "Émission de Flux": {
    color: "#D49BA6",
    icon: "cinema",
  },
  // temp
  projectType: {
    color: "#D49BA6",
    icon: "cinema",
  },
};

const ProductionCard = ({ production }) => {
  const progress = production.criterias.reduce((prev, curr) => {
    if (["ok", "pending", "error", "invalid"].includes(curr.status)) return prev + 1;
    return prev;
  }, 0);
  const completion = production.criterias.length ? (progress / production.criterias.length) * 100 : 0;

  const settings = productionTypeInfo[production.type] || productionTypeInfo.projectType;
  return (
    <Link
      as="div"
      to={`/saas/productions/${production._id.toString()}`}
      className="relative z-10 flex h-[340px] flex-col rounded border-2 border-transparent bg-white p-2 shadow hover:border-2 hover:border-blue-dark">
      {production.status === "validated" ? <img className="absolute -top-2 -left-2 h-12 w-12 z-50" src={BadgeRespProd} /> : null}
      <div className="relative h-full w-full">
        {production.image ? (
          <img className="h-36 w-full object-contain" src={production.image} alt="illustration" />
        ) : (
          <div className="absolute top-0 left-0 h-36 w-full overflow-hidden bg-blue-light">
            <p className="-mt-[50px] ml-5 text-[200px] text-blue-gray">{production.title}</p>
          </div>
        )}
        <div className={`absolute top-2.5 right-2 flex border-spacing-3 items-center border-2 border-white px-2 py-1`} style={{ backgroundColor: settings.color }}>
          <img className="mr-1 h-6 w-6" src={`/images/icons/${settings.icon}.svg`} alt="icone" />
          <p className="hzb">{production.type}</p>
        </div>
      </div>

      <div className="flex flex-1 flex-col p-2">
        <h5 className="mt-1 truncate">{production.title}</h5>
        <div className="mt-2 flex items-center justify-between">
          <p className="text-[10px] text-blue-mid">Cree le {new Date(production.createdAt).toLocaleDateString("fr")}</p>
          <div>{planLabel[production.plan]}</div>
        </div>
        <div className="mt-2 flex items-center justify-between gap-2">
          <div className={`full absolute top-0.5 right-0.5 h-2 w-2 rounded bg-${production.status}`} />
          <p className="text-xs text-blue-dark">{labelProductionStatus[production.status]}</p>
          <div className="flex-1">
            <div className="relative">
              <div className="flex h-2 overflow-hidden rounded-sm bg-blue-dark-150 text-xs">
                <div style={{ width: `${completion}%` }} className="h-full bg-blue-dark" />
              </div>
            </div>
          </div>
          <span className="text-xs text-blue-dark">{completion.toFixed(0)} %</span>
        </div>
        <div className="mt-2 flex items-center gap-2">
          <p className="whitespace-nowrap text-xs text-blue-dark">Production</p>
          <span className="text-xs font-bold text-blue-dark">{production.production}</span>
        </div>
        <div className="mt-1 flex items-center gap-2">
          <p className="whitespace-nowrap text-xs text-blue-dark">Dir. Prod</p>
          <span className="truncate text-xs font-bold text-blue-dark">{production.productionDirector}</span>
        </div>
      </div>
    </Link>
  );
};
